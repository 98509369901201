import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import PastCampaignHighlights1  from "../../Seasonal Campaign Images/Past Campaign Highlights.jpg" 


const PastCampaignHighlights = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className='lg:my-40'>
        <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Past Campaign Highlights
      </p>
        <div className='xl:flex  xl:justify-between'>
            <div className='xl:w-1/2 p-5 md:p-16 xl:p-5' data-aos="zoom-in">
                <img src={PastCampaignHighlights1} className="rounded-3xl lg:ml-5"></img>
            </div>
            <div className='xl:w-1/2 md:ml-5 lg:ml-20 xl:ml-0'>
                <div className='border border-white md:h-[118px] m-2 rounded-r-full md:w-[330px] ' data-aos="fade-right">
                   <p className=" text-[18px] m-1 mt-4"><div className="font-bold my-2 text-2xl">Throwback Trends </div>Revisit standout trends from past campaigns</p>
                </div>
                <div className='border border-white rounded-r-full  md:h-[118px] md:w-[400px] lg:w-[500px] xl:w-[400px]  m-2'data-aos="fade-right">
                <p className=" text-[18px] m-1 mt-6"><div className="font-bold my-2 text-2xl">Customer Stories</div>Hear loyal customer experiences</p>               
                 </div>
                <div className='border border-white rounded-r-full md:h-[118px] md:w-[600px] lg:w-[600px] xl:w-[600px] m-2'data-aos="fade-right">
                <p className=" text-[18px] m-1 mt-6"><div className="font-bold my-2 text-2xl">Visual Showcase</div>Journey through past campaigns visually</p>    
                </div>
                <div className='border border-white rounded-r-full  md:h-[118px] md:w-[700px] lg:w-[700px] xl:w-[700px]  m-2'data-aos="fade-right">
                <p className=" text-[18px]  m-1 mt-6"><div className="font-bold my-2 text-2xl">Success Stories</div>Discover achievements from past campaigns</p>    
                </div>
            </div>
        </div>
    </div>
  )
}

export default PastCampaignHighlights
// Past Campaign Highlights
// Throwback Trends: Revisit standout trends from past campaigns.
// Customer Stories: Hear loyal customer experiences.
// Visual Showcase: Journey through past campaigns visually.
// Success Stories: Discover achievements from past campaigns.
