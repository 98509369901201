import React from 'react'
import StreetStyle1 from "../../Fashion Insights Images/Street style 01.jpg" 
import StreetStyle2 from "../../Fashion Insights Images/street style 02.jpg" 


const StreetStyle = () => {
  return (
    <div className='my-32  lg:my-60'>
        <p className=" text-4xl lg:text-6xl flex justify-center font-bold mb-20"  data-aos="zoom-in">Street Style</p>
        <div className='lg:flex'>
            <div className='lg:w-1/2'>
                <div className='lg:flex'>
                    <div className='p-5 md:p-16 lg:p-0 lg:w-[400px] xl:w-1/2 xl:ml-10 lg:m-5 xl:m-5'>
                    <img className=' rounded-3xl' src={StreetStyle1} data-aos="zoom-in"></img>
                    </div>
                   <div className=' border border-white lg:h-[210px] xl:h-[180px] mb-auto p-2 rounded-3xl m-3 md:m-14 lg:m-3'  data-aos="fade-right">
                   <p className='flex justify-center  font-bold text-2xl lg:text-[20px] xl:text-2xl md:my-5'>Global Trendsetters</p>
                    <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl'>Trendsetters worldwide</p>
                    <p className=' flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3'> with Poshavesh</p>
                   </div>
                </div>
                <div className='border border-white lg:mr-40 lg:ml-5 m-3 md:m-14 lg:m-3 rounded-3xl'data-aos="fade-up">
                <p className='text-xl lg:text-[18px] xl:text-xl ml-5 mt-5'> <span className='text-2xl font-bold' >City Streets : </span> Vibrant urban fashion with Poshavesh style </p>
                <p className='text-xl ml-[50%] mb-5  '></p>
                </div>
            </div>
            <div className='lg:w-1/2'>
                <div className='lg:flex'>
                <div className=' border border-white lg:h-[210px] xl:h-[180px]  rounded-3xl m-3 md:m-14 lg:m-3 lg:mt-44  xl:mt-72 my-auto' data-aos="fade-down-left">
                <p className='flex justify-center  font-bold text-2xl my-5'>Fresh Ideas</p>
                <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5 mx-3'>Unexpected fashion  </p>
                <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl mb-5 mx-3'>combos with  Poshavesh </p>
                </div>
                <div className='p-5 md:p-16 lg:p-0 lg:w-[400px] xl:w-1/2 lg:m-5 xl:m-5' >
                <img className=' rounded-3xl' src={StreetStyle2} data-aos="zoom-in" ></img>
                </div>
                   
                </div>
                <div className='border border-white rounded-3xl m-3 md:m-14 lg:m-3  lg:ml-40  md:mx-10'  data-aos="fade-up">
                <p className='flex justify-center mt-5 text-xl lg:text-[18px] xl:text-xl'><span className='font-bold mx-3 '>Express Yourself : </span> Self-expression through fashion</p>
                <p className='text-xl lg:text-[18px] xl:text-xl ml-[43%] mb-5'> with Poshavesh</p>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default StreetStyle
//12. Street Style:
// Global Trendsetters: Trendsetters worldwide with PV.
// City Streets: Vibrant urban fashion with PV style.
// Fresh Ideas: Unexpected fashion combos with PV.
// Express Yourself: Self-expression through fashion with PV.



