import React from 'react'
import FAQSectionCustomerSupport from "./FAQSectionCustomerSupport"
const CustomerSupportFAQ = () => {
  return (
    <div>
    <p className="sales text-4xl lg:text-6xl flex justify-start font-bold my-10  ml-12  md:ml-28 lg:ml-40 xl:ml-60" data-aos="zoom-in">CUSTOMER SUPPORT</p>
    <FAQSectionCustomerSupport/>
    </div>
  )
}

export default CustomerSupportFAQ