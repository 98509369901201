import React from 'react'
import ConsumerEducation1 from "../../Sustainability Efforts Images/Consumer Education01.jpg" 
import ConsumerEducation2 from "../../Sustainability Efforts Images/Consumer Education02.jpg" 


const ConsumerEducation = () => {
  return (
    <div className='mt-40'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold  mb-32"  data-aos="zoom-in">Consumer Education</p>
        <div className='lg:flex ml-5'>
            <div className='lg:w-1/2 lg:p-5 xl:p-0' data-aos="fade-right">
                <div className='lg:p-5 xl:p-8'>
                <img className='md:m-5 rounded-3xl transform skew-y-6' src={ConsumerEducation1}>
                </img>
                </div>
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl'>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Wardrobe Longevity Tips : </span> Extend garment lifespan advice</p>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Environmental Care Instructions : </span>Eco-conscious care guidance</p>
                </div>
            </div>
            <div className='mt-16 lg:w-1/2 lg:p-5 xl:p-0 lg:mr-5 xl:mr-0' data-aos="fade-left">
              <div className='lg:p-5 xl:p-8 '>
              <img className='md:m-5 rounded-3xl transform skew-y-6 lg:h-[450px]' src={ConsumerEducation2}>
                </img>
              </div>
                
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl '>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Responsible Consumption Guides : </span> Mindful shopping resources</p>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Sustainability 101 :</span> Comprehensive sustainable fashion guides</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default ConsumerEducation
// Consumer Education
// Wardrobe Longevity Tips: Extend garment lifespan advice.
// Environmental Care Instructions: Eco-conscious care guidance.
// Responsible Consumption Guides: Mindful shopping resources.
// Sustainability 101: Comprehensive sustainable fashion guides.

