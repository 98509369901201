import React from 'react'
import DataVisualizations1  from "../../Industry Insights and Trends Images/Data Visualizations01.jpg" 
import DataVisualizations2  from "../../Industry Insights and Trends Images/Data Visualizations02.jpg" 
const DataVisualizations = () => {
  return (
    <div className='mt-40'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold  mb-32"  data-aos="zoom-in"> Data Visualizations</p>
        <div className='lg:flex ml-5'>
            <div className='lg:w-1/2 lg:p-5 xl:p-0' data-aos="fade-right">
               <div className='lg:p-5 xl:p-8'>
               <img className='md:m-5 rounded-3xl transform skew-y-6' src={DataVisualizations1}>
                </img>
               </div>
               
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl'>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Trend Graphs : </span> Fashion trend evolution</p>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Market Analysis Infographics : </span>Key industry stats visually</p>
                </div>
            </div>
            <div className='mt-16 lg:w-1/2 lg:p-5 xl:p-0 lg:mr-5 xl:mr-0' data-aos="fade-left">
              <div className='lg:p-5 xl:p-8'>
              <img className='md:m-5 rounded-3xl transform skew-y-6' src={DataVisualizations2}></img>
              </div>
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl '>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Consumer Behavior Charts : </span> Insights made clear</p>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Sustainability Impact Maps :</span> Environmental fashion impact</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default DataVisualizations

// Data Visualizations
// Trend Graphs: Fashion trend evolution.
// Market Analysis Infographics: Key industry stats visually.
// Consumer Behavior Charts: Insights made clear.
// Sustainability Impact Maps: Environmental fashion impact.

