import React from 'react'
import Section8 from './Section8'
import Section9 from './Section9'
import GuaranteeOverview from './GuaranteeOverview'
import SatisfactionGuaranteeStatement from './SatisfactionGuaranteeStatement'
import QualityAssurance from './QualityAssurance'
import EasyReturnsandExchanges from './EasyReturnsandExchanges'
import MoneyBackGuarantee from './MoneyBackGuarantee'
import CustomerSupport from './CustomerSupport'
import PersonalizedService from './PersonalizedService'
import CustomerTestimonials from './CustomerTestimonials'
import GuaranteeRenewal from './GuaranteeRenewal'
import ContinuousImprovement from './ContinuousImprovement'
import Navbar from  "../Navbar"
import Footer from  "../Footer"
import Footer2 from  "../Footer2"
import ShopNowButton from '../ShopNowButton'
const CustomerSatisfaction = () => {
  return (
    <div className='overflow-x-hidden'>
      <Navbar />
      <GuaranteeOverview/>
      <ShopNowButton/>
      <SatisfactionGuaranteeStatement/>
      <ShopNowButton/>
      <QualityAssurance/>
      <ShopNowButton/>
      <EasyReturnsandExchanges/>
      <ShopNowButton/>
      <MoneyBackGuarantee/>
      <ShopNowButton/>
      <CustomerSupport/>
      <ShopNowButton/>
      <PersonalizedService/>
      <ShopNowButton/>
      <CustomerTestimonials/>
      <ShopNowButton/>
      <GuaranteeRenewal/>
      <ShopNowButton/>
      <ContinuousImprovement/>
      <ShopNowButton/>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default CustomerSatisfaction