import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import FeaturedPartnerships1  from "../../Company Partnerships Images/Featured Partnerships.jpg" 

const FeaturedPartnerships = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='md:my-32  lg:my-20'>
       <p className="text-4xl  lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Featured Partnerships
      </p>
        <div className='lg:flex lg:justify-evenly'>
        <div data-aos="zoom-in" className="p-5 md:p-16 lg:p-5 lg:mr-10 xl:m-10 h-3/4 lg:ml-20">
            <img src={FeaturedPartnerships1} className='rounded-3xl   '></img>
        </div>
        <div className='lg:w-screen lg:mx-10 md:m-10'>
          <div className="m-3">
          <p className='flex justify-center border border-white rounded-xl text-xl lg:mb-[55px] xl:mb-[150px]  mx-2 my-5 p-5 lg:text-[18px] xl:text-xl ' data-aos="fade-right"><span className="font-bold text-xl mx-3">Designer Collaborations :</span> Exclusive collections with top designers</p>
          </div>
           
          <div className="m-3">
          <p className='flex justify-center lg:mb-[55px] xl:mb-[150px] border border-white mx-2 rounded-xl my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-left"><span className="font-bold text-xl  mx-1">Sustainability Initiatives :</span> Shaping the future of eco-conscious fashion</p>
          </div>
       
            <div className="m-3">
            <p className='flex justify-center lg:mb-[55px]  xl:mb-[150px] border border-white mx-2 rounded-2xl my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-right"><span className="font-bold text-xl mx-3">Community Engagement :</span> Impactful collaborations with local communities</p>
            </div>
           
            <div className="m-3">
            <p className='flex justify-center  lg:mb-[55px] xl:mb-[150px] border border-white mx-2 rounded-2xl  my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-left"><span className="font-bold text-xl mx-3">Industry Innovations :</span> Collaborations with pioneering brands and tech partners</p>
            </div>
            

        </div>
        </div>
    </div>
  )
}

export default FeaturedPartnerships
// Featured Partnerships
// Designer Collaborations: Exclusive collections with top designers.
// Sustainability Initiatives: Shaping the future of eco-conscious fashion.
// Community Engagement: Impactful collaborations with local communities.
// Industry Innovations: Collaborations with pioneering brands and tech partners.

