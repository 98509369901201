import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import AgeAppropriateStylingTips1 from "../../Fashion For All ages/Age-Appropriate Styling Tips.jpg"

const AgeAppropriateStylingTips = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='md:my-32  lg:mt-20'>
       <p className="text-4xl  lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Age-Appropriate Styling Tips
      </p>
        <div className='lg:flex lg:justify-evenly'>
        <div data-aos="zoom-in" className="p-5 md:p-16 lg:p-5 lg:mr-10 xl:m-10 h-3/4 lg:ml-20">
            <img src={AgeAppropriateStylingTips1} className='rounded-3xl   '></img>
        </div>
        <div className='lg:w-screen lg:mx-10 md:m-10'>
          <div className="m-3">
          <p className='flex justify-center border border-white rounded-xl text-xl lg:mb-[55px] xl:mb-[150px]  mx-2 my-5 p-5 lg:text-[18px] xl:text-xl ' data-aos="fade-right"><span className="font-bold text-xl ">Occasion-Specific Guidance : </span>    Styling tips for occasions</p>
          </div>
           
          <div className="m-3">
          <p className='flex justify-center lg:mb-[55px] xl:mb-[150px] border border-white mx-2 rounded-xl my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-left"><span className="font-bold text-xl">Addressing Common Concerns : </span>Fashion advice for transitions </p>
          </div>
       
            <div className="m-3">
            <p className='flex justify-center lg:mb-[55px]  xl:mb-[150px] border border-white mx-2 rounded-2xl my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-right"><span className="font-bold text-xl">Seasonal Wardrobe Tips : </span> Styling tips for seasons</p>
            </div>
           
            <div className="m-3">
            <p className='flex justify-center  lg:mb-[55px] xl:mb-[150px] border border-white mx-2 rounded-2xl  my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-left"><span className="font-bold text-xl">Promoting Confidence : </span>  Confidence through styling</p>
            </div>
            

        </div>
        </div>
    </div>
  )
}

export default AgeAppropriateStylingTips
// Age-Appropriate Styling Tips:
// Occasion-Specific Guidance: Styling tips for occasions.
// Addressing Common Concerns: Fashion advice for transitions.
// Seasonal Wardrobe Tips: Styling tips for seasons.
// Promoting Confidence: Confidence through styling.


