import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CustomerSupport1 from "../../Customer Satisfaction Images/Customer Support01 .jpg"
import CustomerSupport2 from "../../Customer Satisfaction Images/Customer Support02.jpg"

const CustomerSupport = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="lg:my-60">
       <p className="text-[34px]  lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in"> 24/7 Customer Support
      </p>
      <div className='md:flex md:justify-between'>
      <div className='md:w-1/2 m-5 xl:ml-[-5%]'data-aos="fade-right" >
      <img src={CustomerSupport1} className='h-3/4 xl:ml-[16%] mt-5   rounded-3xl'></img>
      <div className="border border-white xl:ml-[20%] my-5 rounded-3xl mx-5">
      <p className=' mt-5'></p>
      <p className='ml-5  mt-5 text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Accessible Assistance :</span>   Round-the-clock support</p>
      <p className='ml-5 my-5 text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Multi-Channel Communication :</span> Diverse support channels</p>
      
      </div>
      </div>
      <div className='md:w-1/2 mt-20 xl:mr-[5%]' data-aos="fade-left">
        <div className="mx-5  xl:ml-[25%]  md:mr-[10%] border border-white rounded-3xl my-5">
        <p className='ml-5 mt-5  text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Timely Response : </span>Prompt customer assistance</p>
        <p className="text-xl mb-5 ml-[57%]"></p>
        <p className='ml-5 mt-2 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold  text-2xl">Customer-Centric Approach : </span>Focused customer support</p>
        <p  className='ml-[64%] mb-5 text-xl'></p>
        </div>
      <div className="p-6">
      <img src={CustomerSupport2} className='h-3/4 rounded-3xl   md:mt-5 md:mt-15 xl:ml-[8%]'></img>
      </div>
      </div>
    </div>
    </div>
  )
}

export default CustomerSupport
// 24/7 Customer Support:
// Accessible Assistance: Round-the-clock support.
// Multi-Channel Communication: Diverse support channels.
// Timely Response: Prompt customer assistance.
// Customer-Centric Approach: Focused customer support.
