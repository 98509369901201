import React from "react";
import LandingPage from "./LandingPage";
import About from "./About/About";
import Contact from "./Contact/Contact";
import Career from "./Career/Career";
import Options from "./Options";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import NewArrivals from "./New Arrivals/NewArrivals";
import SustainabilityEfforts from "./Sustainability Efforts/SustainabilityEfforts";
import CompanyPartnerships from "./Company Partnerships/CompanyPartnerships";
import SeasonalCampaigns from "./Seasonal Campaigns/SeasonalCampaigns";
import IndustryInsightsandTrends from "./Industry Insights and Trends/IndustryInsightsandTrends";
import FashionHistory from "./Fashion History/FashionHistory";
import FashionInnovationLab from "./Fashion Innovation Lab/FashionInnovationLab";
import Fashionforallages from "./Fashion for all ages/Fashionforallages";
import CustomerSatisfaction from "./Customer Satisfaction/CustomerSatisfaction";
import FashionInsights from "./FashionInsights/FashionInsights";
import FAQ from "./FAQ/FAQ";
import RefundPolicyandCancellationPolicy from "./RefundPolicyandCancellationPolicy"
import PrivacyandPolicy from "./PrivacyandPolicy"
import TermsandConditions from "./TermsandConditions"
import UserGuidlines from "./UserGuidlines";


const Body = () => {
  return (
    <div>
       <BrowserRouter>
       <ScrollToTop/>
      <Routes>
      <Route path="/" element={<LandingPage/>}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/contact" element={<Contact/>}></Route>
      <Route path="/career" element={<Career/>}></Route>
      <Route path="/faq" element={<FAQ/>}></Route>
      <Route path="/options" element={<Options/>}></Route>
      <Route path="/fashioninsights" element={<FashionInsights/>}></Route>
      <Route path="/newarrivals" element={<NewArrivals/>}></Route>
      <Route path="/sustainabilityefforts" element={<SustainabilityEfforts/>}></Route>
      <Route path="/companypartnerships" element={<CompanyPartnerships/>}></Route>
      <Route path="/seasonalcampaigns" element={<SeasonalCampaigns/>}></Route>
      <Route path="/industryinsightsandtrends" element={<IndustryInsightsandTrends/>}></Route>
      <Route path="/fashionhistory" element={<FashionHistory/>}></Route>
      <Route path="/fashioninnovationlab" element={<FashionInnovationLab/>}></Route>
      <Route path="/fashionforallages" element={<Fashionforallages/>}></Route>
      <Route path="/customersatisfaction" element={<CustomerSatisfaction/>}></Route>
      <Route path="/userguidlines" element={<UserGuidlines/>}></Route>
      <Route path="/termsandconditions" element={<TermsandConditions/>}></Route>
      <Route path="/privacyandpolicy" element={<PrivacyandPolicy/>}></Route>
      <Route path="/refundpolicyandcancellationpolicy" element={<RefundPolicyandCancellationPolicy/>}></Route>   
      </Routes>
      </BrowserRouter>
      {/* <RouterProvider router={appRouter} /> */}
    </div>
  );
};

export default Body;
