import React from 'react'
import PathGrowth1 from "../../Career Page Images/Career Paths and Growth Opportunities01.jpg"
const PathGrowth = () => {
  return (
    <div className='text-white  lg:my-60 pb-10 '>
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">Career Paths and Growth Opportunities
      </p>
      <div className='lg:flex'>
        <div className='lg:w-1/2 p-5 lg:m-10 xl:m-16 xl:mt-40 md:ml-20' data-aos="fade-right">
      <p className='text-xl md:text-2xl mb-5'>Professional Development Programs</p>
      <p className='text-xl md:text-2xl mb-5'>Mentorship and Coaching</p>
      <p className='text-xl md:text-2xl mb-5'>Leadership Training</p>
      <p className='text-xl md:text-2xl mb-5'>Promotion and Advancement Paths</p>
        </div>
        <div className='lg:w-1/2 p-5 xl:m-16' data-aos="fade-left">
          <img className='rounded-3xl' src={PathGrowth1}></img>
        </div>
      </div>



    </div>
  )
}

export default PathGrowth