import React from 'react'

const ApplicationProcess = () => {
  return (
    <div className='text-white  lg:my-60 pb-10 '>
    <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">Application Process
    </p>
    <div className='lg:flex lg:justify-evenly my-40' data-aos="fade-up">
     <div>
     <div className="ApplicationProcess  w-60 h-60 rounded-full inline-flex items-center justify-center bg-white text-black text-xl font-bold ml-10 lg:ml-0 mb-5 ">
        <p>Welcome & Orientation</p>
      </div>
      <p className='Steps font-bold text-3xl ml-24 lg:ml-14 mb-20'>STEP - 1</p>
     </div>
      <div>
      <div className="ApplicationProcess w-60 h-60 rounded-full inline-flex items-center justify-center bg-white text-black text-xl font-bold ml-10 lg:ml-0 mb-5"><p>Training & Development</p>
      </div>
      <p className='Steps font-bold text-3xl ml-24 lg:ml-14 mb-20'>STEP - 2</p>
      </div>
      
      <div>
      <div className="ApplicationProcess w-60 h-60 rounded-full inline-flex items-center justify-center bg-white text-black text-xl font-bold ml-10 lg:ml-0 mb-5"><p>Role Integration</p>
      </div>
      <p className='Steps font-bold text-3xl ml-24 lg:ml-14 mb-20'>STEP - 3</p>

      </div>
     
    <div>
    <div className="ApplicationProcess w-60 h-60 rounded-full inline-flex items-center justify-center bg-white text-black text-xl font-bold ml-10 lg:ml-0 mb-5"><p>Assistance & Feedback</p>
    </div>
    <p className='Steps font-bold text-3xl ml-24 lg:ml-14 mb-20'>STEP - 4</p>
    </div>
     
  </div>
</div>
  )
}

export default ApplicationProcess