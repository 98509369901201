import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Testimonials1 from "../../Career Page Images/TESTIMONIALS Alex M.jpg"
import Testimonials2 from "../../Career Page Images/TESTIMONIALS Jamie L.jpg"
import Testimonials3 from "../../Career Page Images/TESTIMONIALS Samira K.jpg"

function Testimonials() {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className='w-3/4 m-auto lg:my-60' data-aos="zoom-in">
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14">Employee Testimonials
      </p>
      <div className="mt-20">
      <Slider {...settings}>
        {data.map((d) => (
          <div key={d.name} className="bg-white h-[530px] md:h-[450px] text-black rounded-xl">
            <div className='h-56 bg-white flex justify-center items-center rounded-t-xl'>
              <img src={d.img} alt="" className="h-44 w-44 rounded-full"/>
            </div>

            <div className="flex flex-col items-center justify-center gap-4 p-4">
              <p className="text-2xl font-bold">{d.name}</p>
              <p className="text-[14px] md:text-xl font-semibold">{d.post}</p>
              <p className="text-center">{d.review}</p>
            </div>
          </div>
        ))}
      </Slider>
      </div>
      
    </div>
  );
}

const data = [
  {
    name: `Alex M`,
    img: Testimonials1 ,
    post:`Marketing Specialist`,
    review: `Working at Poshavesh has been an incredible journey. The supportive and collaborative environment allows me to grow professionally while feeling valued every step of the way. `
  },
  {
    name: `Jamie L`,
    img: Testimonials2,
    post:`Product Designer`,
    review: `Poshavesh truly cares about its employees' well-being and career development. The opportunities for training and advancement have helped me achieve my career goals and more.`
  },
  {
    name: `Samira K`,
    img: Testimonials3,
    post: `Customer Support Manager`,
    review: `Being part of the Poshavesh team means being part of a family. The inclusive culture and innovative spirit make every day exciting and fulfilling.`
  },
];

export default Testimonials;