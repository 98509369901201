import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SeasonalPicks1 from "../../New Arrivals Images/seasonal pics.jpg" 


const SeasonalPicks = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='md:my-32  lg:my-20'>
       <p className="text-4xl  lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Seasonal Picks
      </p>
        <div className='lg:flex lg:justify-evenly'>
        <div data-aos="zoom-in" className="p-5 md:p-16 lg:p-5 lg:mr-10 xl:m-10 h-3/4 lg:ml-20">
            <img src={SeasonalPicks1} className='rounded-3xl   '></img>
        </div>
        <div className='lg:w-screen lg:mx-10 md:m-10'>
          <div className="m-3">
          <p className='flex justify-center border border-white rounded-xl text-xl lg:mb-[55px] xl:mb-[150px]  mx-1 my-5 p-5 lg:text-[18px] xl:text-xl ' data-aos="fade-right">
            <span className="font-bold text-xl mx-3 ">Fall Finesse :</span>Cozy Knits, Stylish Layers</p>
          </div>
           
          <div className="m-3">
          <p className='flex justify-center lg:mb-[55px] xl:mb-[150px] border border-white mx-2 rounded-xl my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-left"><span className="font-bold text-xl  mx-3">Summer Staples :</span>Sun-Soaked Styles </p>
          </div>
       
            <div className="m-3">
            <p className='flex justify-center lg:mb-[55px]  xl:mb-[150px] border border-white mx-2 rounded-2xl my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-right"><span className="font-bold text-xl  mx-3">Holiday Glamour :</span> Party-Perfect Dresses</p>
            </div>
           
            <div className="m-3">
            <p className='flex justify-center  lg:mb-[55px] xl:mb-[150px] border border-white mx-2 rounded-2xl  my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-left"><span className="font-bold text-xl mx-3 ">Spring Flair :</span> Fresh Floral Picks</p>
            </div>
            

        </div>
        </div>
    </div>
  )
}

export default SeasonalPicks
// Seasonal Picks
// Fall Finesse: Cozy Knits, Stylish Layers
// Summer Staples: Sun-Soaked Styles
// Holiday Glamour: Party-Perfect Dresses
// Spring Flair: Fresh Floral Picks


