import React from "react";
// import BG_VID from "../assets/demo.mp4";
import BG_VID from "../assets/Poshavesh Final Video.m4v";

const VideoSection = () => {
  return (
    <div>
      <div className="md:w-screen md:aspect-video ">
        <video className="md:object-cover " autoPlay loop muted>
          <source src={BG_VID} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default VideoSection;
