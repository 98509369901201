import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Team1 from "../../About Us Images/Shruti V Iyer.png"
import Team2 from "../../About Us Images/BISHOP ADHIKARI.png"
import Team3 from "../../About Us Images/Deepak Dev Raj.png"
import Team4 from "../../About Us Images/Megna Raj.png"
import Team5 from "../../About Us Images/Abhi Deb Roy.png"
import Team6 from "../../About Us Images/Parag R.png"



const Team = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white my-20 lg:my-60">
      <p
        className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"
        data-aos="zoom-in"
      >
        Our Team
      </p>
      <div className="lg:flex lg:flex-wrap lg:mt-28" data-aos="fade-up">
        <div className="lg:w-3/12 border border-white p-3 lg:ml-[40px] xl:ml-[95px]   rounded-xl cursor-pointer hover:scale-105 duration-500  mx-3 md:mx-48 lg:mx-0  mb-5">
          <div className="flex justify-center">
            <img
              className="rounded-full  w-[140px] h-[110px] "
              src={Team1}
              alt="person"
            ></img>
          </div>
          <p className="flex justify-center text-xl lg:text-2xl  my-2 font-bold">
            Shruti V Iyer{" "}
          </p>
          <p className="flex justify-center my-1">CEO, Co-Founder</p>
        </div>
        <div className="lg:w-3/12 border border-white p-3 lg:mx-[70px] xl:mx-[75px]   rounded-xl cursor-pointer hover:scale-105 duration-500 mx-3 md:mx-48   mb-5">
          <div className="flex justify-center">
            <img
              className="rounded-full  w-[140px] h-[110px] "
              src={Team2}
              alt="person"
            ></img>
          </div>
          <p className="flex justify-center text-xl lg:text-2xl my-2 font-bold">
            Bishop Adhikari
          </p>
          <p className="flex justify-center my-1">CMO, Co-Founder</p>
        </div>
        <div className="lg:w-3/12 border border-white p-3  xl:mr-[75px] rounded-xl cursor-pointer hover:scale-105 duration-500 mx-3 md:mx-48 lg:mx-0 mb-5">
          <div className="flex justify-center">
            <img
              className="rounded-full  w-[140px] h-[110px] "
              src={Team3}
              alt="person"
            ></img>
          </div>
          <p className="flex justify-center text-xl lg:text-2xl my-2 font-bold">
            Deepak Dev Raj
          </p>
          <p className="flex justify-center my-1">CXO</p>
        </div>
        <div className="lg:w-3/12 border border-white p-3  lg:ml-[40px] xl:ml-[95px]  rounded-xl cursor-pointer hover:scale-105 duration-500 mx-3 mb-5  md:mx-48 lg:mx-0">
          <div className="flex justify-center">
            <img
              className="rounded-full  w-[140px] h-[110px] "
              src={Team4}
              alt="person"
            ></img>
          </div>
          <p className="flex justify-center text-xl lg:text-2xl my-2 font-bold">
            Megna Raj
          </p>
          <p className="flex justify-center my-1">Fashion Designer</p>
        </div>
        <div className="lg:w-3/12 border border-white p-3 lg:mx-[70px]  xl:mx-[75px] rounded-xl cursor-pointer hover:scale-105 duration-500 mx-3 mb-5 md:mx-48 ">
          <div className="flex justify-center">
            <img
              className="rounded-full  w-[140px] h-[110px] "
              src={Team5}
              alt="person"
            ></img>
          </div>
          <p className="flex justify-center text-xl lg:text-2xl my-2 font-bold">
            Abhi Deb Roy
          </p>
          <p className="flex justify-center my-1">COO</p>
        </div>
        <div className="lg:w-3/12 border border-white p-3  lg:mr-[75px]  rounded-xl cursor-pointer hover:scale-105 duration-500 mx-3 mb-5 md:mx-48 lg:mx-0">
          <div className="flex justify-center">
            <img
              className="rounded-full  w-[140px] h-[110px] "
              src={Team6}
              alt="person"
            ></img>
          </div>
          <p className="flex justify-center text-xl lg:text-2xl my-2 font-bold">Parag R </p>
          <p className="flex justify-center my-1">CFO</p>
        </div>
      </div>
    </div>
  );
};

export default Team;
