import React from 'react'
import Navbar from "./Navbar"
import Footer from "./Footer"
import Footer2 from "./Footer2"

const PrivacyandPolicy = () => {
  return (
    <div className=' overflow-x-hidden'>
      <Navbar/>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-40 mb-14"  data-aos="zoom-in">Privacy and Policy</p>
      <div data-aos="fade-up">
      <p className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
      <div className='font-bold my-3'>WHAT TYPE OF INFORMATION DOES POSHAVESH COLLECT?</div>
When you use this Site, we may collect information about you in the following ways:
Information we Actively Provide: We collect the Personally Identifiable Information (PII) you actively provide when you use this Site using your mouse or keyboard. PII may include your name; phone numbers; credit card information; demographic information; shopping and contact preferences; home, business, billing, and shipping addresses; e-mail address; and bar number and date passed. You may choose to provide PII when you access or use features or services of the Site, such as making a purchase, requesting information, registering on the Site, filling out a survey, or providing feedback. Where we can, we indicate which fields of information are required and which fields are optional. You can always choose not to provide information but may not be able to use a particular service or feature on the Site.
<div className='font-bold my-3'>Information Sent to Us by Your Web Browser :-</div> 
We also collect information that is sent to us automatically by your web browser, including your computer’s Internet Protocol address, the address of the page you were visiting when you accessed our Site, the name of the operating system and type of web browser installed on your computer, and the date and time of your visit. The information we receive depends on the settings on your web browser. Please check with the developer of your browser if you want to learn what information the browser sends or how to change your settings to limit the information that is sent. Information Collected Using “Cookies.” We use “cookies,” and similar technologies to help you in your online shopping and to personalize your experience on this Site. Cookies cannot be used to install computer programs or deliver viruses to your computer. As is true on most web sites, we may use cookies to collect information about the ways visitors use our Site (known as “click-stream information”) to better understand our visitors’ interests and needs, to improve the content and functionality of our Site, and to facilitate the proper functioning of our Site. We may also use cookies to remember your login name or preferences for your future visits to our Site, and to improve your experience when using our Site. We may link the information collected by our cookies to PII and store it with other information we have collected from or about you.
You have the ability to set your browser to reject cookies, but if you set your browser to reject cookies, you may not be able to use certain features and functionality of this Site.
<div className='font-bold my-3'>OUR USE OF YOUR PII :-</div> 
We use PII you submit to us for various business purposes. These purposes include processing orders and requesting information or improving our Site for our visitors. If you provide us with your e-mail address as part of a purchase transaction on our Site, or if you opt-in to receive communications from POSHAVESH, we may send you newsletters or e-mails featuring promotions, informing you about new POSHAVESH products and services, requesting your feedback, or providing other information we think may be of interest to you. In addition, if you make a purchase on our Site, we will send you order and shipping confirmation emails. You can remove your e-mail from our e-mail list at any time by following the procedures described in the “Accessing and Updating Your Information” section below to change your preferences, or clicking on the “unsubscribe” link in every POSHAVESH promotional e-mail.
<div className='font-bold my-3'>DOES POSHAVESH SHARE YOUR INFORMATION?</div>
POSHAVESH will not sell or lease any PII that you share with us.
POSHAVESH may share your information with others in the following limited circumstances:
<div className='font-bold my-3'>Third Party Sites :-</div>
 You may visit other websites, through links on our Site, that may collect, use and share your information in accordance with their own privacy policies. The information practices of those websites linking from or to our Site are not covered by this Privacy Policy. We encourage you to be very cautious before you disclose your information to others.
<div className='font-bold my-3'>Our Service Providers :-</div>
 We share PII collected through our Site with companies and organizations that perform services on our behalf, such as data storage, web hosting services, and order fulfillment companies. These companies and organizations are required by contract to use the PII we share with them only to perform services on our behalf (rather than for their own purposes) and to protect the confidentiality of the PII we share with them.
<div className='font-bold my-3'>Our Successors :-</div>
 If that POSHAVESH is acquired by or merged with a third-party, the information POSHAVESH collects may be one of the assets examined or transferred as part of the transaction. POSHAVESH will not permit another business to examine the PII POSHAVESH has collected without a confidentiality agreement? If a transaction is completed, POSHAVESH reserves the right to transfer or assign the PII it has collected from its users as part of such merger, acquisition, sale, or other change of control. POSHAVESH will not transfer the information it has collected unless the recipient agrees to provide privacy protections equal to or exceeding those established by this Privacy Policy. In the unlikely event POSHAVESH is involved in a bankruptcy proceeding, it may sell the PII that it has collected to the extent permitted by applicable law.
<div className='font-bold my-3'>Other Limited Circumstances :-</div> 
There are other limited circumstances in which we may share the PII in our databases with unrelated third parties. For example, we may disclose the PII when we believe disclosure is appropriate to comply with the law or a regulatory requirement or to comply with a subpoena or court order; prevent or investigate a possible crime, such as fraud or identity theft; enforce a contract; protect the rights, property or safety of POSHAVESH our customers and visitors to our Site; or protect the vital interests of our customers or visitors.
<div className='font-bold my-3'>ACCESSING AND UPDATING YOUR INFORMATION :-</div>
You may review and change the PII that you provide to us by logging into the Site and entering in the new information yourself in the “My Account” section of the Site.
You may ask to have your account information removed from our active databases and to mark your information as “do not allow.” If you request to be placed on “do not allow” status, although your information will remain in our records, POSHAVESH will not contact you for any of the purposes set forth in this Privacy Policy or otherwise. We prefer to retain your information and place your PII in “do not allow” status rather than deleting your information entirely to ensure that the next time you contact POSHAVESH or make a purchase from us, we do not add you to our mailing lists or otherwise contact you as described in this Privacy Policy. However, if you object to this practice and wish to instead have your information completely removed from our database, please send an e-mail to (adoreforgrowth@bisjhintus.com)  with a request that your information be deleted in its entirety rather than marked as “do not allow.”
<div className='font-bold my-3'>SECURITY :-</div>
POSHAVESH uses commercially reasonable physical, technical, and administrative safeguards to help protect against the loss, misuse, and unauthorized access to, alteration or destruction of any content or information you may have provided while using the Site. We store information on our secure servers and use industry-standard encryption technologies when transferring and receiving user data exchanged with our Site.
Additionally, POSHAVESH uses physical and electronic security measures, including Secure Sockets Layer (SSL), to protect your information. Only POSHAVESH has the key needed to decode your information. You can tell if the page you are on is using SSL technology by looking at the lower left-hand corner of your browser window. If you see an unbroken key or a closed lock, SSL is engaged and your information is secure. You also can look at the URL line of your browser. When accessing a secure page, the characters of the site address will change from “http” to “https.” When you send us e-mail over the Internet, the transmission is not protected from interception. Please note that we cannot ensure or warrant the security of any information that you transmit to or store at POSHAVESH. If, for any reason you are unable to connect to our server or would rather place your order by phone, please call us at POSHAVESH.


<div className='font-bold my-3'>UPDATES TO PRIVACY POLICY :-</div>
From time to time, we may update or revise our Privacy Policy. We reserve the right to do so at any time by posting the revised version of this Privacy Policy on the Site. Please check this page for changes. If we update or revise this Privacy Policy, we will not use or share your information in a manner that is incompatible with the policy in effect when your PII was initially collected.


<div className='font-bold my-3'>INTERNATIONAL USERS :-</div>
This Site is hosted in India and is intended for, and directed to, users in India. If you are visiting this Site from a country other than India with laws or regulations governing personal data collection, use, and disclosure that differ from the laws in India, your use of the Site is governed by Indian law, this Privacy Policy and POSHAVESH Terms of Use.


<div className='font-bold my-3'>GOVERNING LAW :-</div>
This Privacy Policy forms part of our Site Terms of Use, which is subject to all the prevailing laws of India.
      </p>
      </div>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default PrivacyandPolicy