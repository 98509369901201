import React from 'react'

const CompanyValues = () => {
  return (
    <div className='text-white  lg:my-60 pb-10 ' >
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">Company Values and Culture
      </p>
      <div className='' data-aos="fade-up">
      <p className='flex justify-center text-xl md:text-2xl mx-16 md:mx-40 lg:mx-20 md:mb-5'>
      At Poshavesh, we foster a culture of innovation, integrity, and inclusivity. 
      </p>
      <p className='flex justify-center text-xl md:text-2xl mx-16 md:mx-40 lg:mx-20 md:mb-4'>Our commitment to excellence and collaboration ensures a dynamic environment </p>
      <p className='flex justify-center text-xl md:text-2xl  mx-16 md:mx-40'>where every voice is valued and creativity thrives. </p>
      </div>
    </div>
  )
}

export default CompanyValues