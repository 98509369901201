import React from 'react'

const JobOpening = () => {
  return (
    <div className='text-white lg:my-60 pb-10'>
      <p className="text-4xl lg:text-6xl flex justify-center  font-bold  my-10 mb-14" data-aos="zoom-in">Job Opening
      </p>
      <div className='md:flex md:justify-evenly mt-20' data-aos="fade-up">
        <div className='border border-white  p-8 md:p-8 lg:p-10 xl:p-16 rounded-3xl  xl:text-2xl lg:w-[320px] xl:w-[460px] mx-2 mb-5'> <p className='flex justify-center'>Marketing Coordinator</p>
</div>
        <div className='border border-white p-8 md:p-8 lg:p-10 xl:p-16 rounded-3xl  xl:text-2xl lg:w-[320px]  xl:w-[460px] mx-2 mb-5'><p className='flex justify-center'>Product Designer</p>
</div>
        <div className='border border-white p-8 md:p-8 lg:p-10 xl:p-16 rounded-3xl  xl:text-2xl lg:w-[320px]   xl:w-[460px] mx-2 mb-5'><p  className='flex justify-center'>Customer Support Specialist</p></div>

      </div>


    </div>
  )
}

export default JobOpening