import React from 'react'
import IntroductiontotheFashionInnovationLab from './IntroductiontotheFashionInnovationLab'
import ResearchAndDevelopment from './ResearchAndDevelopment'
import PrototypingAndTestingFacilities from './PrototypingAndTestingFacilities'
import EventsAndWorkshops from './EventsAndWorkshops'
import OpenInnovationOpportunities from './OpenInnovationOpportunities'
import IndustryImpactandSuccessStories from './IndustryImpactandSuccessStories'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Footer2 from '../Footer2'
import ShopNowButton from '../ShopNowButton'
const FashionInnovationLab = () => {
  return (
    <div className='overflow-x-hidden'>
      <Navbar />
      <IntroductiontotheFashionInnovationLab/>
      <ShopNowButton/>
      <ResearchAndDevelopment/>
      <ShopNowButton/>
      <PrototypingAndTestingFacilities/>
      <ShopNowButton/>
      <EventsAndWorkshops/>
      <ShopNowButton/>
      <OpenInnovationOpportunities/>
      <ShopNowButton/>
      <IndustryImpactandSuccessStories/>
      <ShopNowButton/>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default FashionInnovationLab