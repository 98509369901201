import React from 'react'

const LanguageOptions = () => {
  return (
    <div className='text-white  lg:mt-60 pb-10 ' data-aos="fade-up">
      <p className='flex justify-center text-3xl md:text-5xl m-5 font-bold'>Language & Accessibility Options</p>
      <p className='flex justify-center text-xl  md:text-3xl m-5 my-10'>We provide support and multilengual site</p>
    </div>
  )
}

export default LanguageOptions