import React from "react";
import Header from "./Header";
import OurStory from "./OurStory";
import VisionMissionGoals from "./VisionMissionGoals";
import Team from "./Team";
import BrandPhilosophy from "./BrandPhilosophy";
import CustomerCentric from "./CustomerCentric";
import AchievementRecognition from "./AchievementRecognition";
import BrandIdentity from "./BrandIdentity";
import TransparencyAccountability from "./TransparencyAccountability";
import FutureVision from "./FutureVision";
import ContactInfo from "./ContactInfo";
import ConnectWithPV from "./ConnectWithPV";
import Navbar from "../Navbar"
import Footer from "../Footer";
import Footer2 from "../Footer2";
const About = () => {
  return (
    <div className=" overflow-x-hidden">
      <Navbar/>
      <Header />
      <VisionMissionGoals />
      <OurStory />
      <Team />
      <BrandPhilosophy />
      <CustomerCentric />
      <AchievementRecognition />
      <BrandIdentity />
      <TransparencyAccountability />
      <FutureVision />
      <ContactInfo />
      <ConnectWithPV />
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  );
};

export default About;
