import React from 'react'
import Insta from "../../Social Media Icons/Instagram-Icon.png"
import X from "../../Social Media Icons/Twitterx-Icon.png"
import Linkedin from "../../Social Media Icons/Linkedin-Icon.png"
import Facebook from "../../Social Media Icons/Facebook-Icon.png"
import Pinrest from "../../Social Media Icons/Pinterest-Icon .png"

const DetailsSocialMedia = () => {
  return (
    <div className='text-white  my-20 lg:my-60' data-aos="fade-up" >
      <div className='xl:flex xl:justify-between'>
      <div className='border border-white xl:w-1/2 xl:h-[400px] m-5 rounded-2xl'>
        <p className='flex justify-center font-bold text-[20px] md:text-2xl my-5'>Company Contact Details</p>
        <div className="md:flex md:justify-evenly mt-20">
        <div
          className="md:w-1/2 border border-white h-[200px] mx-5 mb-5 rounded-xl hover:scale-105  duration-700"
          
        >
          <div className="flex justify-center">
          <img src="https://thumb.ac-illust.com/1e/1e4e382499bd10f4bc814033adb85517_t.jpeg"
           className=" h-[80px] mt-5">
          </img>
          </div>
          <p className="text-[15px] font-bold flex justify-center mt-7">
            www.poshavesh.com
          </p>
        </div>
        <div
          className="md:w-1/2 border border-white mx-5 h-[200px] mb-5  rounded-xl hover:scale-105 duration-700"
          
        >
          <div className="flex justify-center mt-5">
          <img src="https://static.vecteezy.com/system/resources/previews/015/720/174/original/eps10-white-phone-call-or-telephone-abstract-icon-isolated-on-black-background-contact-us-or-hotline-symbol-in-a-simple-flat-trendy-modern-style-for-your-website-design-logo-and-mobile-app-vector.jpg"
           className=" h-[80px]">
          </img>
          </div>
          <p className="text-[15px] font-bold flex justify-center mt-8">
            +91-9612676260
          </p>
        </div>
        <div
          className="md:w-1/2 border border-white h-[200px] mx-5 mb-5 rounded-xl hover:scale-105 duration-700"
          
        >

         <div className="flex justify-center mt-5 ">
          <img src="https://i.pinimg.com/736x/b8/a3/2d/b8a32d5bfb694e012dde03d2e6b90c9f.jpg"
           className=" h-[80px]">
          </img>
          </div>
          <p className="text-[15px] font-bold flex justify-center mt-7">
            info@poshavesh.com
          </p>
        </div>
      </div>
      </div>
      <div  className='border border-white xl:w-1/2 md:h-[400px] m-5 rounded-2xl'>
      <p className='flex justify-center font-bold text-[20px] md:text-2xl my-5'>Follow us here</p>
      <div className="md:flex md:justify-evenly mt-20 ">
      
      <a href='https://www.instagram.com/poshavesh/?fbclid=IwAR3z1dCmhSbo2bONtagroJoqdd4uwvLO7v4Cwx1LwKMACJqevtEWyvyjgcQ' target='blank'>
      <div className="logo hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mb-5 mx-[25%]  md:mx-0 lg:m-2" data-aos="zoom-in">
          <img
            src={Insta}
            className="rounded-full w-[100px]"
          ></img>
        </div>
      </a>
      
      <a href='https://www.linkedin.com/company/poshavesh/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BtR%2F3RZrjS3Gld23rbgaxMw%3D%3D' target='blank'>
      <div
            className="logo hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mb-5 mx-[25%] md:mx-0 lg:m-2" data-aos="zoom-in"
          >
            <img
              src={Linkedin}
              className="rounded-full w-[100px]"
            ></img>
          </div>
      </a>
        
        <a href='https://www.facebook.com/share/zDzP1Jg35cED3h8P/?mibextid=qi2Omg' target='blank'>
        <div
            className="logo hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mb-5 mx-[25%]  md:mx-0 lg:m-2" data-aos="zoom-in"
          >
            <img
              src={Facebook}
              className="rounded-full w-[100px]"
            ></img>
          </div>
        </a>
         
         <a href='https://in.pinterest.com/poshavesh/' target='blank'>
         <div
          className="logo hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mb-5  mx-[25%]  md:m-2 lg:m-2" data-aos="zoom-in">
          <img
            src={Pinrest}
            className="rounded-full w-[100px]"></img> 
          </div>
         </a>
          
          <a href='https://x.com/PoshaVesh' target='blank'>
          <div
          className="logo hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mb-5 mx-[25%]  md:m-2 lg:m-2" data-aos="zoom-in"
        >
          <img
            src={X}
            className="rounded-full w-[100px]"
          ></img>   
          </div>
          </a>
            
      </div>
      </div>
      </div>
     
    </div>
  )
}

export default DetailsSocialMedia