import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import  FashionIconsandInfluencers1 from "../../Fashion For All ages/Fashion Icons and Influencers.jpg"

const FashionIconsandInfluencers = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className=' lg:my-40' >
        <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in">Fashion Icons and Influencers
      </p>
        <div className='flex justify-center  border border-white m-5 md:m-14 lg:m-3  lg:ml-[30%] lg:mr-[30%] my-5 rounded-2xl ' data-aos="fade-down">
           <p className=" mx-5 my-5 md:my-10 text-xl lg:text-[18px] xl:text-xl"><span className="font-bold text-2xl">Diverse Style Inspirations : </span> Varied fashion influencers</p>
        </div>
        <div className='lg:flex lg:justify-between'>
            <div className='lg:w-[450px] xl:w-4/12 border border-white m-5 md:m-14  xl:m-10 lg:my-2 rounded-3xl lg:p-5 xl:p-0'data-aos="fade-right" >
              <p className="flex justify-center font-bold text-2xl my-3 xl:mt-[35%]">Celebrating Diversity</p> 
                <p className='flex justify-center   lg:mt-[15%] text-xl lg:text-[18px] xl:text-xl '>Diverse fashion </p>
                <p className='flex justify-center mb-3  lg:mt-[5%] text-xl lg:text-[18px] xl:text-xl '> representation</p>
            </div>
            <div data-aos="zoom-in" className="p-5 md:p-16 lg:p-5">
                <img src={FashionIconsandInfluencers1} className='rounded-3xl'></img>
            </div>
            <div className='lg:w-[450px] xl:w-4/12 border border-white m-5 md:m-14 xl:m-10 lg:my-2  rounded-3xl lg:p-5 xl:p-0' data-aos="fade-left">
            <p className="flex justify-center font-bold text-2xl my-3 mr-4 xl:mt-[35%]">   Inspiring Creativity</p>
            <p className='flex justify-center   mb-3  lg:mt-[15%] text-xl lg:text-[18px] xl:text-xl '>Fashion inspiring </p>
            <p className='flex justify-center   mb-3  lg:mt-[5%] text-xl lg:text-[18px] xl:text-xl '> creativity</p>
            </div>
        </div>
        <div className='flex justify-center rounded-2xl  border border-white m-5 md:m-14 lg:m-3  lg:ml-[30%]  my-5 lg:mr-[30%]' data-aos="fade-up">
           <p className=" mx-5 my-3 md:my-10 text-xl lg:text-[18px] xl:text-xl"><span className="font-bold text-2xl">Cross-Generational Influence : </span> Fashion icons across ages</p>
        </div>
    </div>
  )
}

export default FashionIconsandInfluencers
// . Fashion Icons and Influencers:
// Diverse Style Inspirations: Varied fashion influencers.
// Cross-Generational Influence: Fashion icons across ages.
// Celebrating Diversity: Diverse fashion representation.
// Inspiring Creativity: Fashion inspiring creativity.
