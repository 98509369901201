import React from 'react'
import Footer from "./Footer"
import Footer2 from "./Footer2"
import Navbar from "./Navbar"
const TermsandConditions = () => {
  return (
    <div className=' overflow-x-hidden'>
      <Navbar/>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-40 mb-14"  data-aos="zoom-in"> Terms and Conditions</p>
      <div>
      <p className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl' data-aos="fade-up">
These Terms and Conditions of the utilization of our site www.poshavesh.com ("Website"), our applications or any items or administrations regarding the Application/, Website/items ("Services") or (b) any methods of enrollments or use of items, including through SD cards, tablets or other stockpiling/communicating gadget are between POSHAVESH INDIA PRIVATE LIMITED  ("Company/We/Us/Our") and its clients ("User/You/Your").
These Terms establish an electronic record as per the arrangements of the Information Technology Act, 2000 and the Information Technology (Intermediaries rules) Rules, 2011 thereunder, as revised every now and then.
If it's not too much trouble, read the Terms and the security strategy of the Company ("Privacy Policy") as for enrollment with us, the utilization of the Application, Website, Services and items cautiously prior to utilizing the Application, Website, Services or items. In case of any inconsistency between the Terms and some other strategies regarding the Application or Website or Services or items, the arrangements of the Terms will win.
Your utilization/access/perusing of the Application or Website or the Services or items or enrollment (with or without installment/with or without membership) through any means will imply your acknowledgment of the Terms and Your consent to be legitimately limited by something very similar.
If you disagree with the Terms or the Privacy Policy, kindly don't utilize the Application or Website or profit the Services or items. Any admittance to our Services/Application/items through enlistments/membership is non transferable.
 <div className='font-bold my-3'> USE OF INFORMATION TOOLS :-</div>
Except as referenced underneath, all data, content, material, brand names, administrations marks, business trademarks, and proprietary innovations including yet not restricted to the product, text, pictures, designs, video, content and sound, contained in the Application, Website, Services and items are exclusive property of the Company ("Proprietary Information"). No Proprietary Information might be replicated, downloaded, repeated, changed, republished, transferred, posted, sent or disseminated in any capacity without acquiring earlier composed consent from the Company and nothing on this Application or Website or Services will be or items considered to give a permit of or some other right, interest or title to or in any of the protected innovation privileges having a place with the Company, to the User. You might claim the medium on which the data, content or materials dwells, however the Company will consistently hold full and complete title to the data, content or materials and all licensed innovation freedoms embedded by the Company on such medium. Certain substance on the Website might have a place with outsiders. Such substance has been repeated subsequent to taking earlier assent from said party and all privileges identifying with such substance will stay with such outsider. Further, you perceive and recognize that the responsibility for brand names, copyright, logos, administration marks and other licensed innovation claimed by any outsider will keep on vesting with such party and you are not allowed to utilize something similar without the assent of the particular outsider.
<div className='font-bold my-3'>LIMITATION OF LIABLITY :-</div>
Your utilization of our items, Website, Application and Services is exclusively for your own and non-business use. Any utilization of the Application, Website, Services or items or their substance other than for individual designs is precluded. Your own and non-business utilization of this Application, Website, items and/or our Services will be exposed to the accompanying limitations:
You may not decompile, pick apart, or dismantle the substance of the Application as well as our Website and additionally Services/items or adjust, duplicate, appropriate, send, show, perform, imitate, distribute, permit, make subordinate works from, move, or sell any data or programming acquired from the Application or potentially our Website as well as Services/items, or eliminate any copyright, brand name enlistment, or other restrictive notification from the substance of the Application or potentially as well as our Website or potentially Services/items.
You will not
utilization this Application or potentially our website and additionally any of our item/s or Service/s for business motivations behind any sort, or
promote or sell the Application or any items, administrations or space names or in any case (whether or not really for benefit), or request others (counting, without impediment, sales for commitments or gifts) or utilize any open gathering for business reasons for any sort, or
 utilize the Application as well as Website/our items and Services in any capacity that is unlawful, or hurts the Company or some other individual or substance as dictated by the Company.
<div className='font-bold my-3'>USE OF PROHIBITED ACTIVITIES :-</div>
Making accessible any substance that is deceiving, unlawful, unsafe, undermining, oppressive, tortious, abusive, offensive, indecent, disgusting, youngster explicit, lustful, prurient, profane, obtrusive of another's protection, disdainful, or racially, ethnically or in any case frightful;
Stalking, threatening and additionally pestering another or potentially impelling other to submit viciousness;
Transmitting material that urges anybody to perpetrate a criminal offense, that outcomes in common responsibility or in any case penetrates any pertinent laws, guidelines or code of training;
Interfering with some other individual's utilization or happiness regarding the Application/Website/Services;
Making, sending or putting away electronic duplicates of materials ensured by copyright without the consent of the proprietor, submitting any demonstration that adds up to the encroachment of protected innovation or making accessible any material that encroaches any protected innovation freedoms or other restrictive privileges of any other person;
Make accessible any substance or material that You don't reserve an option to make accessible under any law or authoritative or guardian relationship, except if You own or control the privileges thereto or have gotten all fundamental assents for such utilization of the substance;
Impersonate any individual or substance, or erroneously state or in any case distort Your association with an individual or element;
Post, communicate or make accessible any material that contains infections, Trojan ponies, worms, spyware, delayed bombs, cancellous, or other PC programming schedules, code, records or such different projects that might hurt the Application/administrations, interests or privileges of different clients or cutoff the usefulness of any program, equipment or media communications, or that might gather or gather any information or individual data about different Users without their assent;
Access or utilize the Application/Website/Services/items in any way that could harm, handicap, overburden or hinder any of the Application's/Website's servers or the organizations associated with any of the servers on which the Application/Website is facilitated;
Intentionally or inadvertently meddle with or upset the administrations or abuse any appropriate laws identified with the admittance to or utilization of the Application/Website/Services/items, disregard any prerequisites, methods, strategies or guidelines of organizations associated with the Application/Website/Services/items, or take part in any movement precluded by these Terms;
Disrupt or meddle with the security of, or in any case influence damage to, the Application/Website/Services/items, materials, frameworks assets, or gain unapproved admittance to client accounts, passwords, servers or organizations associated with or open through the Application/Website/Services/items or any partnered or connected locales;
Interfere with, or restrain any client from utilizing and getting a charge out of admittance to the Application/Website/Services/items, or other subsidiary locales, or take part in troublesome assaults like disavowal of administration assault on the Application/Website/Services/items;
Use profound connections, page-scratch, robot, insect or other programmed gadget, program, calculation or approach, or any comparable or identical manual interaction, to build traffic to the Application/Website/Services/items, to get to, procure, duplicate or screen any part of the Application/Website/Services/items, or in any capacity recreate or evade the navigational construction or show of the Application, or any substance, to get or endeavor to get any substance, reports or data through any means not explicitly made accessible through the Application/Website/Services/items;
Alter or adjust any piece of the Services;
Use the Services for purposes that are not allowed by: (I) these Terms; and (ii) any appropriate law, guideline or by and large acknowledged practices or rules in the significant ward; or
Violate any of the terms indicated under the Terms for the utilization of the Application/Website/Services/items.

 <div className='font-bold my-3'>RESTRICTIONS PERMISSIONS FROM PRESENTER OF INFORMATION :-</div>
By submitting content on or through the Services (your "Material"), you award us an around the world, non-selective, eminence free permit (with the right to sublicense) to utilize, duplicate, recreate, process, adjust, alter, distribute, send, show and appropriate such Material in all possible media or dissemination techniques (presently known or later created) and to connect your Material with you, besides as portrayed beneath. You concur that others might utilize Your Material similarly as some other substance accessible through the Services. Different clients of the Services might fork, change and repurpose your Material as per these Terms. If you erase your client account your Material and name might stay accessible through the Services.
<div className='font-bold my-3'>USE OF INFORMATION / LIMITED LIABILITY :- </div>


Use of Information:
In the arrangement of the Application/Website/Services/items and substance in that, each work has been made to offer the most current, right, and unmistakably communicated data conceivable. All things considered; incidental mistakes might happen. Specifically, however without restricting anything here, the Company disavows any obligation regarding any blunders and precision of the data that might be contained in the Application. Any input from the User is generally free to make the Application and substance thereof mistake free and easy to use. The organization likewise saves the right and circumspection to roll out any improvements/redresses or pull out/add substance whenever without notice. Neither the Company nor any outsiders give any guarantee or assurance regarding the exactness, idealness, execution, culmination, or appropriateness of the data and materials found or presented on Application/Website/Services/items for a specific reason. You recognize that such data and materials might contain mistakes or blunders and we explicitly bar obligation for any such errors or blunders to the furthest reaches allowed by law.
Limited Liability
Our website furnishes Users with admittance to incorporated instructive data and related sources. We will not be responsible to refresh or guarantee congruity of such data contained on the Website. We would not be liable for any blunders, which may show up in such data, which is aggregated from outsider sources, or for any inaccessibility of such data. Every now and then the Website may likewise incorporate connections to different sites. These connections accommodate your comfort to give additional data. They don't mean that we underwrite the website(s). We have no obligation regarding the substance of the connected website(s). You may not make a connection to the Website from one more site or record without the Company's earlier composed assent.
<div className='font-bold my-3'>MISCELLANEOUS :-</div>

The substance of the Application/Services/items are created on the ideas canvassed in the organized educational plan schedule recommended for understudies of different courses. The use of the Application/Services/items isn't embraced as a replacement to the educational program-based instruction given by the instructive establishments yet is expected to enhance something similar by clarifying and introducing the ideas in a way empowering simple agreement. The fundamental definitions and formulae of the topic would continue as before. The Company recognizes that there are different method for conveying organized educational program teaching method and incorporation of techniques in the Application/Services/items doesn't infer support of a specific strategy nor does avoidance suggest objection. Membership to the Application or use of our Services/Website/items doesn't in any way ensure admission to any instructive foundations or breezing through of any tests or accomplishment of any predefined level of imprints in any assessments or examination.
Certain substances in the Application/Services/Website/items (specifically identifying with help with arrangements for authoritative administrations) may contain feelings and perspectives. The Company will not be answerable for such suppositions or any cases coming about because of them. Further, the Company makes no guarantees or portrayals at all with respect to the quality, content, fulfillment, or ampleness of such data and information.
A few pieces of the Services are intuitive, and we energize commitments by clients, which might be dependent upon publication control preceding being posted. The Company acknowledges no obligation or responsibility for any material conveyed by outsiders along these lines. The Company holds the right at its sole prudence to eliminate, survey, alter or erase any substance. Also, we won't be mindful or obligated for any substance transferred by Users straightforwardly on the Website, independent of whether we have confirmed any answer transferred by the User. We would not be mindful to confirm whether such inquiries/answers or substances put by any User contain encroaching materials or not.
The Company (counting however not restricted to its auxiliaries/subsidiaries) might be founded on any type of admittance to the Application (counting free download/preliminaries) or Services or Website or enlistments through any source at all, contact the User through SMS, email and call, to give data about their contributions and items just as notices on different significant updates as well as to look for consent for exhibition of its items. The User explicitly awards such authorization to reach him/her through phone, SMS, email and holds the Company (counting yet not restricted to its auxiliaries/partners) repaid against any liabilities including monetary punishments, harms, expenses on the off chance that the User's portable number is enlisted with Do not Call (DNC) data set. By enlisting yourself, you consent to make your contact subtleties accessible to Our representatives, partners, auxiliaries, associates, and accomplices so you might be reached for schooling data, contributions, and advancements through phone, SMS, email, and so on.
While the Company may dependent on the User's affirmation, work with the show of its items at the area looked for by the User, the User recognizes that he/she has not been incited by any assertions or portrayals of any individual regarding the quality or states of the items and that User has depended entirely on the examinations, assessments, and reviews as the User has decided to make and that the Company has managed the cost of the User the chance for full and complete examinations, assessments and investigations.
Upon enrollment through any means at all, the Company might get in touch with you through the enlisted portable number or email or some other versatile number or contact number or email given by you to empower compelling arrangement of Services. The User explicitly allows the Company to reach him/her and the understudy using the Services, through the previously mentioned implies whenever post-enlistment. .- . Further, the Company will reserve the option to screen the download and utilization of the Application/Services/items and the substance thereof by the User/understudy, to dissect such use and examine something similar with the User/understudy to empower powerful and proficient use of the Services. The User explicitly allows the Company to get the questions free from the understudy utilizing the Application/Services/online entrance by addressing the inquiries put before it, giving review plans, educating regarding the advancement, giving input, speaking with the understudy, and tutoring the understudy through phone or email on the express assent of the lawful watchman/parent of the User or through some other gathering.
While the Company has put forth attempts to prepare the work force occupied with the deals and administrations identifying with its items to empower quality control, it makes no guarantees or portrayals at all in regards to the quality and skill of such staff and would not be answerable for any degenerate conduct of any such faculty. Any input from the User identifying with the equivalent is generally welcome and Company saves the right and prudence to make any move in such manner.
<div className='font-bold my-3'>ADDITIONAL PERMISSION :-</div>

Admittance to specific components of the Services including question leeway, tutoring administrations, and so on might be liable to isolate terms, conditions, and reasonable use strategy. The Company maintains all authority to decide the rules for arrangement of different components of Services to the various classifications of Users dependent on its approaches. Consequently, membership to the Application/items or enrollments don't naturally qualify the User for all possible components of Services given by the Company and the Company will be qualified for practice its tact while giving admittance to and deciding progression of specific components of Services. We claim all authority to expand, drop, stop, rashly pull out or adjust any of Our Services whatsoever attentiveness.
The Company's items and/or Services, including the Application and content, are viable just with specific gadgets/tablets/instruments/equipment. The Company will not be committed to give useful items and/or administrations for any instruments that are not perceived by the Company or those instruments that might be bought from any outsider which are not viable with the Company's items and Services The organization maintains all authority to redesign the table/kind of viable gadgets as needed every now and then.
 <div className='font-bold my-3'>LOSS OR DAMAGE TO THE PRODUCT :-</div>
The Company will have no obligation regarding any misfortune or harm caused to electronic equipment or some other equipment and additionally programming as well as instrument, remembering loss of information or impact for the handling speed, coming about because of your utilization of our items and Services.
You need to determine the location to which the shipment must be made at the hour of procurement. All product(s) will be conveyed straightforwardly to the location as determined at the reason behind requesting and you can't, under any conditions at all, change the location after the request is handled. In the event of any adjustment of the location, you want to determine something similar to us recorded as a hard copy well ahead of time to the delivery date. Any irregularities in name or address will bring about non-conveyance of the product.
(a) For the arrival of product(s) harmed at the hour of conveyance, the transportation charges will be borne by the Company. In any case, for the arrival of any of the product(s) for some other reasons, it will be the obligation of the User to set up for the arrival of such dropped product(s) and the transportation charges will be borne by such User. (b)We demand you not to acknowledge any item bundle that is by all accounts messed with, opened, or harmed at the hour of conveyance. The items should be returned in a similar condition as conveyed by the Company. Any items returned giving indications of any utilization or harm in any way will not be acknowledged for return. (c)All demands for the arrival of items must be set inside 30 (thirty) days from the date of conveyance. If it's not too much trouble, note that no discounts will be guaranteed or will be engaged post 30 (thirty) days from the date of conveyance.
You recognize that the Company isn't the maker of the instrument/medium/equipment and consequently, any deformity identifying with the equivalent will be accounted for to the producer whose subtleties will be indicated on the bundling and the Company will not be in any way answerable for the equivalent. The Company doesn't give any assurance or guarantee identifying with the instrument/medium/equipment tools.
<div className='font-bold my-3'> ELIGIBILITY :-</div>
People who are "skilled/proficient" of contracting inside the significance of the Indian Contract Act, 1872 will be qualified to enlist for the Application and every one of our items or Services. People who are minors, un-released insolvents, and so forth are not qualified to enroll for our items or Services. As a minor, on the off chance that you wish to utilize our items or Services, such use will be made accessible to you by your legitimate gatekeeper or guardians, who have consented to these Terms. In the occasion a minor uses the Application/Website/Services, it is expected that he/she has acquired the assent of the lawful watchman or guardians and such use is made accessible by the legitimate gatekeeper or guardians. The Company won't be answerable for any outcome that emerges because of abuse of any sort of Our Application or any of our items or Services that might happen by uprightness of any individual including a minor enlisting for the Services/items given. By utilizing the items or Services You warrant that every one of the information given by You is exact and complete and that the understudy utilizing the Application has acquired the assent of the parent/lawful gatekeeper (in the event of minors). The Company maintains all authority to end Your membership as well as decline to give You admittance to the items or administrations in case it is found that You are younger than 18 (eighteen) a long time and the agree to utilize the items or Services isn't made by Your parent/legitimate gatekeeper or any data given by You is erroneous. You recognize that the Company doesn't have the obligation to guarantee that you adjust to the aforementioned qualification rules. It will be your sole liability to guarantee that you meet the necessary capability. Any people younger than 18 (eighteen) should look for the assent of their folks/legitimate parents prior to giving any data about themselves or their folks and other relatives on the Application.
You consent to shield, repay and hold innocuous the Company, its officials, chiefs, workers and specialists, from and against all possible cases, harms, commitments, misfortunes, liabilities, expenses or obligation, and costs (counting yet not restricted to lawyer's charges) emerging from: (I) Your utilization of and access of the Application/Website/Services; (ii) Your infringement of any term of these Terms or some other approach of the Company; (iii) Your infringement of any outsider right, including without limit, any copyright, property, or security right; or (iv) any case that Your utilization of the Application/Website/Services hosts made harm a third get-together. This protection and reimbursement commitment will endure these Terms.
In no occasion will the Company, its officials, chiefs, workers, accomplices, or specialists be at risk to you or any outsider for any unique, coincidental, aberrant, significant, or corrective harms at all, including those subsequent from loss of utilization, information, or benefits or some other case emerging out, of or regarding, your utilization of, or admittance to, the Application.
In case of your break of these Terms, you concur that the Company will be hopelessly hurt and might not have a sufficient cure in cash or harms. The Company, thus, will be qualified on such occasion to acquire an order against such a break from any court of skilled purview. The Company's on the right track to get such help will not restrict its entitlement to acquire different cures.
<div className='font-bold my-3'>TERMINATION OF ACCOUNT :-</div>

Any infringement by you of the provisions of this Clause might bring about prompt suspension or end of Your Accounts separated from any legitimate cure that the Company can benefit. In such examples, the Company may likewise unveil Your Account Information whenever needed by any Governmental or legitimate power. You comprehend that the infringement of these Terms could likewise bring about common or criminal obligation under pertinent laws.
<div className='font-bold my-3'>JURISDICTION :-</div>
The Terms will be represented by and interpreted as per the laws of India, regardless of contention of law standards. Further, the Terms will be dependent upon the selective ward of the equipped courts situated in Bangalore and you therefore consent to and acknowledge the locale of such courts.
The Company has the privilege to change, adjust, suspend, or end and additionally dispose of any aspect(s), elements, or usefulness of the Application or the Services as it considers fit whenever without notice. Further, the Company has the option to change these Terms now and again without earlier notification to you. The Company makes no responsibility, express or suggested, to keep up with or proceed with any part of the Application. You concur that the Company will not be obligated to you or any outsider for any alteration, suspension or discontinuance of the Application/Services. All costs are likely to change without notice.
<div className='font-bold my-3'>DISCLAIMER :-</div>
THIS WEBSITE, THE APPLICATION, AND THE SERVICES ARE PROVIDED ON AN "With no guarantees" BASIS WITH ALL FAULTS AND WITHOUT ANY WARRANTY OF ANY KIND. THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE WEBSITE, APPLICATION/PRODUCTS, AND THE SERVICES, INCLUDING WITHOUT LIMITATION, ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY, TIMELINESS, PERFORMANCE, COMPLETENESS, SUITABILITY AND NON-INFRINGEMENT. Also, THE COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SITE, OR THE APPLICATION OR THE SERVICES. YOUR USE OF ANY INFORMATION OR MATERIALS ON THIS WEBSITE/APPLICATION/SERVICES/PRODUCTS IS ENTIRELY AT YOUR OWN RISK, FOR WHICH WE SHALL NOT BE LIABLE. IT SHALL BE YOUR OWN RESPONSIBILITY TO ENSURE THAT SERVICES PROVIDED BY US MEET YOUR SPECIFIC REQUIREMENTS.
<div className='font-bold my-3'>GENERAL PROVISION :-</div>

Notice: All notification served by the Company will be given through email to your record or as an overall warning on the Application. Any notification to be given to the Company ought to be shipped off info@poshavesh.com
Assignment: You can't dole out or in any case move your commitments under the Terms, or any right conceded hereunder to any outsider. The Company's privileges under the Terms are unreservedly adaptable by the Company to any outsiders without the prerequisite of looking for your assent.
Severability: On the off chance that under any condition, a court of equipped purview tracks down any arrangement of the Terms, or piece thereof, to be unenforceable, that arrangement will be authorized to the greatest degree reasonable to give impact to the purpose of the gatherings as reflected by that arrangement, and the rest of the Terms will proceed in full power and impact.
Waiver: Any disappointment by the Company to implement or exercise any arrangement of the Terms, or any connected right, will not establish a waiver by the Company of that arrangement or right.
Relationship: You recognize that your investment on the Application, doesn't make you a representative or organization or association or joint endeavor or establishment of the Company.
The Company gives these Terms so you know about the terms that apply to your utilization of the Website/Application and Services. You recognize that the Company has offered you a sensible chance to audit these Terms and that you have consented to them.
<div className='font-bold my-3'>FEEDBACK :-</div>
Any criticism you give regard to the Application will be considered to be non-secret. The Application will be allowed to utilize such data on an unhindered premise. Further, by presenting the criticism, you address and warrant that
Your input doesn't contain classified or restrictive data of You or of outsiders;
 the Company isn't under any commitment of classification, express or inferred, regarding the input;
 the Application might have something almost identical to the input currently viable or being developed; and
 You are not qualified for any remuneration or repayment of any sort from the Company for the input under any conditions, except if determined.
By no means will the Company be considered dependable in any way for any substance given by different clients even if such substance is hostile, destructive or hostile. Kindly exercise alert while getting to the Application.
<div className='font-bold my-3'>CUSTOMER CARE :-</div>
We do our best to give you a charming encounter. In the impossible occasion that you face any issues, kindly reach us at info@poshavesh.com.
      </p>
      </div>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default TermsandConditions