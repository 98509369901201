import React from 'react'
import FAQSectionSales from './FAQSectionSales'
const SalesFAQ = () => {
  return (
    <div className=' mt-16 lg:mt-60 mb-20'>
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">FAQ Categories
    </p>
    <p className="sales text-4xl lg:text-6xl flex justify-start font-bold  my-10 ml-12  md:ml-28 lg:ml-40 xl:ml-60 mt-32" data-aos="zoom-in">SALES</p>
    <FAQSectionSales/>

    </div>
  )
}

export default SalesFAQ