import React from 'react'
import Section1 from "./Section1"
import Section2 from './Section2'
import Section3 from './Section3'
import OverviewofPartnerships from './OverviewofPartnerships'
import PartnerDirectory from './PartnerDirectory'
import FeaturedPartnerships from './FeaturedPartnerships'
import BenefitsofPartneringwithUs from './BenefitsofPartneringwithUs'
import PartnershipOpportunities from './PartnershipOpportunities'
import PartnerSuccessStories from './PartnerSuccessStories'
import CollaborativeProjects from './CollaborativeProjects'
import HowtoBecomeaPartner from './HowtoBecomeaPartner'
import PartnerResources from './PartnerResources'
import ContactInformation from './ContactInformation'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Footer2 from '../Footer2'
import ShopNowButton from '../ShopNowButton'
const CompanyPartnerships = () => {
  return (
    <div className='overflow-x-hidden'>
      <Navbar />
      <OverviewofPartnerships/>
      <ShopNowButton/>
      <PartnerDirectory/>
      <ShopNowButton/>
      <FeaturedPartnerships/>
      <ShopNowButton/>
      <BenefitsofPartneringwithUs/>
      <ShopNowButton/>
      <PartnershipOpportunities/>
      <ShopNowButton/>
      <PartnerSuccessStories/>
      <ShopNowButton/>
      <CollaborativeProjects/>
      <ShopNowButton/>
      <HowtoBecomeaPartner/>
      <ShopNowButton/>
      <PartnerResources/>
      <ShopNowButton/>
      <ContactInformation/>
      <ShopNowButton/>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
      {/* <Section1/>
      <Section2/>
      <Section3/> */}
    </div>
  )
}

export default CompanyPartnerships