import React from 'react'
import SeasonalCampaignOverview from './SeasonalCampaignOverview'
import CurrentSeasonalCampaign from './CurrentSeasonalCampaign'
import PastCampaignHighlights from './PastCampaignHighlights'
import UpcomingCampaignTeasers from './UpcomingCampaignTeasers'
import SeasonalCollections from './SeasonalCollections'
import PromotionalOffers from './PromotionalOffers'
import HolidayGiftGuides from './HolidayGiftGuides'
import SeasonalContentHub from './SeasonalContentHub'
import UserGeneratedContent from './UserGeneratedContent'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Footer2 from '../Footer2'
import ShopNowButton from '../ShopNowButton'
const SeasonalCampaigns = () => {
  return (
    <div className='overflow-x-hidden'>
      <Navbar />
      <SeasonalCampaignOverview/>
      <ShopNowButton/>
      <CurrentSeasonalCampaign/>
      <ShopNowButton/>
      <PastCampaignHighlights/>
      <ShopNowButton/>
      <UpcomingCampaignTeasers/>
      <ShopNowButton/>
      <SeasonalCollections/>
      <ShopNowButton/>
      <PromotionalOffers/>
      <ShopNowButton/>
      <HolidayGiftGuides/>
      <ShopNowButton/>
      <SeasonalContentHub/>
      <ShopNowButton/>
      <UserGeneratedContent/>
      <ShopNowButton/>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default SeasonalCampaigns