import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import IndustryTrendsOverview1  from "../../Industry Insights and Trends Images/Industry Trends Overview.jpg" 



const IndustryTrendsOverview = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='md:my-32   mt-32'>
       <p className="text-4xl  lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Industry Trends Overview
      </p>
        <div className='lg:flex lg:justify-evenly '>
        <div data-aos="zoom-in" className="p-5 md:p-16 lg:p-5 lg:mr-10 xl:m-10 h-3/4 lg:ml-20">
            <img src={IndustryTrendsOverview1} className='rounded-3xl'></img>
        </div>
        <div className='lg:w-screen lg:mx-10 md:m-10'>
          <div className="m-3">
          <p className='flex justify-center border border-white rounded-xl text-xl lg:mb-[55px] xl:mb-[150px]  mx-2 my-5 p-5 lg:text-[18px] xl:text-xl ' data-aos="fade-right"><span className="font-bold text-xl mx-3 ">Fashion Pulse Dive :</span> Curated industry trends</p>
          </div>
           
          <div className="m-3">
          <p className='flex justify-center lg:mb-[55px] xl:mb-[150px] border border-white mx-2 rounded-xl my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-left"><span className="font-bold text-xl mx-3">Tomorrow's Trends :</span> Latest forecasts revealed </p>
          </div>
       
            <div className="m-3">
            <p className='flex justify-center lg:mb-[55px]  xl:mb-[150px] border border-white mx-2 rounded-2xl my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-right"><span className="font-bold text-xl mx-3">Style Journey Empowerment :</span> Elevate fashion knowledge</p>
            </div>
           
            <div className="m-3">
            <p className='flex justify-center  lg:mb-[55px] xl:mb-[150px] border border-white mx-2 rounded-2xl  my-5 p-5 text-xl lg:text-[18px] xl:text-xl' data-aos="fade-left"><span className="font-bold text-xl mx-3" >Style Insight Hub :</span> Valuable insights for your journey</p>
            </div>
            

        </div>
        </div>
    </div>
  )
}

export default IndustryTrendsOverview
// Industry Trends Overview
// Fashion Pulse Dive: Curated industry trends.
// Tomorrow's Trends: Latest forecasts revealed.
// Style Journey Empowerment: Elevate fashion knowledge.
// Fashion Intelligence Source: Valuable insights for your journey.



