import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NewArrivalsLookbook1 from "../../New Arrivals Images/Chic Ensembles.png"
import NewArrivalsLookbook2 from "../../New Arrivals Images/Mix & Match Mastery.jpeg"
import NewArrivalsLookbook3 from "../../New Arrivals Images/Trendy Transformations.png"
import NewArrivalsLookbook4 from "../../New Arrivals Images/Day-to-Night Delight.png"

const NewArrivalsLookbook = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className="md:my-40">
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">New Arrivals Lookbook
      </p>
        <div>
          <div className="lg:ml-[5%] xl:ml-[10%]">
            <div className="flex justify-center lg:inline-block  lg:ml-[11%]  xl:ml-[7%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook1} className="rounded-full w-[110px] mb-3"></img>
            </div>
          <div className='border border-white m-3 lg:w-[300px] lg:h-[180px] md:m-14 lg:m-0 rounded-2xl'data-aos="fade-right">
           <p className="text-2xl font-bold flex justify-center mt-5">Chic Ensembles</p>
           <p className="text-2xl font-bold flex justify-center"></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mt-5">Elevate your style </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">with our curated looks</p>
        </div>
          </div>

          <div className=" mt-16 lg:ml-[20%] xl:ml-[30%]">
            <div className=" flex justify-center lg:inline-block  lg:ml-[14%] xl:ml-[10%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook2} className="rounded-full w-[110px] mb-3"></img>
            </div>
            <div className=' border border-white m-3  lg:w-[300px]  lg:h-[180px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Mix & Match Mastery </p>
        <p className="text-2xl font-bold flex justify-center"></p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Inspiring blends of  </p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">new and classic</p>
        </div>
          </div>
        </div>

        <div className="mt-16 lg:ml-[40%] xl:ml-[50%]">
        <div className="flex justify-center lg:inline-block lg:ml-[18%]  xl:ml-[13%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook3} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3 lg:w-[300px] lg:h-[180px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Trendy Transformations </p>
        <p className="text-2xl font-bold flex justify-center "></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5"> Stay ahead with our  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3"> latest looks </p>
        </div>
        </div>
        
        <div className="mt-16 lg:ml-[60%] xl:ml-[70%]">
        <div className="flex justify-center lg:inline-block  lg:ml-[28%] xl:ml-[22%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook4} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3  lg:w-[300px] lg:h-[180px] rounded-2xl md:m-14 lg:m-3 'data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Day-to-Night Delight </p>
        <p className="text-2xl font-bold flex justify-center"></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Effortless transitions  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">for every moment</p>
        </div>
        </div>
        
        
    </div>
  )
}

export default NewArrivalsLookbook
// New Arrivals Lookbook
// Chic Ensembles: Elevate your style with our curated looks.
// Mix & Match Mastery: Inspiring blends of new and classic.
// Trendy Transformations: Stay ahead with our latest looks.
// Day-to-Night Delight: Effortless transitions for every moment.

