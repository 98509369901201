import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CommunityInitiatives1 from "../../Sustainability Efforts Images/Community Initiatives.jpg" 


const CommunityInitiatives = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className='lg:my-40'>
        <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Community Initiatives
      </p>
        <div className='xl:flex  xl:justify-between'>
            <div className='xl:w-1/2 p-5 md:p-16 xl:p-5' data-aos="zoom-in">
                <img src={CommunityInitiatives1} className="rounded-3xl lg:ml-5"></img>
            </div>
            <div className='xl:w-1/2 md:ml-5 lg:ml-20 xl:ml-0'>
                <div className='border border-white md:h-[118px] m-2 rounded-r-full md:w-[330px] ' data-aos="fade-right">
                   <p className=" text-[18px] m-1 mt-4"><div className="font-bold my-2 text-2xl">Community Engagement Events </div>Uniting for sustainability</p>
                </div>
                <div className='border border-white rounded-r-full  md:h-[118px] md:w-[400px] lg:w-[500px] xl:w-[400px]  m-2'data-aos="fade-right">
                <p className=" text-[18px] m-1 mt-6"><div className="font-bold my-2 text-2xl">Sustainable Living Education</div>Spreading awareness for change</p>               
                 </div>
                <div className='border border-white rounded-r-full md:h-[118px] md:w-[600px] lg:w-[600px] xl:w-[600px] m-2'data-aos="fade-right">
                <p className=" text-[18px] m-1 mt-6"><div className="font-bold my-2 text-2xl">Local Nonprofit Collaborations</div>Partnering for impact</p>    
                </div>
                <div className='border border-white rounded-r-full  md:h-[118px] md:w-[700px] lg:w-[700px] xl:w-[700px]  m-2'data-aos="fade-right">
                <p className=" text-[18px] mx-1 m-1 mt-6"><div className="font-bold my-2 text-2xl">Environmental Conservation</div>Protecting our planet together</p>    
                </div>
            </div>
        </div>
    </div>
  )
}

export default CommunityInitiatives
// Community Initiatives
// Environmental Conservation: Protecting our planet together.
// Sustainable Living Education: Spreading awareness for change.
// Local Nonprofit Collaborations: Partnering for impact.
// Community Engagement Events: Uniting for sustainability.


