import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import IntroductiontotheFashionInnovationLab1 from "../../Fashion Innovation Lab Images/Innovating Fashion.png"
import IntroductiontotheFashionInnovationLab2 from "../../Fashion Innovation Lab Images/Leading the CARGER.png"
import IntroductiontotheFashionInnovationLab3 from "../../Fashion Innovation Lab Images/Crafting Tomorrow_s.png"
import IntroductiontotheFashionInnovationLab4 from "../../Fashion Innovation Lab Images/Empowering INNOVATION.png"


const IntroductiontotheFashionInnovationLab = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className="mt-32  md:my-40">
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Introduction to the Fashion Innovation Lab
      </p>
        <div>
          <div className="lg:ml-[5%] xl:ml-[10%]">
            <div className="flex justify-center lg:inline-block  lg:ml-[11%]  xl:ml-[7%]" data-aos="zoom-in">
            <img src={IntroductiontotheFashionInnovationLab1} className="rounded-full w-[110px] mb-3"></img>
            </div>
          <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] md:m-14 lg:m-0 rounded-2xl'data-aos="fade-right">
           <p className="text-2xl font-bold flex justify-center mt-5">Innovating Fashion </p>
           <p className="text-2xl font-bold flex justify-center">Futures</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mt-5">Pioneering change</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 "></p>
        </div>
          </div>

          <div className=" mt-16 lg:ml-[20%] xl:ml-[30%]">
            <div className=" flex justify-center lg:inline-block  lg:ml-[14%] xl:ml-[10%]" data-aos="zoom-in">
            <img src={IntroductiontotheFashionInnovationLab2} className="rounded-full w-[110px] mb-3"></img>
            </div>
            <div className=' border border-white m-3  lg:w-[300px]  lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Leading the  </p>
        <p className="text-2xl font-bold flex justify-center">Charge</p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Boundary-breaking</p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">innovation</p>
        </div>
          </div>
        </div>

        <div className="mt-16 lg:ml-[40%] xl:ml-[50%]">
        <div className="flex justify-center lg:inline-block lg:ml-[18%]  xl:ml-[13%]" data-aos="zoom-in">
            <img src={IntroductiontotheFashionInnovationLab3}className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Crafting Tomorrow's  </p>
        <p className="text-2xl font-bold flex justify-center ">Trends</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5"> Future-focused  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">experimentation </p>
        </div>
        </div>
        
        <div className="mt-16 lg:ml-[60%] xl:ml-[70%]">
        <div className="flex justify-center lg:inline-block  lg:ml-[28%] xl:ml-[22%]" data-aos="zoom-in">
            <img src={IntroductiontotheFashionInnovationLab4} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3  lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3 'data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Empowering  </p>
        <p className="text-2xl font-bold flex justify-center">Innovation</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Fueling  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">creativity and change </p>
        </div>
        </div>
        
        
    </div>
  )
}

export default IntroductiontotheFashionInnovationLab
// Introduction to the Fashion Innovation Lab
// Innovating Fashion Futures: Pioneering change.
// Leading the Charge: Boundary-breaking innovation.
// Crafting Tomorrow's Trends: Future-focused experimentation.
// Empowering Innovation: Fueling creativity and change.

