import React from 'react'
import { Link } from 'react-router-dom'

const ResoursesToBeExplored = () => {
  return (
    <div className='text-white  lg:my-60 pb-10 '>
    <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">Resourses To Be Explored
    </p>
    <div className='flex justify-center' data-aos="fade-up">
    <Link to="https://poshavesh.com/">
    <button className='bg-white text-black px-12 py-8 m-5 rounded-3xl  text-2xl hover:bg-black hover:text-white hover:border hover:border-white duration-500  '>
      Check it 
    </button>
    </Link>
    
    </div>
  </div>
  )
}

export default ResoursesToBeExplored