import React from 'react'
import BestSellers from './BestSellers'
import EditorsPicks from './EditorsPicks'
import FeaturedCollections from './FeaturedCollections'
import TrendingNow from './TrendingNow'
import SeasonalPicks from './SeasonalPicks'
import CustomerFavorites from './CustomerFavorites'
import NewArrivalsLookbook from './NewArrivalsLookbook'
import SaleAndClearance from './SaleAndClearance'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Footer2 from '../Footer2'
import ShopNowButton from '../ShopNowButton'
const NewArrivals = () => {
  return (
    <div className='overflow-x-hidden overflow-y-hidden'>
      <Navbar />
      <BestSellers/>
      <ShopNowButton/>
      <EditorsPicks/>
      <ShopNowButton/>
      <FeaturedCollections/>
      <ShopNowButton/>
      <TrendingNow/>
      <ShopNowButton/>
      <SeasonalPicks/>
      <ShopNowButton/>
      <CustomerFavorites/>
      <ShopNowButton/>
      <NewArrivalsLookbook/>
      <ShopNowButton/>
      <SaleAndClearance/>
      <ShopNowButton/>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default NewArrivals