import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";

const Header = () => {
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    section.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white mt-32 lg:my-60 ">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="fade-up">
        About Poshavesh
      </p>
      <div className="hidden md:block  xl:mx-[200px] mt-10 text-xl lg:text-2xl" data-aos="fade-up">
      <p className=" flex justify-center p-1">
Where elegance meets style in every stitch
</p>
<p className="flex justify-center xl:mx-[40px] p-1">
From everyday essentials to statement pieces, find fashion that speaks
</p>
<p className="flex justify-center xl:mx-[40px] p-1">
to your unique sophistication
</p>
<p className=" flex justify-center p-1">
Step into a world of refined elegance with Poshavesh
</p>
      </div>
      <div className=" md:hidden  xl:mx-[200px] mt-10 text-xl lg:text-2xl" data-aos="fade-up">
       <p className="mx-5">Where elegance meets style in every stitch From everyday essentials to statement pieces, find fashion that speaks to your unique sophistication Step into a world of refined elegance with Poshavesh</p>
      </div>
      <div
        className="flex justify-center mt-5 mb-5"
        data-aos="fade-zoom-in"
        data-aos-easing="ease-in-back"
        data-aos-delay="300"
        data-aos-offset="0"
      >
        <button className="bg-white text-black p-4 m-3 rounded-md font-bold hover:bg-black hover:text-white hover:border hover:boder-white duration-500" onClick={() => scrollToSection("story")}>
          OUR STORY
        </button>
      </div>
    </div>
  );
};

export default Header;
 