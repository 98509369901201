import React from 'react'
import EnvironmentalImpact1 from "../../Sustainability Efforts Images/Environmental Impact01.jpg" 
import EnvironmentalImpact2 from "../../Sustainability Efforts Images/Environmental Impact02.jpg" 


const EnvironmentalImpact = () => {
  return (
    <div>
      <div className='my-20 md:my-32 lg:my-20'>
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in">Environmental Impact
      </p>
        <div className='' data-aos="zoom-in-up">
        <div className=''>
          <div className='lg:flex lg:justify-between'>
          <div className='p-5 md:p-16 lg:p-0'>
          <img src={EnvironmentalImpact1} className='lg:w-1/2 lg:m-5 lg:ml-10 rounded-3xl'></img>
          </div>
          </div>
        
       <div className='border border-white rounded-3xl m-5 md:m-14 lg:m-3 lg:ml-10 lg:mr-[45%] xl:mr-[60%]'>
       <p className=' ml-5 md:ml-10 my-5 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold text-2xl">Carbon Footprint Reduction : </span>Minimizing environmental impact</p>
        <p className=' mb-3 md:m-5 ml-5  md:ml-10 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold text-2xl">Waste Management :</span>   Efficient recycling programs</p>
       </div>
       </div>
       
        </div>
        <div className='my-10' data-aos="zoom-in-up" >
        <div className='border border-white rounded-3xl m-5 md:m-14 lg:m-3   lg:ml-[45%] my-5
        lg:mb-10  lg:mr-16  xl:mr-40'>
        <p className=' ml-5 md:ml-[5%] text-xl lg:text-[18px] xl:text-xl mt-5'><span className="font-bold text-2xl">Water Conservation : </span>Prioritizing water stewardship</p>
        <p className=' ml-5 md:ml-[64%] text-xl my-1'></p>
        <p className=' ml-5 md:ml-[5%] text-xl lg:text-[18px] xl:text-xl mt-3 mb-5 '><span className="font-bold text-2xl"> Green Initiatives : </span>Continuous eco-friendly innovations</p>
        </div>
        <div className='p-5 md:p-16 lg:p-0'>
        <img src={EnvironmentalImpact2} className='lg:w-1/2 lg:ml-[45%] rounded-3xl md:mr-10'></img>
        </div>
        </div>        
      </div>
    </div>
  )
}

export default EnvironmentalImpact
// Environmental Impact
// Carbon Footprint Reduction: Minimizing environmental impact.
// Waste Management: Efficient recycling programs.
// Water Conservation: Prioritizing water stewardship.
// Green Initiatives: Continuous eco-friendly innovations.


