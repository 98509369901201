import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import StyleGuideimage1 from "../../Fashion Insights Images/Style guide01.jpg" 
import StyleGuideimage2 from "../../Fashion Insights Images/Style guide 02.jpg" 

const StyleGuides = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div>
      <div className='my-20'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in"> Style Guides
</p>
        <div data-aos="zoom-in-up" className="lg:flex lg:justify-between">
          <div className="p-5 md:p-16 lg:p-0  lg:w-1/2 lg:m-10 ">
          <img src={StyleGuideimage1} className='rounded-3xl '></img>
          </div>
          
        <div className="">
        <div className="border border-white rounded-3xl m-5 xl:m-10 lg:mt-10 xl:mt-40 md:m-14 lg:m-3 ">
       <p className='ml-5 my-4 text-xl lg:text-[18px] xl:text-xl' ><span className="font-bold text-2xl">Expert Styling Tips : </span>   Elevate your fashion game</p>
        <p className='ml-5   my-4  text-xl lg:text-[18px]  xl:text-xl' ><span className="font-bold text-2xl"> Curated Outfit Inspiration : </span>  Ensembles to inspire</p>
       </div>
        </div>
        </div>
        <div className='lg:my-10 lg:flex lg:justify-between' data-aos="zoom-in-up" >
       <div className="inline-block item-center m-5 md:m-14 lg:m-3 lg:mt-10  ">
       <div className="border border-white rounded-3xl my-1 xl:my-28 ml-3">
        <p className='m-5 text-xl lg:text-[18px]  xl:text-xl' ><span className="font-bold text-2xl"> Master the Latest Trends : </span>Easy-to-follow wardrobe guides</p>
        <p className='m-5 text-xl lg:text-[18px]  xl:text-xl' ><span className="font-bold text-2xl"> Accessorize like a Pro : </span>Tips for polished looks</p>
        </div>
       </div>
       <div className="p-5 md:p-16 lg:p-0 lg:w-1/2 lg:m-10">
       <img src={StyleGuideimage2} className=' rounded-3xl'></img>
       </div>
        
        </div>
      </div>
    </div>
  )
}

export default StyleGuides
// Expert Styling Tips: Elevate your fashion game.
// Curated Outfit Inspiration: Ensembles to inspire.
// Master the Latest Trends: Easy-to-follow wardrobe guides.
// Accessorize like a Pro: Tips for polished looks
