import React from 'react'
import PrototypingAndTestingFacilities1 from "../../Fashion Innovation Lab Images/Prototyping and Testing Facilities01.jpg"
import PrototypingAndTestingFacilities2 from "../../Fashion Innovation Lab Images/Prototyping and Testing Facilities02.jpg"
const PrototypingAndTestingFacilities = () => {
  return (
    <div className='mt-40'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold  mb-32"  data-aos="zoom-in">Prototyping and Testing Facilities</p>
        <div className='lg:flex ml-5'>
            <div className='lg:w-1/2 lg:p-5 xl:p-0' data-aos="fade-right">
                <div className='lg:p-5 xl:p-8' >
                <img className='md:m-5 rounded-3xl transform skew-y-6' src={PrototypingAndTestingFacilities1}>
                </img>
                </div>
                
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl'>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Cutting-Edge Equipment : </span>State-of-the-art machinery</p>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Material Evaluation Resources : </span>Comprehensive testing capabilities</p>
                </div>
            </div>
            <div className='mt-16 lg:w-1/2 lg:p-5 xl:p-0 lg:mr-5 xl:mr-0' data-aos="fade-left">
              <div className='lg:p-5 xl:p-8'>
              <img className='md:m-5 rounded-3xl transform skew-y-6' src={PrototypingAndTestingFacilities2}>
                </img>
              </div>
                
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl '>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Virtual Prototyping Technologies : </span> Future-focused design tools</p>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>User Experience Testing Labs :</span> Interactive testing environments</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default PrototypingAndTestingFacilities
// Prototyping and Testing Facilities
// Cutting-Edge Equipment: State-of-the-art machinery.
// Material Evaluation Resources: Comprehensive testing capabilities.
// Virtual Prototyping Technologies: Future-focused design tools.
// User Experience Testing Labs: Interactive testing environments.

