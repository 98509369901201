import React from 'react'
import IndustryTrendsOverview from './IndustryTrendsOverview'
import CurrentIndustryInsights from './CurrentIndustryInsights'
import TrendSpotlights from './TrendSpotlights'
import ExpertCommentary from './ExpertCommentary'
import DataVisualizations from './DataVisualizations'
import MarketAnalysis from './MarketAnalysis'
import ConsumerBehaviorInsights from './ConsumerBehaviorInsights'
import TechnologyAndInnovation from './TechnologyAndInnovation'
import RegulatoryAndPolicyUpdates from './RegulatoryAndPolicyUpdates'
import FutureOutlook from './FutureOutlook'
import InteractiveContent from './InteractiveContent'
import NewsletterSignUp from './NewsletterSignUp'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Footer2 from '../Footer2'
import ShopNowButton from '../ShopNowButton'
const IndustryInsightsandTrends = () => {
  return (
    <div className='overflow-x-hidden'>
      <Navbar />
      <IndustryTrendsOverview/>
      <ShopNowButton/>
      <CurrentIndustryInsights/>
      <ShopNowButton/>
      <TrendSpotlights/>
      <ShopNowButton/>
      <ExpertCommentary/>
      <ShopNowButton/>
      <DataVisualizations/>
      <ShopNowButton/>
      <MarketAnalysis/>
      <ShopNowButton/>
      <ConsumerBehaviorInsights/>
      <ShopNowButton/>
      <TechnologyAndInnovation/>
      <ShopNowButton/>
      <RegulatoryAndPolicyUpdates/>
      <ShopNowButton/>
      <FutureOutlook/>
      <ShopNowButton/>
      <InteractiveContent/>
      <ShopNowButton/>
      <NewsletterSignUp/>
      <ShopNowButton/>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default IndustryInsightsandTrends