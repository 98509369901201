import React from 'react'
import UpdatesandMaintenance1 from "../../FAQ Images/Updates and Maintenance.jpg"

const UpdatesandMaintenance = () => {
  return (
    <div className='text-white mb-10 md:mb-20  lg:mt-60 pb-10 '>
    <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">Updates & Maintenance
    </p>
    <div data-aos="fade-up">
      <div className='flex justify-evenly' >
      <div className='m-5 md:m-16  lg:mx-60  xl:mx-96'>
      <img className='rounded-3xl' src={UpdatesandMaintenance1}></img>
      </div>
      </div>
      <div className='flex justify-center lg:mt-5 text-2xl'>
      Discover Our Unique Fashion Styles
      </div>
      
    </div>
  
  </div>
  )
}

export default UpdatesandMaintenance