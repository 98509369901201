import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BrandPhilosophy1 from "../../About Us Images/Brand Philosophy.jpg"


const BrandPhilosophy = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white  lg:my-60 ">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">
        Brand Philosophy
      </p>
      <div className="lg:flex p-5 m-3  lg:mt-28">
        <div className="lg:w-1/2 mx-2" data-aos="fade-right">
          <img
            className="rounded-3xl"
            src={BrandPhilosophy1}
            alt="teamimage"
          ></img>
        </div>
        <div className="lg:w-1/2 lg:ml-5 mt-10" data-aos="fade-left">
          <p className="text-xl lg:text-2xl">
            At Poshavesh, our brand philosophy is rooted in celebrating
            individuality, embracing global style, and empowering our consumers
            to stand out with confidence. Through authenticity and transparency,
            we strive to redefine fashion lifestyles and create a brighter, more
            inclusive future for all.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BrandPhilosophy;
