import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NewArrivalsLookbook1 from "../../Company Partnerships Images/Joint Ventures.png"
import NewArrivalsLookbook2 from "../../Company Partnerships Images/Strategic Alliances.png"
import NewArrivalsLookbook3 from "../../Company Partnerships Images/Co-Branding Initiatives.png"
import NewArrivalsLookbook4 from "../../Company Partnerships Images/Sponsorship & Affiliate.png"


const PartnershipOpportunities = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className="md:my-40">
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Partnership Opportunities
      </p>
        <div>
          <div className="lg:ml-[5%] xl:ml-[10%]">
            <div className="flex justify-center lg:inline-block  lg:ml-[11%]  xl:ml-[7%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook1} className="rounded-full w-[110px] mb-3"></img>
            </div>
          <div className='border border-white m-3 lg:w-[300px] lg:h-[180px] md:m-14 lg:m-0 rounded-2xl'data-aos="fade-right">
           <p className="text-2xl font-bold flex justify-center mt-5">Joint Ventures</p>
           <p className="text-2xl font-bold flex justify-center"></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mt-5"> Pursue innovative </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">projects together</p>
        </div>
          </div>

          <div className=" mt-16 lg:ml-[20%] xl:ml-[30%]">
            <div className=" flex justify-center lg:inline-block  lg:ml-[14%] xl:ml-[10%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook2} className="rounded-full w-[110px] mb-3"></img>
            </div>
            <div className=' border border-white m-3  lg:w-[300px]  lg:h-[180px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Strategic Alliances </p>
        <p className="text-2xl font-bold flex justify-center"></p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Leverage complementary </p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">strengths</p>
        </div>
          </div>
        </div>

        <div className="mt-16 lg:ml-[40%] xl:ml-[50%]">
        <div className="flex justify-center lg:inline-block lg:ml-[18%]  xl:ml-[13%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook3} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3 lg:w-[300px] lg:h-[180px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Co-Branding Initiatives </p>
        <p className="text-2xl font-bold flex justify-center "></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5"> Create unique  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3"> collaborations </p>
        </div>
        </div>
        
        <div className="mt-16 lg:ml-[60%] xl:ml-[70%]">
        <div className="flex justify-center lg:inline-block  lg:ml-[28%] xl:ml-[22%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook4} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3  lg:w-[300px] lg:h-[180px] rounded-2xl md:m-14 lg:m-3 'data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Sponsorship & Affiliate  </p>
        <p className="text-2xl font-bold flex justify-center">Programs</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Get involved in sponsorship</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 "> and affiliate opportunities </p>
        </div>
        </div>
        
        
    </div>
  )
}

export default PartnershipOpportunities
// Partnership Opportunities
// Joint Ventures: Pursue innovative projects together.
// Strategic Alliances: Leverage complementary strengths.
// Co-Branding Initiatives: Create unique collaborations.
// Sponsorship & Affiliate Programs: Get involved in sponsorship and affiliate opportunities.

