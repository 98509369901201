import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NewsletterSignUp1  from "../../Industry Insights and Trends Images/Newsletter Sign-Up.jpg" 

const NewsletterSignUp = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className=' lg:my-40' >
        <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in">Newsletter Sign-Up
      </p>
        <div className='flex justify-center  border border-white m-5 md:m-14 lg:m-3  lg:ml-[30%] lg:mr-[30%] my-5 rounded-2xl ' data-aos="fade-down">
           <p className=" mx-5 my-5 md:my-10 text-xl lg:text-[18px] xl:text-xl"><span className="font-bold text-2xl">Trendsetters Club : </span> Exclusive trend access</p>
        </div>
        <div className='lg:flex lg:justify-between'>
            <div className='lg:w-[450px] xl:w-4/12 border border-white m-5 md:m-14  xl:m-10 lg:my-2 rounded-3xl lg:p-5 xl:p-0'data-aos="fade-right" >
              <p className="flex justify-center font-bold text-2xl my-3 xl:mt-[35%]">Get Styled</p> 
                <p className='flex justify-center mb-3  lg:mt-[15%] text-xl lg:text-[18px] xl:text-xl '>Personalized fashion tips</p>
            </div>
            <div data-aos="zoom-in" className="p-5 md:p-16 lg:p-5 lg:w-[1200px]">
                <img src={NewsletterSignUp1} className='rounded-3xl'></img>
            </div>
            <div className='lg:w-[450px] xl:w-4/12 border border-white m-5 md:m-14 xl:m-10 lg:my-2  rounded-3xl lg:p-5 xl:p-0' data-aos="fade-left">
            <p className="flex justify-center font-bold text-2xl mt-3 mr-4 xl:mt-[35%]">   Be in the Know</p>
            <p className='flex justify-center ml-5  mb-3  lg:mt-[15%] text-xl lg:text-[18px] xl:text-xl '>Stay informed on trends</p>
            </div>
        </div>
        <div className='flex justify-center rounded-2xl  border border-white m-5 md:m-14 lg:m-3  lg:ml-[30%]  my-5 lg:mr-[30%]' data-aos="fade-up">
           <p className=" mx-5 my-3 md:my-10 text-xl lg:text-[18px] xl:text-xl"><span className="font-bold text-2xl">Fashion Forward Newsletter : </span> Weekly insights</p>
        </div>
    </div>
  )
}

export default NewsletterSignUp
// Subscription or Newsletter Sign-Up
// Trendsetters Club: Exclusive trend access.
// Fashion Forward Newsletter: Weekly insights.
// Be in the Know: Stay informed on trends.
// Get Styled: Personalized fashion tips.

