import React from 'react'
import FeedbackMechanism1 from "../../FAQ Images/Feedback Mechanism.jpg"
const FeedbackMechanism = () => {
  return (
    <div className='text-white  lg:my-60 pb-10 '>
    <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">Feedback Mechanism
  </p>
  <div className='lg:flex' >
  <div className='lg:w-1/2 md:p-5 m-5 md:m-16 lg:m-0' data-aos="fade-right">
        <img className='rounded-3xl' src={FeedbackMechanism1}></img>
      </div>
      <div className='lg:w-1/2 p-5 m-5 md:m-16 text-xl md:text-2xl lg:text-xl' data-aos="fade-left">
        <p>Please email here for your valuable feed back at “info@poshavesh.com”</p>
      </div>
     

    </div>
  </div>
  )
}

export default FeedbackMechanism