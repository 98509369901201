import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const TransparencyAccountability = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white  lg:my-60">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">
        Transparency & Accountability
      </p>
      <div className="lg:flex lg:mt-28 xl:ml-20">
        <div
          className="lg:h-[250px] lg:w-[300px] xl:w-3/12 border border-white mx-5 rounded-xl"
          data-aos="fade-right"
        >
          <p className="flex justify-center my-10   lg:mt-[100px] font-bold text-xl lg:text-2xl">
            Steadfast commitment
          </p>
        </div>
        <div
          className="lg:h-[150px] lg:w-[300px] xl:w-4/12 border border-white mx-5 rounded-xl mt-10"
          data-aos="fade-right"
        >
          <p className="flex justify-center my-10 lg:mt-[60px] font-bold text-xl lg:text-2xl">
          Governance policies
          </p>
        </div>
        <div
          className="lg:h-[100px] lg:w-[340px]  xl:w-3/12 border border-white mx-5 rounded-xl mt-[60px]"
          data-aos="fade-right"
        >
          <p className="flex justify-center my-10 lg:my-0  lg:mt-[40px] font-bold text-xl lg:text-2xl ">
            Build enduring relationships
          </p>
        </div>
      </div>
    </div>
  );
};

export default TransparencyAccountability;
