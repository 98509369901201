import React from 'react'
import PromotionalOffers1  from "../../Seasonal Campaign Images/Promotional Offers01.jpg" 
import PromotionalOffers2  from "../../Seasonal Campaign Images/Promotional Offers02.jpg" 

const PromotionalOffers = () => {
  return (
    <div className='mt-40'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold  mb-32"  data-aos="zoom-in">Promotional Offers</p>
        <div className='lg:flex ml-5'>
            <div className='lg:w-1/2 lg:p-5 xl:p-0' data-aos="fade-right">
                <div className='lg:p-5 xl:p-8'>
                <img className='md:m-5 rounded-3xl transform skew-y-6' src={PromotionalOffers1}>
                </img>
                </div>
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl'>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Limited-Time Discounts : </span> Exclusive deals, limited time</p>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Seasonal Sales : </span>Unbeatable seasonal deals</p>
                </div>
            </div>
            <div className='mt-16 lg:w-1/2 lg:p-5 xl:p-0 lg:mr-5 xl:mr-0' data-aos="fade-left">
                <div className='lg:p-5 xl:p-8'>
                <img className='md:m-5 rounded-3xl transform skew-y-6' src={PromotionalOffers2}>
                </img>
                </div>
                
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl '>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Special Rewards : </span> Earn rewards, enhance shopping</p>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Early Access :</span> Sign up for early access</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default PromotionalOffers
// Promotional Offers
// Limited-Time Discounts: Exclusive deals, limited time.
// Seasonal Sales: Unbeatable seasonal deals.
// Special Rewards: Earn rewards, enhance shopping.
// Early Access: Sign up for early access.
