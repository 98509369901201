import React from 'react'
import IntroductiontoFashionHistory from './IntroductiontoFashionHistory'
import TimelineofFashionEras from './TimelineofFashionEras'
import EvolutionofFashionTrends from './EvolutionofFashionTrends'
import FashioninArtandCulture from './FashioninArtandCulture'
import RevivalsandRetroFashion from './RevivalsandRetroFashion'
import ImpactofGlobalization from './ImpactofGlobalization'
import SustainabilityinFashionHistory from './SustainabilityinFashionHistory'
import FashionMuseumsandArchives from './FashionMuseumsandArchives'
import EducationalResources from './EducationalResources'
import InteractiveFeatures from './InteractiveFeatures'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Footer2 from '../Footer2'
import ShopNowButton from '../ShopNowButton'
const FashionHistory = () => {
  return (
    <div className='overflow-x-hidden'>
      <Navbar />
      <IntroductiontoFashionHistory/>
      <ShopNowButton/>
      <TimelineofFashionEras/>
      <ShopNowButton/>
      <EvolutionofFashionTrends/>
      <ShopNowButton/>
      <FashioninArtandCulture/>
      <ShopNowButton/>
      <RevivalsandRetroFashion/>
      <ShopNowButton/>
      <ImpactofGlobalization/>
      <ShopNowButton/>
      <SustainabilityinFashionHistory/>
      <ShopNowButton/>
      <FashionMuseumsandArchives/>
      <ShopNowButton/>
      <EducationalResources/>
      <ShopNowButton/>
      <InteractiveFeatures/>
      <ShopNowButton/>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default FashionHistory