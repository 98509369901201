import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CustomerCentric1 from "../../About Us Images/Customer-Centric Approach.jpg"
import CustomerCentric2 from "../../About Us Images/Customer-Centric Approach01.jpg"


const CustomerCentric = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white lg:my-60 ">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">
        Customer-Centric Approach
      </p>
      <div className="flex lg:mt-28" data-aos="fade-right">
        <div className="rounded-3xl lg:rounded-e-full border border-white  my-10 lg:w-8/12 lg:h-[350px] mx-3 lg:ml-0">
          <div className="lg:flex p-5 lg:p-0 mt-8 lg:mt-16 lg:mx-2 ">
            <div className="flex md:justify-center lg:items-center lg:mr-6 lg:ml-0">
              <img
                className="h-[250px] m-0  rounded-3xl  "
                src={CustomerCentric1}
                alt="customerimg"
              ></img>
            </div>
            <p className="lg:w-1/2  text-xl lg:text-[15px] xl:text-2xl mt-5 ">
              At Poshavesh, our commitment to customer satisfaction is
              paramount. We prioritize understanding and exceeding the needs and
              expectations of our audience, ensuring every interaction leaves a
              lasting impression of delight and fulfillment.
            </p>
          </div>
        </div>
      </div>

      <div className="lg:flex" data-aos="fade-left">
        <div className="rounded-3xl lg:rounded-l-full border border-white  my-20 lg:ml-[35%] xl:ml-[45%] lg:w-8/12 lg:h-[350px] mx-3 lg:mx-0">
          <div className="lg:flex p-5 lg:mt-8 ">
            <p className="lg:w-1/2  text-xl lg:text-[15px] xl:text-2xl lg:ml-10 lg:mt-8">
            Poshavesh prioritizes customer needs with personalized assistance, feedback loops, and support channels, ensuring everyone feels valued and heard.
            </p>
            <div className="flex items-center md:justify-center lg:items-center">
              <img
                className="mt-5  h-[250px]  lg:mx-5  rounded-3xl "
                src={CustomerCentric2}
                alt="customerimg"
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerCentric;
