import React from 'react'
import Perks1 from "../../Career Page Images/Generous Paid .png"
import Perks2 from "../../Career Page Images/Flexible Work Hours.jpg"
import Perks3 from "../../Career Page Images/Health Insurance.png"
import Perks4 from "../../Career Page Images/Professional Development .png"
import Perks5 from "../../Career Page Images/Wellness Programs .png"
import Perks6 from "../../Career Page Images/Work Engagement.png"


const Perks = () => {
  return (
    <div className='text-white  mt-[70px] pb-10'>
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">Benefits & Perks
      </p>
      <div className='md:flex md:justify-evenly mt-20' data-aos="fade-up">
        <div className='PerksIcons ml-20   mt-10 md:ml-10 xl:ml-0 '>
          <img src={Perks1} className='ml-5 rounded-full w-[120px]'></img>
          <p className=' mt-14 xl:mt-12 text-2xl ml-1 md:ml-0'>Generous Paid</p>
        </div>
        <div className='PerksIcons ml-20   mt-10 md:ml-10 xl:ml-0'>
          <img src={Perks2} className='ml-5 md:ml-10 rounded-full w-[120px]'></img>
          <p className=' mt-14 xl:mt-12 text-2xl ml-[-18px] md:ml-0'>Flexible Work Hours</p>

        </div>
        <div className='PerksIcons ml-20  mt-10 md:ml-10 xl:ml-0'>
          <img src={Perks3} className='ml-5 rounded-full w-[120px]'></img>
          <p className=' mt-14 xl:mt-12 text-2xl ml-[-12px] md:ml-0'>Health Insurance</p>

        </div>
      </div>
      <div className=' md:flex md:justify-evenly mt-20'>
      <div className='PerksIcons ml-20 md:ml-0 mt-20 xl:ml-0'>
          <img src={Perks4} className='ml-5 rounded-full w-[120px]'></img>
          <p className=' mt-14 xl:mt-12 text-2xl ml-[13px] md:ml-0'>Professional</p>
          <p className='text-2xl ml-[13px] md:ml-0'> Development</p>

        </div>
        <div className='PerksIcons ml-20 md:ml-10  mt-20 xl:ml-0'>
          <img src={Perks5} className='ml-5 md:ml-0 lg:ml-0 rounded-full w-[120px]'></img>
          <p className=' mt-14 xl:mt-12 text-2xl ml-[35px] md:ml-0'>Wellness</p>
          <p className=' text-2xl ml-[35px] md:ml-0'> Programs</p>

        </div>
        <div className='PerksIcons ml-20 md:ml-10 mt-20'>
          <img src={Perks6} className='ml-5 rounded-full w-[120px]'></img>
          <p className=' mt-14 xl:mt-12 text-2xl ml-[35px] md:ml-0'>Work</p>
          <p className=' text-2xl ml-[35px] md:ml-0'>Engagement</p>

        </div>
      </div>




    </div>
  )
}

export default Perks