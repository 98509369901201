import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import IntroductiontotheFashionInnovationLab1 from "../../Fashion For All ages/Interactive Social Media Campaigns.png"
import IntroductiontotheFashionInnovationLab2 from "../../Fashion For All ages/Amplifying Voices.png"
import IntroductiontotheFashionInnovationLab3 from "../../Fashion For All ages/Fostering Connections.png"
import IntroductiontotheFashionInnovationLab4 from "../../Fashion For All ages/Building a Digital Community.png"

const CommunityEngagement = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className="md:my-40">
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Community Engagement
      </p>
        <div>
          <div className="lg:ml-[5%] xl:ml-[10%]">
            <div className="flex justify-center lg:inline-block  lg:ml-[11%]  xl:ml-[7%]" data-aos="zoom-in">
            <img src={IntroductiontotheFashionInnovationLab1} className="rounded-full w-[110px] mb-3"></img>
            </div>
          <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] md:m-14 lg:m-0 rounded-2xl'data-aos="fade-right">
           <p className="text-2xl font-bold flex justify-center mt-5">Interactive Social </p>
           <p className="text-2xl font-bold flex justify-center">Media Campaigns</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mt-5">Social media </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">fashion engagement</p>
        </div>
          </div>

          <div className=" mt-16 lg:ml-[20%] xl:ml-[30%]">
            <div className=" flex justify-center lg:inline-block  lg:ml-[14%] xl:ml-[10%]" data-aos="zoom-in">
            <img src={IntroductiontotheFashionInnovationLab2} className="rounded-full w-[110px] mb-3"></img>
            </div>
            <div className=' border border-white m-3  lg:w-[300px]  lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Amplifying Voices </p>
        <p className="text-2xl font-bold flex justify-center"></p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Diversity in  </p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">fashion voices</p>
        </div>
          </div>
        </div>

        <div className="mt-16 lg:ml-[40%] xl:ml-[50%]">
        <div className="flex justify-center lg:inline-block lg:ml-[18%]  xl:ml-[13%]" data-aos="zoom-in">
            <img src={IntroductiontotheFashionInnovationLab3} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Fostering Connections </p>
        <p className="text-2xl font-bold flex justify-center "></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">  Building fashion  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">communities </p>
        </div>
        </div>
        
        <div className="mt-16 lg:ml-[60%] xl:ml-[70%]">
        <div className="flex justify-center lg:inline-block  lg:ml-[28%] xl:ml-[22%]" data-aos="zoom-in">
            <img src={IntroductiontotheFashionInnovationLab4} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3  lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3 'data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Building a  </p>
        <p className="text-2xl font-bold flex justify-center">Digital Community</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Inclusive online  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">fashion community</p>
        </div>
        </div>
        
        
    </div>
  )
}

export default CommunityEngagement
// Community Engagement:
// Interactive Social Media Campaigns: Social media fashion engagement.
// Fostering Connections: Building fashion communities.
// Amplifying Voices: Diversity in fashion voices.
// Building a Digital Community: Inclusive online fashion community.

