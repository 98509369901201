import React from 'react'

const BusinessHours = () => {
  return (
    <div className='text-white my-20  lg:my-60 pb-10' data-aos="fade-up">
     <p className=' text-3xl md:text-5xl font-bold flex justify-center'>Business Hours</p>
      <p  className='text-xl  md:text-3xl  flex justify-center m-5 my-5'>Monday-Friday : 9:00AM to 6:00PM</p>
      <p  className='text-xl md:text-3xl  flex justify-center m-5 my-5'>Saturday-Sunday : 10:00AM to 4:00PM</p>
    </div>
  )
}

export default BusinessHours