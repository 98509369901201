import React, { useEffect } from 'react'
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from 'react-router-dom';

const ShopNowButton = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='my-32'>
        <div className='flex justify-center' data-aos="zoom-in" >
           <Link to="https://poshavesh.com/">
           <button className='bg-white text-2xl rounded-2xl  text-black font-bold px-20 py-5 hover:bg-black hover:text-white hover:border hover:boder-white duration-500'>Shop Now</button>
           </Link>
           
        </div>
    </div>
  )
}

export default ShopNowButton