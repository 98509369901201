import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import SeasonalCollections1  from "../../Seasonal Campaign Images/Seasonal Collections.jpg" 

const SeasonalCollections = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="mt-20  lg:mt-40 lg:mb-[-530px]">
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in">Seasonal Collections</p>
      <div className=' lg:flex lg:justify-evenly mt-28 mb-40'>
        <div className=' lg:w-3/12  xl:w-4/12 mx-1' >
          <div className=' border border-white rounded-xl lg:w-[200px] xl:w-[220px] lg:h-1/4  lg:ml-[50%]  m-3 md:m-14 lg:m-3' data-aos="fade-down-right">
            <p className=" flex justify-center my-1 mt-5 text-2xl font-bold ">Trendy Essentials </p>
            <p className=" flex justify-center text-2xl font-bold " ></p>
            <p className="flex justify-center mt-3 lg:mt-10 text-xl lg:text-[18px] xl:text-xl">Explore curated </p>
            <p className="flex justify-center  text-xl lg:text-[18px] xl:text-xl  mb-5"> seasonal must-haves</p>
            <p className="flex justify-center  text-xl"></p>
          </div>
          <div className='border border-white lg:w-[200px] xl:w-[220px] lg:h-1/4 m-3  mt-5 md:mt-3 rounded-xl lg:ml-[10%] md:m-14 lg:m-3' data-aos="fade-up-right"> 
           <p className=" flex justify-center my-1 mt-5 text-2xl font-bold ">Signature Styles </p>
           <p className="flex justify-center my-1  text-2xl font-bold"> </p>
           <p className="flex justify-center lg:mt-7  text-xl lg:text-[18px] xl:text-xl">Discover season's</p>
           <p className="flex justify-center  text-xl lg:text-[18px] xl:text-xl  mb-3"> signature looks</p>
          </div>
        </div>
<div className='p-5 lg:flex lg:justify-center md:p-16 lg:p-5 xl:p-0' data-aos="zoom-in" >
  <img src={SeasonalCollections1} className=" rounded-3xl h-1/2  lg:ml-0 xl:ml-5 ">
  </img>
</div>
<div className='lg:w-3/12  xl:w-4/12 mx-1'>
<div className=' border border-white rounded-xl lg:w-[200px] xl:w-[220px] lg:h-1/4 m-3 md:m-14 lg:m-3 lg:ml-[-40%] xl:ml-[-4%]' data-aos="fade-down-left">
           <p className=" flex justify-center my-1 mt-5 text-2xl font-bold ">Themed Trends </p>
           <p className=" flex justify-center my-1  text-2xl font-bold "></p>
           <p className="flex justify-center lg:mt-7 text-xl lg:text-[18px] xl:text-xl"> Immerse in themed </p>
           <p className="flex justify-center  text-xl mb-3 lg:text-[18px] xl:text-xl"> seasonal trends</p>
       </div>
          <div className=' border border-white m-3 md:m-14 lg:m-3  lg:w-[200px] xl:w-[220px] rounded-xl  lg:h-1/4 mt-3 lg:ml-[2%] xl:ml-[34%]' data-aos="fade-up-left">
          <p className=" flex justify-center my-1 mt-5 text-2xl font-bold ">Shop the Look </p>
          <p className=" flex justify-center my-1  text-2xl font-bold"></p>
          <p className=" flex justify-center my-1  text-2xl font-bold"></p>
          <p className="flex justify-center lg:mt-7 text-xl lg:text-[18px] xl:text-xl">Directly shop from </p>
          <p className="flex justify-center text-xl mb-3 lg:text-[18px] xl:text-xl"> seasonal collections</p>
          </div>
       </div>
     </div>



    </div>
  )
}

export default SeasonalCollections
// Seasonal Collections
// Trendy Essentials: Explore curated seasonal must-haves.
// Signature Styles: Discover season's signature looks.
// Themed Trends: Immerse in themed seasonal trends.
// Shop the Look: Directly shop from seasonal collections.




