import React from 'react';
import AccordianCustomerSupport from './AccordianCustomerSupport';

const items = [
  {
    title: '1. How can I contact Poshavesh customer support?',
    content: 'You can reach our customer support team via email at info@poshavesh.com or by calling our toll-free number +91 8777472644. Our support hours are Monday to Friday, 9 AM to 6 PM (IST).'
  },
  {
    title: '2. What should I do if I receive a damaged or incorrect item?',
    content: 'If you receive a damaged or incorrect item, please contact our customer support team immediately. Provide your order number and details about the issue, and we will arrange for a replacement or refund as soon as possible.'
  },
  {
    title: '3. How do I update my account information?',
    content: 'To update your account information, log in to your Poshavesh account, go to the "Account Settings" page, and make the necessary changes. If you encounter any issues, our customer support team is here to assist you.'
  },
  {
    title: '4. Can I cancel or modify my order after it has been placed?',
    content: 'Orders can be cancelled or modified within a short window 1 hour time after placement. Please contact customer support as soon as possible if you need to make changes. Once an order is processed for shipping, modifications or cancellations may not be possible.'
  },
  {
    title: '5. How can I provide feedback about my shopping experience?',
    content: 'We value your feedback! You can share your thoughts and suggestions by emailing info@poshavesh.com. Your input helps us improve our products and services to better meet your needs.'
  },
  {
    title: '6. What do I do if I forget my password?',
    content: 'If you forgot your password, click on the "Forgot Password" link on the login page. Enter your registered email address, and we will send you instructions to reset your password. If you encounter any issues, our customer support team is ready to help.'
  }
];

const FAQSectionCustomerSupport = () => {
  return (
    <div className="py-8" data-aos="fade-up">
      <AccordianCustomerSupport items={items} />
    </div>
  );
};

export default FAQSectionCustomerSupport;
