import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import EthicalSourcing1 from "../../Sustainability Efforts Images/Ethical Sourcing01.jpg" 
import EthicalSourcing2 from "../../Sustainability Efforts Images/Ethical Sourcing02.jpg" 

const EthicalSourcing = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="mt-32 lg:mb-60">
       <p className="text-[34px]  lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in">Ethical Sourcing
      </p>
      <div className='md:flex md:justify-between'>
      <div className='md:w-1/2 m-5 xl:ml-[-5%]'data-aos="fade-right" >
      <img src={EthicalSourcing1} className='h-3/4 xl:ml-[16%] mt-5   rounded-3xl'></img>
      <div className="border border-white xl:ml-[20%] my-5 rounded-3xl mx-1">
      <p className=' mt-5'></p>
      <p className='ml-5  mt-5 text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Sustainable Materials :</span>  Eco-conscious fabric choices</p>
      <p className='ml-5 my-5 text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Fair Labor Standards :</span> Ethical treatment for all workers</p>
      
      </div>
      </div>
      <div className='md:w-1/2 mt-20 xl:mr-[5%]' data-aos="fade-left">
        <div className="mx-7  xl:ml-[25%]  md:mr-[10%] border border-white rounded-3xl my-5">
        <p className='ml-5 mt-5  text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Transparent Supply Chain : </span>Traceability and accountability</p>
        <p className="text-xl mb-5 ml-[57%]"></p>
        <p className='ml-5 mt-2 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold  text-2xl">Community Support : </span>Empowering local artisans</p>
        <p  className='ml-[64%] mb-5 text-xl'></p>
        </div>
      <div className="p-6">
      <img src={EthicalSourcing2} className='h-3/4 rounded-3xl   md:mt-5 md:mt-15 xl:ml-[8%]'></img>
      </div>
      </div>
    </div>
    </div>
  )
}

export default EthicalSourcing
// Ethical Sourcing
// Sustainable Materials: Eco-conscious fabric choices.
// Fair Labor Standards: Ethical treatment for all workers.
// Transparent Supply Chain: Traceability and accountability.
// Community Support: Empowering local artisans

