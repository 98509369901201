import React from 'react'
import EnvironmentalImpact from './EnvironmentalImpact'
import EthicalSourcing from './EthicalSourcing'
import MaterialsGuide from './MaterialsGuide'
import CircularFashion from './CircularFashion'
import CommunityInitiatives from './CommunityInitiatives'
import CertificationsAndStandards from './CertificationsAndStandards'
import TransparencyAndReporting from './TransparencyAndReporting'
import ConsumerEducation from './ConsumerEducation'
import CollaborationsAndInnovations from './CollaborationsAndInnovations'
import InspirationAndAdvocacy from './InspirationAndAdvocacy'
import Navbar from '../Navbar'
import Footer from '../Footer'
import Footer2 from '../Footer2'
import ShopNowButton from '../ShopNowButton'

const SustainabilityEfforts = () => {
  return (
    <div className='overflow-x-hidden'>
      <Navbar />
      <EthicalSourcing/>
      <ShopNowButton/>
      <EnvironmentalImpact/>
      <ShopNowButton/>
      <MaterialsGuide/>
      <ShopNowButton/>
      <CircularFashion/>
      <ShopNowButton/>
      <CommunityInitiatives/>
      <ShopNowButton/>
      <CertificationsAndStandards/>
      <ShopNowButton/>
      <TransparencyAndReporting/>
      <ShopNowButton/>
      <ConsumerEducation/>
      <ShopNowButton/>
      <CollaborationsAndInnovations/>
      <ShopNowButton/>
      <InspirationAndAdvocacy/>
      <ShopNowButton/>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default SustainabilityEfforts