import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TrendSpotlights1 from "../../Industry Insights and Trends Images/Color Trends.png"
import TrendSpotlights2 from "../../Industry Insights and Trends Images/Sustainable Fashion.png"
import TrendSpotlights3 from "../../Industry Insights and Trends Images/Tech-Infused.png"
import TrendSpotlights4 from "../../Industry Insights and Trends Images/Influencer.png"


const TrendSpotlights = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className="md:my-40">
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Trend Spotlights
      </p>
        <div>
          <div className="lg:ml-[5%] xl:ml-[10%]">
            <div className="flex justify-center lg:inline-block  lg:ml-[11%]  xl:ml-[7%]" data-aos="zoom-in">
            <img src={TrendSpotlights1} className="rounded-full w-[110px] mb-3"></img>
            </div>
          <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] md:m-14 lg:m-0 rounded-2xl'data-aos="fade-right">
           <p className="text-2xl font-bold flex justify-center mt-5">Color Trends </p>
           <p className="text-2xl font-bold flex justify-center">Unveiled</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mt-5"> Dominating </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">hues revealed</p>
        </div>
          </div>

          <div className=" mt-16 lg:ml-[20%] xl:ml-[30%]">
            <div className=" flex justify-center lg:inline-block  lg:ml-[14%] xl:ml-[10%]" data-aos="zoom-in">
            <img src={TrendSpotlights2} className="rounded-full w-[110px] mb-3"></img>
            </div>
            <div className=' border border-white m-3  lg:w-[300px]  lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Sustainable Fashion  </p>
        <p className="text-2xl font-bold flex justify-center">Revolution</p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Rise of </p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">eco-fashion</p>
        </div>
          </div>
        </div>

        <div className="mt-16 lg:ml-[40%] xl:ml-[50%]">
        <div className="flex justify-center lg:inline-block lg:ml-[18%]  xl:ml-[13%]" data-aos="zoom-in">
            <img src={TrendSpotlights3} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Tech-Infused  </p>
        <p className="text-2xl font-bold flex justify-center ">Style</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5"> Fashion meets  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">technology </p>
        </div>
        </div>
        
        <div className="mt-16 lg:ml-[60%] xl:ml-[70%]">
        <div className="flex justify-center lg:inline-block  lg:ml-[28%] xl:ml-[22%]" data-aos="zoom-in">
            <img src={TrendSpotlights4} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3  lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3 'data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Influencer  </p>
        <p className="text-2xl font-bold flex justify-center">Power</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Social media's</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">fashion impact</p>
        </div>
        </div>
        
        
    </div>
  )
}

export default TrendSpotlights
// Trend Spotlights
// Color Trends Unveiled: Dominating hues revealed.
// Sustainable Fashion Revolution: Rise of eco-fashion.
// Tech-Infused Style: Fashion meets technology.
// Influencer Power: Social media's fashion impact.
