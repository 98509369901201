import React from 'react'
import RevivalsandRetroFashion1 from "../../Fashion History/Revivals and Retro Fashion01.jpg"
import RevivalsandRetroFashion2 from "../../Fashion History/Revivals and Retro Fashion02.jpg"


const RevivalsandRetroFashion = () => {
  return (
    <div className='my-32  lg:my-60'>
        <p className=" text-4xl lg:text-6xl flex justify-center font-bold mb-20"  data-aos="zoom-in">Revivals and Retro Fashion</p>
        <div className='lg:flex'>
            <div className='lg:w-1/2'>
                <div className='lg:flex'>
                    <div className='p-5 md:p-16 lg:p-0 lg:w-[400px] xl:w-1/2 xl:ml-10 lg:m-5 xl:m-5'>
                    <img className=' rounded-3xl' src={RevivalsandRetroFashion1} data-aos="zoom-in"></img>
                    </div>
                   <div className=' border border-white lg:h-[210px] xl:h-[180px] mb-auto p-2 rounded-3xl m-3 md:m-14 lg:m-3'  data-aos="fade-right">
                   <p className='flex justify-center  font-bold text-2xl lg:text-[20px] xl:text-2xl md:my-5'>Vintage Vibes</p>
                    <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl'>Allure of yester years </p>
                    <p className=' flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3'> </p>
                   </div>
                </div>
                <div className='border border-white lg:mr-40 lg:ml-5 m-3 md:m-14 lg:m-3 rounded-3xl'data-aos="fade-up">
                <p className='text-xl lg:text-[18px] xl:text-xl ml-5 mt-5'> <span className='text-2xl font-bold' >Trend Time Machine : </span> Vintage resurgence </p>
                <p className='text-xl ml-[50%] mb-5  '></p>
                </div>
            </div>
            <div className='lg:w-1/2'>
                <div className='lg:flex'>
                <div className=' border border-white lg:h-[210px] xl:h-[180px]  rounded-3xl m-3 md:m-14 lg:m-3 lg:mt-44  xl:mt-72 my-auto' data-aos="fade-down-left">
                <p className='flex justify-center  font-bold text-2xl my-5'>Designer Homage</p>
                <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5 mx-3'>Honoring fashion icons  </p>
                <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl mb-5 mx-3'> </p>
                </div>
                <div className='p-5 md:p-16 lg:p-0 lg:w-[400px] xl:w-1/2 lg:m-5 xl:m-5' >
                <img className=' rounded-3xl' src={RevivalsandRetroFashion2} data-aos="zoom-in" ></img>
                </div>
                   
                </div>
                <div className='border border-white rounded-3xl m-3 md:m-14 lg:m-3  lg:ml-40  md:mx-10'  data-aos="fade-up">
                <p className='flex justify-center mt-5 text-xl lg:text-[18px] xl:text-xl'><span className='font-bold mx-3 '>Cultural Echoes : </span> Nostalgia's fashion impact</p>
                <p className='text-xl lg:text-[18px] xl:text-xl ml-[43%] mb-5'></p>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default RevivalsandRetroFashion
// Revivals and Retro Fashion
// Trend Time Machine: Vintage resurgence.
// Vintage Vibes: Allure of yester years.
// Designer Homage: Honoring fashion icons.
// Cultural Echoes: Nostalgia's fashion impact.




