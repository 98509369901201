import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BestSeller1 from "../../New Arrivals Images/Best sellers 01.jpg" 
import BestSeller2 from "../../New Arrivals Images/Best sellers 02.jpg" 

const BestSellers = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div>
      <div className='my-20 mt-32'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in"> Best Sellers
</p>
        <div data-aos="zoom-in-up" className="lg:flex lg:justify-between">
          <div className="p-5 md:p-16 lg:p-0  lg:w-1/2 lg:m-10 ">
          <img src={BestSeller1} className='rounded-3xl '></img>
          </div>
          
        <div className="">
        <div className="border border-white rounded-3xl m-5 xl:m-10 lg:mt-10 xl:mt-40 md:m-14 lg:m-3 ">
       <p className='ml-5 my-4 text-xl lg:text-[18px] xl:text-xl' ><span className="font-bold text-2xl">Trendsetters' Picks : </span>   Dive into our curated collection</p>
        <p className='ml-5   my-4  text-xl lg:text-[18px]  xl:text-xl' ><span className="font-bold text-2xl"> Timeless Classics : </span>  Explore our perennial favorites</p>
       </div>
        </div>
        </div>
        <div className='lg:my-10 lg:flex lg:justify-between' data-aos="zoom-in-up" >
       <div className="inline-block item-center m-5 md:m-14 lg:m-3 lg:mt-10  ">
       <div className="border border-white rounded-3xl my-1 xl:my-28 ml-3">
        <p className='m-5 text-xl lg:text-[18px]  xl:text-xl' ><span className="font-bold text-2xl"> Seasonal Essentials : </span>Stay ahead of the curve with our seasonal essentials</p>
        <p className='m-5 text-xl lg:text-[18px]  xl:text-xl' ><span className="font-bold text-2xl"> Customer Favorites : </span>Shop from the versatile basics to statement-making showstoppers</p>
        </div>
       </div>
       <div className="p-5 md:p-16 lg:p-0 lg:w-1/2 lg:m-10">
       <img src={BestSeller2} className=' rounded-3xl'></img>
       </div>
        
        </div>
      </div>
    </div>
  )
}

export default BestSellers
// Best Sellers
// Trendsetters' Picks: Dive into our curated collection 
// Timeless Classics: Explore our perennial favorites 
// Seasonal Essentials: Stay ahead of the curve with our seasonal essentials.
// Customer Favorites: Shop from the versatile basics to statement-making showstoppers.
