import React from 'react'
import Heading from './Heading'
import SalesFAQ from './SalesFAQ'
import CustomerSupportFAQ from "./CustomerSupportFAQ"
import ResoursesToBeExplored from './ResoursesToBeExplored'
import ContactInformation from "./ContactInformation"
import FeedbackMechanism from './FeedbackMechanism'
import UpdatesandMaintenance from './UpdatesandMaintenance'
import Navbar from "../Navbar"
import Footer from '../Footer'
import Footer2 from '../Footer2'
const FAQ = () => {
  return (
    <div className='overflow-x-hidden'>
        <Navbar/>
        <Heading/>
        <SalesFAQ/>
        <CustomerSupportFAQ/>
        <ResoursesToBeExplored/>
        <ContactInformation/>
        <FeedbackMechanism/>
        <UpdatesandMaintenance/>
        <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default FAQ