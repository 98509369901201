import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Insta from "../.././Social Media Icons/Instagram-Icon.png"
import Pinrest from "../.././Social Media Icons/Pinterest-Icon .png"
import X from "../.././Social Media Icons/Twitterx-Icon.png"
import Facebook from "../.././Social Media Icons/Facebook-Icon.png"
import Linkedin from "../.././Social Media Icons/Linkedin-Icon.png"


const ConnectWithPV = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white  my-20 lg:my-60">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">
        Connect with Us
      </p>
      <div className="md:flex md:justify-evenly mt-28">
      
      <a href="https://www.instagram.com/poshavesh/?fbclid=IwAR3z1dCmhSbo2bONtagroJoqdd4uwvLO7v4Cwx1LwKMACJqevtEWyvyjgcQ" target="blank">
      <div className="ConnectWithPV hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mx-24 md:mx-0 lg:m-0 mb-5" data-aos="zoom-in">
          <img
            src={Insta}
            className="rounded-full w-[100px]"
          ></img>
        </div>
      </a>

        <a href="https://www.linkedin.com/company/poshavesh/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BtR%2F3RZrjS3Gld23rbgaxMw%3D%3D" target="blank">
        <div
            className="ConnectWithPV hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mx-24 md:mx-0 lg:m-0 mb-5" data-aos="zoom-in">
            <img
              src={Linkedin}
              className="rounded-full w-[100px]"></img>
          </div></a> 
        
          <a href="https://www.facebook.com/share/zDzP1Jg35cED3h8P/?mibextid=qi2Omg" target="blank">
          <div
            className="ConnectWithPV hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mx-24 md:mx-0 lg:m-0 mb-5" data-aos="zoom-in"
          >
            <img
              src={Facebook}
              className="rounded-full w-[100px]"
            ></img>
          </div>
          </a>
          
          <a href="https://in.pinterest.com/poshavesh/
" target="blank">
          <div
          className="ConnectWithPV hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mx-24 md:mx-0 lg:m-0 mb-5" data-aos="zoom-in"
        >
          <img
            src={Pinrest}
            className="rounded-full w-[100px]"
          ></img> 
          </div>
          </a>
         
         <a href="https://x.com/PoshaVesh" target="blank">
         <div
          className="ConnectWithPV hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mx-24 md:mx-0 lg:m-0 mb-5" data-aos="zoom-in">
          <img
            src={X}
            className=" rounded-full w-[100px]"></img>   
          </div> 
         </a>
           
      </div>
    </div>
  );
};

export default ConnectWithPV;
