import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/logo.png";
import AOS from "aos";
import "aos/dist/aos.css";
import Insta from "./../Social Media Icons/Instagram-Icon.png"
import X from "./../Social Media Icons/Twitterx-Icon.png"
import Facebook from "./../Social Media Icons/Facebook-Icon.png"
import Pinrest from "./../Social Media Icons/Pinterest-Icon .png"
import Linkedin from "./../Social Media Icons/Linkedin-Icon.png"

const Footer2 = () => {

  const [show, setShow] = useState(false);
  const handleOnClick = () => {
    setShow(!show);
  };
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: true, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="">
      {(
        <div
          className="md:flex  md:justify-between bg-black text-white"
          data-aos="fade-up"
        >
          <div className="border border-black md:w-6/12  mt-5">
            <img src={logo} alt="logo" className=" ml-5 h-[80px] rounded-xl"></img>
            <p className="m-2 p-2 text-[18px] text-white my-5 ">Startup India certified and ISO 9001: 2015</p>
            <div className="mt-10 text-[18px] text-white">
               <div className="md:flex md:justify-evenly mt-10 ">

          <a href="https://www.instagram.com/poshavesh/?fbclid=IwAR3z1dCmhSbo2bONtagroJoqdd4uwvLO7v4Cwx1LwKMACJqevtEWyvyjgcQ" target="blank">
          <div className="logo flex justify-center md:inline-block hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mx-24 md:mx-0 lg:m-0 mb-5" data-aos="zoom-in">
          <img
            src={Insta}
            className="rounded-full w-[80px]"></img>
          </div>
          </a>

        <a href="https://www.linkedin.com/company/poshavesh/?lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3BtR%2F3RZrjS3Gld23rbgaxMw%3D%3D" target="blank">
        <div
            className="logo flex justify-center md:inline-block hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mx-24 md:mx-0  mb-5" data-aos="zoom-in">
            <img
              src={Linkedin}
              className="rounded-full w-[80px]"></img>
          </div>
          </a>          
        
          <a href="https://www.facebook.com/share/zDzP1Jg35cED3h8P/?mibextid=qi2Omg" target="blank">
          <div
            className="logo flex justify-center md:inline-block hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mx-24 md:mx-0 mb-5" data-aos="zoom-in">
            <img
              src={Facebook}
              className="rounded-full w-[80px]"></img>
          </div>
          </a>
         
         <a href="https://in.pinterest.com/poshavesh/" target="blank">
         <div
          className="logo flex justify-center md:inline-block hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mx-24 md:mx-0 mb-5" data-aos="zoom-in">
          <img
            src={Pinrest}
            className="rounded-full w-[80px]"></img> 
          </div>
         </a>
          
          <a href="https://x.com/PoshaVesh" target="blank">
          <div
          className="logo flex justify-center md:inline-block hover:scale-110 duration-500 hover:bg-white rounded-xl p-6 mx-24 md:mx-0 mb-5" data-aos="zoom-in"
        >
          <img
            src={X}
            className="rounded-full w-[80px]"
          ></img>   
          </div> 
          </a>
           
      </div>
            <p className="m-2 p-2 ">M/s POSHAVESH INDIA PRIVATE LIMITED</p>
            <p className="m-2 p-2 ">CIN : U80904TR2021PTCO13885 </p>
            <p className="m-2 p-2 ">All Rights Reserved / Copyright  © POSHAVESH / 2024</p>
            </div>
          </div>
          <div className="border border-black md:w-3/12  mt-5">
            <p className="text-xl font-bold p-2">Link Section</p>
            <ul className="">
              <Link to="/">
                <li className="cursor-pointer  p-2 text-[18px]">
                  Home
                </li>
              </Link>
              <Link to="/about">
                <li className="cursor-pointer  p-2 text-[18px]">
                  About
                </li>
              </Link>
              <Link to="/contact">
                <li className="cursor-pointer  p-2 text-[18px]">
                  Contact
                </li>
              </Link>
              <Link to="/career">
                <li className="cursor-pointer  p-2 text-[18px]">
                  Career
                </li>
              </Link>
              <Link to="/options">
                <li className="cursor-pointer  p-2 text-[18px]">
                Global Pages
                </li>
              </Link>
              <Link to="/faq">
                <li className="cursor-pointer  p-2 text-[18px]">
                  FAQ
                </li>
              </Link>
            </ul>
          </div>
          <div className="border border-black md:w-3/12  mt-5">
            <p className="text-xl font-bold p-2">Legal Section</p>
            <ul>
              <Link to="/userguidlines">
                <li className="cursor-pointer  p-2 text-xl">
                User Guidlines
              </li></Link>
              <Link to="/termsandconditions">
              <li className="cursor-pointer  p-2 text-xl">
                Terms and Conditions
              </li>
              </Link>
              
              <Link to="/privacyandpolicy">
              <li className="cursor-pointer  p-2 text-xl">
                Privacy and Policy
              </li>
              </Link>
              
              <Link to="/refundpolicyandcancellationpolicy">
              <li className="cursor-pointer  p-2 text-xl">
                Refund Policy and Cancellation Policy
              </li>
              </Link>
              
            </ul>
            <p className="text-[18px] p-2 ">Contact Us</p>
            <div className="">
            <div className="mb-5"><p className="text-[18px] p-2">Register Office :-</p>
              <p className="p-3 text-[15px]   mr-5">C/O- JHINTU BAIDYA ADHIKARI, Plot No 2005/10019/9, Near Arya Colony HS School, Belonia, South Tripura- 799 115, Tripura, India.</p>
        </div>
            <div className="mb-5">
                <p className="text-[18px] p-2" >Corporate Office :-</p>
                <p className="p-3 text-[15px]   mr-5">2D, Raj Roshni, 94 Sahapur main road, Kolkata-700 038, West Bengal, India.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer2;
