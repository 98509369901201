import React from 'react'
import AdaptiveFashion1 from "../../Fashion For All ages/Adaptive Fashion01.jpg"
import AdaptiveFashion2 from "../../Fashion For All ages/Adaptive Fashion02.jpg"

const AdaptiveFashion = () => {
  return (
    <div>
      <div className='my-20 md:my-32 lg:my-20'>
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in">Adaptive Fashion
      </p>
        <div className='' data-aos="zoom-in-up">
        <div className=''>
          <div className='lg:flex lg:justify-between'>
          <div className='p-5 md:p-16 lg:p-0'>
          <img src={AdaptiveFashion1} className='lg:w-1/2 lg:m-5 lg:ml-10 rounded-3xl'></img>
          </div>
          </div>
        
       <div className='border border-white rounded-3xl m-5 md:m-14 lg:m-3 lg:ml-10 lg:mr-[45%] xl:mr-[60%]'>
       <p className=' ml-5 md:ml-10 my-5 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold text-2xl">Inclusive Fashion Solutions : </span>Inclusive clothing for all</p>
        <p className=' mb-3 md:m-5 ml-5  md:ml-10 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold text-2xl"> Comfort, Functionality, and Style :</span>  Fashion with comfort and style</p>
       </div>
       </div>
       
        </div>
        <div className='my-10' data-aos="zoom-in-up" >
        <div className='border border-white rounded-3xl m-5 md:m-14 lg:m-3   lg:ml-[45%] my-5
        lg:mb-10  lg:mr-16  xl:mr-40'>
        <p className=' ml-5 md:ml-[5%] text-xl lg:text-[18px] xl:text-xl mt-5'><span className="font-bold text-2xl">Breaking Barriers : </span>Fashion challenging norms</p>
        <p className=' ml-5 md:ml-[64%] text-xl my-1'></p>
        <p className=' ml-5 md:ml-[5%] text-xl lg:text-[18px] xl:text-xl mt-3 mb-5 '><span className="font-bold text-2xl">Empowering Self-Expression : </span>Fashion empowering identity</p>
        </div>
        <div className='p-5 md:p-16 lg:p-0'>
        <img src={AdaptiveFashion2} className='lg:w-1/2 lg:ml-[45%] rounded-3xl md:mr-10'></img>
        </div>
        </div>        
      </div>
    </div>
  )
}

export default AdaptiveFashion
// Adaptive Fashion:
// Inclusive Fashion Solutions: Inclusive clothing for all.
// Comfort, Functionality, and Style: Fashion with comfort and style.
// Breaking Barriers: Fashion challenging norms.
// Empowering Self-Expression: Fashion empowering identity.

