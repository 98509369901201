import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const ContactInfo = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white  lg:my-60"
    data-aos="fade-up">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">
        Contact Information
      </p>
      <div className="lg:flex lg:justify-evenly mt-28">
        <div
          className="lg:w-1/2 border border-white h-[250px] mx-5 rounded-xl hover:scale-105  duration-700 mb-5"
          
        >
          <div className="flex justify-center">
          <img src="https://thumb.ac-illust.com/1e/1e4e382499bd10f4bc814033adb85517_t.jpeg"
           className=" h-[100px] mt-5">
          </img>
          </div>
          <p className="text-2xl font-bold flex justify-center mt-7">
            www.poshavesh.com
          </p>
        </div>
        <div
          className="lg:w-1/2 border border-white mx-5 h-[250px] rounded-xl hover:scale-105 duration-700 mb-5"
          
        >
          <div className="flex justify-center">
          <img src="https://static.vecteezy.com/system/resources/previews/015/720/174/original/eps10-white-phone-call-or-telephone-abstract-icon-isolated-on-black-background-contact-us-or-hotline-symbol-in-a-simple-flat-trendy-modern-style-for-your-website-design-logo-and-mobile-app-vector.jpg"
           className=" h-[150px]">
          </img>
          </div>
          <p className="text-2xl font-bold flex justify-center">
            +91-9612676260
          </p>
        </div>
        <div
          className="lg:w-1/2 border border-white h-[250px] mx-5 rounded-xl hover:scale-105 duration-700 mb-5"
          
        >

         <div className="flex justify-center mt-5">
          <img src="https://i.pinimg.com/736x/b8/a3/2d/b8a32d5bfb694e012dde03d2e6b90c9f.jpg"
           className=" h-[100px]">
          </img>
          </div>
          <p className="text-2xl font-bold flex justify-center mt-7">
            info@poshavesh.com
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
