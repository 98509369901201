import React from 'react'
import { Link } from 'react-router-dom'

const ContactInformation = () => {
  return (
    <div className='my-20'>
        <p className=" text-4xl xl:text-6xl flex justify-center font-bold mb-20 "  data-aos="zoom-in"> Contact Information</p>
        <div className='flex justify-center'>
       <Link to="/contact"> <button className='flex justify-center p-5 px-10  bg-white rounded-3xl text-black font-bold hover:bg-black hover:text-white duration-500 hover:border hover:border-white'>Contact</button></Link>
        </div>
   
    </div>
    
  )
}

export default ContactInformation