import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NewArrivalsLookbook1 from "../../Sustainability Efforts Images/Fair Trade  Certified.png"
import NewArrivalsLookbook2 from "../../Sustainability Efforts Images/B Corp  Certified .png"
import NewArrivalsLookbook3 from "../../Sustainability Efforts Images/Cradle to Cradle 01.png"
import NewArrivalsLookbook4 from "../../Sustainability Efforts Images/OEKO-TEX  Certified.png"

const CertificationsAndStandards = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className="md:my-40">
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Certifications & Standards
      </p>
        <div>
          <div className="lg:ml-[5%] xl:ml-[10%]">
            <div className="flex justify-center lg:inline-block  lg:ml-[11%]  xl:ml-[7%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook1} className="rounded-full w-[110px] mb-3"></img>
            </div>
          <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] md:m-14 lg:m-0 rounded-2xl'data-aos="fade-right">
           <p className="text-2xl font-bold flex justify-center mt-5">Fair Trade </p>
           <p className="text-2xl font-bold flex justify-center">Certified</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mt-5">  Ethical sourcing </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">assurance</p>
        </div>
          </div>

          <div className=" mt-16 lg:ml-[20%] xl:ml-[30%]">
            <div className=" flex justify-center lg:inline-block  lg:ml-[14%] xl:ml-[10%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook2} className="rounded-full w-[110px] mb-3"></img>
            </div>
            <div className=' border border-white m-3  lg:w-[300px]  lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">B Corp  </p>
        <p className="text-2xl font-bold flex justify-center">Certified</p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Social</p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">environmental accountability</p>
        </div>
          </div>
        </div>

        <div className="mt-16 lg:ml-[40%] xl:ml-[50%]">
        <div className="flex justify-center lg:inline-block lg:ml-[18%]  xl:ml-[13%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook3} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Cradle to Cradle  </p>
        <p className="text-2xl font-bold flex justify-center ">Certified</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5"> Highest sustainability  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">standards</p>
        </div>
        </div>
        
        <div className="mt-16 lg:ml-[60%] xl:ml-[70%]">
        <div className="flex justify-center lg:inline-block  lg:ml-[28%] xl:ml-[22%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook4} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3  lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3 'data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">OEKO-TEX  </p>
        <p className="text-2xl font-bold flex justify-center">Certified</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Harmful substance-free </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 "> guarantee</p>
        </div>
        </div>
        
        
    </div>
  )
}

export default CertificationsAndStandards
// Certifications & Standards
// Fair Trade Certified: Ethical sourcing assurance.
// B Corp Certified: Social, environmental accountability.
// Cradle to Cradle Certified: Highest sustainability standards.
// OEKO-TEX Certified: Harmful substance-free guarantee.

