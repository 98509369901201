import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BrandIdentity1 from "../../About Us Images/Brand Identity.jpg"


const BrandIdentity = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white lg:my-60">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">
        Brand Identity
      </p>
      <div className="lg:flex p-5 lg:mt-28">
        <div className="lg:w-1/2 mx-2" data-aos="fade-right">
          <img
            className="rounded-3xl"
            src={BrandIdentity1}
            alt="teamimage"
          ></img>
        </div>
        <div className="lg:w-1/2 lg:ml-5" data-aos="fade-left">
          <p className="text-xl lg:text-2xl my-10 lg:my-0">
            Our tone of voice is characterized by warmth, authenticity, and a
            touch of elegance, reflecting our commitment to forging genuine
            connections with our customers. Overall, Poshavesh's aesthetic
            exudes modernity, creativity, and a flair for individuality,
            inviting visitors to expect nothing less than exceptional
            craftsmanship, unparalleled quality, and memorable interactions with
            our brand.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BrandIdentity;
