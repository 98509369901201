import React from 'react';
import AccordionSales from './AccordionSales';

const items = [
  {
    title: '1. How can I place an order on Poshavesh?',
    content: 'Placing an order is simple! Browse our online catalogue, select your desired items, choose your size and colour, and click "inquiry now” with your details our sales team will connect with you shorty also you will receive a confirmation email shortly after'
  },
  {
    title: '2. What payment methods do you accept?',
    content: 'We accept various payment methods including credit/debit cards and online payment (Visa, MasterCard, American Express), PayPal, and Apple Pay. For international orders, additional payment options may be available.'
  },
  {
    title: '3. Do you offer discounts for bulk purchases or wholesale orders?',
    content: 'Poshavesh will not. Please contact our sales team at sales@poshavesh.com for more details and to discuss your specific needs.'
  },
  {
    title: '4. How can I track my order status?',
    content: 'Once your order is shipped, you will receive a tracking number via email. You can use this number on our websites "Track Order" page to monitor the status and location of your shipment.'
  },
  {
    title: '5. What is your return and exchange policy?',
    content: 'We offer a 7-day return and exchange policy. If you are not satisfied with your purchase, you can return or exchange the item in its original condition within 7 days of receipt. Please visit our  nearest store for more information and instructions.'
  }
  ,
  {
    title: '6. Do you ship internationally?',
    content: 'Yes, we ship to many countries around the world. Shipping costs and delivery times vary depending on the destination. During payment, you can select your country to see the available shipping options and costs.'
  }
];

const FAQSectionSales = () => {
  return (
    <div className="py-8" data-aos="fade-up">
      <AccordionSales items={items} />
    </div>
  );
};

export default FAQSectionSales;
