import React from 'react'
import DiversityInclusions1 from "../../Career Page Images/Diversity and Inclusion Initiatives.jpg"
const DiversityInclusions = () => {
  return (
    <div className='text-white  lg:my-60 pb-10'>
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">Diversity and Inclusion Initiatives
    </p>
    <div className='lg:flex'>
    <div className='lg:w-1/2 p-5 xl:m-16' data-aos="fade-right">
          <img className='rounded-3xl' src={DiversityInclusions1}></img>
        </div>
        <div className='lg:w-1/2 p-5 xl:m-16 lg:mt-20 xl:mt-40' data-aos="fade-left">
          <p className='flex justify-center text-xl md:text-2xl my-3'>Diverse Hiring Practices</p>
          <p className='flex justify-center text-xl md:text-2xl my-3'>Inclusive Culture Programs</p>
</div>
       

      </div>
    </div>
  )
}

export default DiversityInclusions