import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Welcome = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='text-white  mt-32 lg:my-60 pb-10 ' >
        <p className='flex justify-center text-6xl font-bold' data-aos="zoom-in">Welcome</p>
        <div className=' flex justify-center border border-white rounded-3xl text-xl md:text-2xl p-10 m-4 lg:mx-40 xl:mx-60 mt-20' data-aos="fade-up"> 
    <p>Welcome to Poshavesh! Your unique talents and passion will help us continue to redefine the fashion industry. Together, we will achieve great things and create extraordinary experiences.
</p>
    </div>
    </div>
  )
}

export default Welcome