import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import EditorsPicks1 from "../../New Arrivals Images/editor_s pic 01.jpg" 
import EditorsPicks2 from "../../New Arrivals Images/Editor_s pic 02.jpg" 

const EditorsPicks = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="lg:my-60">
       <p className="text-[34px]  lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in">Editor's Picks
      </p>
      <div className='md:flex md:justify-between'>
      <div className='md:w-1/2 m-5 xl:ml-[-5%]'data-aos="fade-right" >
      <img src={EditorsPicks1} className='h-3/4 xl:ml-[16%] mt-5   rounded-3xl'></img>
      <div className="border border-white xl:ml-[20%] my-5 rounded-3xl mx-5">
      <p className=' mt-5'></p>
      <p className='ml-5  mt-5 text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Runway Rendezvous :</span>   Step into the world of high fashion with our editor's picks</p>
      <p className='ml-5 my-4 text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Iconic Essentials :</span> Elevate your wardrobe with timeless pieces handpicked by our experts</p>
      
      </div>
      </div>
      <div className='m-5  md:w-1/2 mt-20 xl:mr-[5%]' data-aos="fade-left">
        <div className="mx-5  xl:ml-[25%]  md:mr-[10%] border border-white rounded-3xl my-5">
        <p className='ml-5 mt-5  text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Artisanal Accents : </span>Discover craftsmanship at its finest with our selection of artisanal treasures</p>
        <p className="text-xl mb-5 ml-[57%]"></p>
        <p className='ml-5 mt-2 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold  text-2xl">Effortless Chic : </span>Embrace effortless elegance with our editor's picks</p>
        <p  className='ml-[64%] mb-5 text-xl'></p>
        </div>
      <div className="p-6">
      <img src={EditorsPicks2} className='h-3/4 rounded-3xl   md:mt-5 md:mt-15 xl:ml-[8%]'></img>
      </div>
      </div>
    </div>
    </div>
  )
}

export default EditorsPicks
// Editor's Picks
// Runway Rendezvous: Step into the world of high fashion with our editor's picks.
// Iconic Essentials: Elevate your wardrobe with timeless pieces handpicked by our experts.
// Artisanal Accents: Discover craftsmanship at its finest with our selection of artisanal treasures.
// Effortless Chic: Embrace effortless elegance with our editor's picks
