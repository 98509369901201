import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import BodyPositivityandInclusivity1 from "../../Fashion For All ages/Body Positivity and Inclusivity01.jpg"
import BodyPositivityandInclusivity2 from "../../Fashion For All ages/Body Positivity and Inclusivity02.jpg"


const BodyPositivityandInclusivity = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div>
      <div className='my-20'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in"> Body Positivity and Inclusivity
</p>
        <div data-aos="zoom-in-up" className="lg:flex lg:justify-between">
          <div className="p-5 md:p-16 lg:p-0  lg:w-1/2 lg:m-10 ">
          <img src={BodyPositivityandInclusivity1} className='rounded-3xl '></img>
          </div>
          
        <div className="">
        <div className="border border-white rounded-3xl m-5 xl:m-10 lg:mt-10 xl:mt-40 md:m-14 lg:m-3 ">
       <p className='ml-5 my-4 text-xl lg:text-[18px] xl:text-xl' ><span className="font-bold text-2xl">Celebrating Diversity : </span>  Diversity in fashion representation</p>
        <p className='ml-5   my-4  text-xl lg:text-[18px]  xl:text-xl' ><span className="font-bold text-2xl">Empowering Confidence : </span> Fashion for self-confidence</p>
       </div>
        </div>
        </div>
        <div className='lg:my-10 lg:flex lg:justify-between' data-aos="zoom-in-up" >
       <div className="inline-block item-center m-5 md:m-14 lg:m-3 lg:mt-10  ">
       <div className="border border-white rounded-3xl my-1 xl:my-28 ml-3">
        <p className='m-5 text-xl lg:text-[18px]  xl:text-xl' ><span className="font-bold text-2xl"> Inclusive Representation : </span>Diverse models in fashion</p>
        <p className='m-5 text-xl lg:text-[18px]  xl:text-xl' ><span className="font-bold text-2xl"> Promoting Inclusivity : </span>Welcoming fashion environment</p>
        </div>
       </div>
       <div className="p-5 md:p-16 lg:p-0 lg:w-1/2 lg:m-10">
       <img src={BodyPositivityandInclusivity2} className=' rounded-3xl'></img>
       </div>
        
        </div>
      </div>
    </div>
  )
}

export default BodyPositivityandInclusivity
// Body Positivity and Inclusivity:
// Celebrating Diversity: Diversity in fashion representation.
// Empowering Confidence: Fashion for self-confidence.
// Inclusive Representation: Diverse models in fashion.
// Promoting Inclusivity: Welcoming fashion environment.

