import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const StyleTech = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className="md:my-40">
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">StyleTech
      </p>
        <div>
          <div className="lg:ml-[5%] xl:ml-[10%]">
            <div className="flex justify-center lg:inline-block  lg:ml-[11%]  xl:ml-[7%]" data-aos="zoom-in">
            <img src="https://static.vecteezy.com/system/resources/previews/031/967/204/original/virtual-fashion-show-icon-design-vector.jpg" className="rounded-full w-[110px] mb-3"></img>
            </div>
          <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] md:m-14 lg:m-0 rounded-2xl'data-aos="fade-right">
           <p className="text-2xl font-bold flex justify-center mt-5">Virtual Fashion Shows</p>
           <p className="text-2xl font-bold flex justify-center">Shows</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mt-5"> Enter the Poshavesh</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">digital realm</p>
        </div>
          </div>

          <div className=" mt-16 lg:ml-[20%] xl:ml-[30%]">
            <div className=" flex justify-center lg:inline-block  lg:ml-[14%] xl:ml-[10%]" data-aos="zoom-in">
            <img src="https://st3.depositphotos.com/25673058/34688/v/450/depositphotos_346885176-stock-illustration-artificial-intelligence-powered-human-brain.jpg" className="rounded-full w-[110px] mb-3"></img>
            </div>
            <div className=' border border-white m-3  lg:w-[300px]  lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">AI-Powered Styling </p>
        <p className="text-2xl font-bold flex justify-center">Tools</p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Personalized recommendations </p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">with Poshavesh</p>
        </div>
          </div>
        </div>

        <div className="mt-16 lg:ml-[40%] xl:ml-[50%]">
        <div className="flex justify-center lg:inline-block lg:ml-[18%]  xl:ml-[13%]" data-aos="zoom-in">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ5a_72N6XaV5Rmc-5FXukFWzTfi9Xql4dsxugCDx2nag&s" className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Innovative Design </p>
        <p className="text-2xl font-bold flex justify-center ">Solutions</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5"> Redefining design </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3"> with Poshavesh </p>
        </div>
        </div>
        
        <div className="mt-16 lg:ml-[60%] xl:ml-[70%]">
        <div className="flex justify-center lg:inline-block  lg:ml-[28%] xl:ml-[22%]" data-aos="zoom-in">
            <img src="https://cdn-icons-png.flaticon.com/512/9750/9750936.png" className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3  lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3 'data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Interactive Fashion </p>
        <p className="text-2xl font-bold flex justify-center">Experiences</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Blurring digital and physical </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">with Poshavesh </p>
        </div>
        </div>
        
        
    </div>
  )
}

export default StyleTech
// Fashion Technology:
// Virtual Fashion Shows: Enter the PV digital realm.
// AI-Powered Styling Tools: Personalized recommendations with PV.
// Innovative Design Solutions: Redefining design with PV.
// Interactive Fashion Experiences: Blurring digital and physical with PV.
