import React from 'react'
import ContactInformation1 from "../../Fashion For All ages/Contact Information 01.jpg"
import ContactInformation2 from "../../Fashion For All ages/Contact Information02.jpg"


const ContactInformation = () => {
  return (
    <div className='mt-40 lg:mb-10 '>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold  mb-32"  data-aos="zoom-in">Contact Information</p>
        <div className='lg:flex ml-5'>
            <div className='lg:w-1/2 lg:p-5 xl:p-0' data-aos="fade-right">
                <div className='md:mr-10 lg:p-5 xl:p-8'>
                <img className='md:m-5 rounded-3xl transform skew-y-6' src={ContactInformation1}>
                </img>
                </div>
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl'>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Accessible Support Channels : </span> Easy fashion support access</p>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Welcoming Feedback : </span>Open to fashion feedback</p>
                </div>
            </div>
            <div className='mt-16 lg:w-1/2 lg:p-5 xl:p-0 lg:mr-5 xl:mr-0' data-aos="fade-left">
              <div className=' md:mr-10 lg:p-5 xl:p-8'>
              <img className='md:m-5 rounded-3xl transform skew-y-6 ' src={ContactInformation2}>
                </img>
              </div>
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl '>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Responsive Customer Service : </span> Prompt fashion assistance</p>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Building Trust :</span>Transparent fashion communication</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default ContactInformation
// Contact Information:
// Accessible Support Channels: Easy fashion support access.
// Welcoming Feedback: Open to fashion feedback.
// Responsive Customer Service: Prompt fashion assistance.
// Building Trust: Transparent fashion communication

