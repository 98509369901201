import React from 'react'
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Address = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='text-white mt-32 mb-60' >
      <div className='lg:flex lg:justify-between'>
      <div className='border border-white lg:w-1/2 lg:h-[250px] m-5 rounded-2xl p-5 lg:p-0' data-aos="fade-up" >
        <p className='flex justify-center text-2xl font-bold my-10'>Register Office</p>
        <p className=' flex justify-center text-xl '>C/O- JHINTU BAIDYA ADHIKARI, </p>
        <p className=' flex justify-center text-xl my-2 '>  Plot No 2005/10019/9,Near Arya Colony HS School,</p>
        <p  className=' flex justify-center text-xl my-2 '> Belonia, South Tripura- 799 115, Tripura, India.</p>
      </div>
      <div  className='border border-white lg:w-1/2 lg:h-[250px] m-5 rounded-2xl p-5 lg:p-0' data-aos="fade-up">
      <p className='flex justify-center text-2xl font-bold my-10'>Corporate Office</p>
      <p className=' flex justify-center  text-xl my-2 '>2D, Raj Roshni, 94 Sahapur main road, </p>
      <p className=' flex justify-center text-xl my-2 '> Kolkata-700 038, West Bengal, India.</p>
      </div>
      </div>
     
    </div>
   )
}

export default Address;