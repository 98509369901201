import React from 'react'
import OverviewofPartnerships1  from "../../Company Partnerships Images/Overview of Partnerships01.jpg" 
import OverviewofPartnerships2  from "../../Company Partnerships Images/Overview of Partnerships02.jpg" 



const OverviewofPartnerships = () => {
  return (
    <div>
      <div className='my-20 md:my-32 mt-32'>
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in"> Overview of Partnerships
      </p>
        <div className='' data-aos="zoom-in-up">
        <div className=''>
          <div className='lg:flex lg:justify-between'>
          <div className='p-5 md:p-16 lg:p-0'>
          <img src={OverviewofPartnerships1} className='lg:w-1/2 lg:m-5 lg:ml-10 rounded-3xl'></img>
          </div>
          </div>
        
       <div className='border border-white rounded-3xl m-5 md:m-14 lg:m-3 lg:ml-10 lg:mr-[45%] xl:mr-[60%]'>
       <p className=' ml-5 md:ml-10 my-5 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold text-2xl">Strategic Alliances : </span>Expand reach and drive innovation</p>
        <p className=' mb-3 md:m-5 ml-5  md:ml-10 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold text-2xl">Cross-Industry Collaborations :</span>   Fuel innovation through partnerships</p>
       </div>
       </div>
       
        </div>
        <div className='my-10' data-aos="zoom-in-up" >
        <div className='border border-white rounded-3xl m-5 md:m-14 lg:m-3   lg:ml-[45%] my-5
        lg:mb-10  lg:mr-16  xl:mr-40'>
        <p className=' ml-5 md:ml-[5%] text-xl lg:text-[18px] xl:text-xl mt-5'><span className="font-bold text-2xl">Innovative Solutions : </span>Fuel innovation through partnerships</p>
        <p className=' ml-5 md:ml-[64%] text-xl my-1'></p>
        <p className=' ml-5 md:ml-[5%] text-xl lg:text-[18px] xl:text-xl mt-3 mb-5 '><span className="font-bold text-2xl">Shared Values : </span>Commitment to mutual growth and excellence</p>
        </div>
        <div className='p-5 md:p-16 lg:p-0'>
        <img src={OverviewofPartnerships2} className='lg:w-1/2 lg:ml-[45%] rounded-3xl md:mr-10'></img>
        </div>
        </div>        
      </div>
    </div>
  )
}

export default OverviewofPartnerships
// Overview of Partnerships
// Strategic Alliances: Expand reach and drive innovation.
// Cross-Industry Collaborations: Team up across diverse sectors.
// Innovative Solutions: Fuel innovation through partnerships.
// Shared Values: Commitment to mutual growth and excellence.


