import React from 'react'
import FashionPic1 from "../../Fashion Insights Images/Fashion pic 01.jpg" 
import FashionPic2 from "../../Fashion Insights Images/Fashion pic 02.jpg" 


const FashionPic = () => {
  return (
    <div className='mt-40'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold  mb-32"  data-aos="zoom-in">FashionPic</p>
        <div className='lg:flex ml-5'>
            <div className='lg:w-1/2 lg:p-5 xl:p-0' data-aos="fade-right">
                <div className='lg:p-5 xl:p-8'>
                <img className='md:m-5 rounded-3xl transform skew-y-6' src={FashionPic1}>
                </img>
                </div>
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl'>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Top Photographers : </span> Stunning fashion portfolios with Poshavesh</p>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Breathtaking Editorials : </span>Mesmerizing editorials from Poshavesh</p>
                </div>
            </div>
            <div className='mt-16 lg:w-1/2 lg:p-5 xl:p-0 lg:mr-5 xl:mr-0' data-aos="fade-left">
              <div className=' lg:p-5 xl:p-8'>
              <img className='md:m-5 rounded-3xl transform skew-y-6' src={FashionPic2}>
                </img>
              </div>
               
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl '>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Global Fashion Publications : </span> Diverse cultural perspectives</p>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Creative Inspiration :</span> Spark creativity with Poshavesh imagery</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default FashionPic
// Fashion Photography:
// Top Photographers: Stunning fashion portfolios with PV.
// Breathtaking Editorials: Mesmerizing editorials from PV.
// Global Fashion Publications: Diverse cultural perspectives with PV. removed with pv from this point
// Creative Inspiration: Spark creativity with PV imagery.
