import React from "react";
import VideoSection from "./VideoSection";
import Navbar from "./Navbar";
// import HeroHeadingSection from "./HeroHeadingSection";
import Footer from "./Footer";
import Footer2 from "./Footer2";

const LandingPage = () => {
  return (
    <div className="">
      <Navbar />
      {/* <HeroHeadingSection /> */}
      <VideoSection />
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
      
    </div>
  );
};

export default LandingPage;
