import React from 'react'
import FashioninArtandCulture1 from "../../Fashion History/Fashion in Art and Culture01.jpg"
import FashioninArtandCulture2 from "../../Fashion History/Fashion in Art and Culture02.jpg"

const FashioninArtandCulture = () => {
  return (
    <div className='mt-40'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold  mb-32"  data-aos="zoom-in">Fashion in Art and Culture</p>
        <div className='lg:flex ml-5'>
            <div className='lg:w-1/2 lg:p-5 xl:p-0' data-aos="fade-right">
                <div className='lg:p-5 xl:p-8'>
                <img className='md:m-5 rounded-3xl transform skew-y-6' src={FashioninArtandCulture1}>
                </img>
                </div>
               
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl'>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Artistic Inspirations : </span> Art's fashion impact</p>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Literary Threads : </span>Fashion in literature</p>
                </div>
            </div>
            <div className='mt-16 lg:w-1/2 lg:p-5 xl:p-0 lg:mr-5 xl:mr-0' data-aos="fade-left">
              <div className='lg:p-5 xl:p-8'>
              <img className='md:m-5 rounded-3xl transform skew-y-6' src={FashioninArtandCulture2}>
                </img>
              </div>
                
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl '>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Cinematic Couture : </span> Fashion in film</p>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Melodies of Style :</span> Music's fashion influence</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default FashioninArtandCulture
// Fashion in Art and Culture
// Artistic Inspirations: Art's fashion impact.
// Literary Threads: Fashion in literature.
// Cinematic Couture: Fashion in film.
// Melodies of Style: Music's fashion influence.

