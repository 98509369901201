import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
const Heading = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='lg:mb-72 mt-40'>
       <p className="text-3xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">Explaining Its Purpose</p>
    <div className=' flex justify-center border border-white rounded-3xl text-xl p-10 m-4 lg:mx-40 xl:mx-60' data-aos="fade-up">
    <p>At Poshavesh, we believe in empowering our employees to shape their careers with passion and innovation. Join our team to grow professionally in a dynamic, creative environment where your ideas and talents are valued and nurtured. Together, we'll redefine the future of fashion.</p>
    </div>
    </div>
  )
}

export default Heading