import React, { useEffect, useState } from "react";
import drop_down from "../assets/down-arrow.png";
import drop_up from "../assets/drop-up.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [Down, setDown] = useState(false);
  const Logic = () => {
    setDown(!Down);
    // console.log(Down);
  };
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="relative">
      <div className="z-30 absolute">
        {!Down && (
          <div
            className="flex justify-center w-screen cursor-pointer bg-white rounded-2xl"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="1500"
          >
            <img
              src={drop_down}
              alt="drop_down"
              className="w-[30px] "
              onClick={Logic}
            ></img>
          </div>
        )}
        {Down && (
          <div
            className=" text-white font-bold text-[16px] md:text-2xl bg-black h-[80px] w-screen"
            data-aos="fade-down"
            data-aos-easing="linear"
            data-aos-duration="500"
          >
            <div className="">
              <ul className="flex  justify-evenly">
                <Link to="/">
                  <button className="p-2 px-4 mt-[20px] rounded-2xl hover:bg-white hover:text-black duration-300">
                    Home
                  </button>
                </Link>
                <Link to="/about">
                  <button className="p-2 px-4 mt-[20px] rounded-2xl hover:bg-white hover:text-black duration-300">
                    About
                  </button>
                </Link>
                <button className="p-2 px-4 mt-[20px] rounded-2xl hover:bg-white hover:text-black duration-300">
                  Shop
                </button>
                <Link to="/contact">
                  <button className="p-2 px-4 mt-[20px] rounded-2xl hover:bg-white hover:text-black duration-300">
                    Contact
                  </button>
                </Link>
              </ul>
              <div className="flex justify-center  bg-black">
                <img
                  src={drop_up}
                  alt="drop_up"
                  className=" w-[50px] cursor-pointer"
                  onClick={() => {
                    setDown(!Down);
                  }}
                ></img>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
