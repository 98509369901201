import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import NewArrivalsLookbook1 from "../../Seasonal Campaign Images/Sneak Peek FASHION.png"
import NewArrivalsLookbook2 from "../../Seasonal Campaign Images/Theme Teasers.png"
import NewArrivalsLookbook3 from "../../Seasonal Campaign Images/Exclusive Promotions.png"
import NewArrivalsLookbook4 from "../../Seasonal Campaign Images/Save the Date.png"



const UpcomingCampaignTeasers = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className="md:my-40">
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Upcoming Campaign Teasers
      </p>
        <div>
          <div className="lg:ml-[5%] xl:ml-[10%]">
            <div className="flex justify-center lg:inline-block  lg:ml-[11%]  xl:ml-[7%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook1} className="rounded-full w-[110px] mb-3"></img>
            </div>
          <div className='border border-white m-3 lg:w-[300px] lg:h-[180px] md:m-14 lg:m-0 rounded-2xl'data-aos="fade-right">
           <p className="text-2xl font-bold flex justify-center mt-5">Sneak Peek</p>
           <p className="text-2xl font-bold flex justify-center"></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mt-5">Preview upcoming </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">campaign highlights</p>
        </div>
          </div>

          <div className=" mt-16 lg:ml-[20%] xl:ml-[30%]">
            <div className=" flex justify-center lg:inline-block  lg:ml-[14%] xl:ml-[10%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook2} className="rounded-full w-[110px] mb-3"></img>
            </div>
            <div className=' border border-white m-3  lg:w-[300px]  lg:h-[180px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Theme Teasers</p>
        <p className="text-2xl font-bold flex justify-center"></p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Unveil theme </p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">defining the season</p>
        </div>
          </div>
        </div>

        <div className="mt-16 lg:ml-[40%] xl:ml-[50%]">
        <div className="flex justify-center lg:inline-block lg:ml-[18%]  xl:ml-[13%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook3} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3 lg:w-[300px] lg:h-[180px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Exclusive Promotions </p>
        <p className="text-2xl font-bold flex justify-center "></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5"> Early access to  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3"> upcoming discounts</p>
        </div>
        </div>
        
        <div className="mt-16 lg:ml-[60%] xl:ml-[70%]">
        <div className="flex justify-center lg:inline-block  lg:ml-[28%] xl:ml-[22%]" data-aos="zoom-in">
            <img src={NewArrivalsLookbook4} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3  lg:w-[300px] lg:h-[180px] rounded-2xl md:m-14 lg:m-3 'data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Save the Date</p>
        <p className="text-2xl font-bold flex justify-center"></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Mark calendars  </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">for campaign launch</p>
        </div>
        </div>
        
        
    </div>
  )
}

export default UpcomingCampaignTeasers
// Upcoming Campaign Teasers
// Sneak Peek: Preview upcoming campaign highlights.
// Theme Teasers: Unveil theme defining the season.
// Exclusive Promotions: Early access to upcoming discounts.
// Save the Date: Mark calendars for campaign launch.

