import React from 'react'
import CompanyEventsandActivites1 from "../../Career Page Images/Company Events and Activities.jpg"
const CompanyEventsandActivites = () => {
  return (
    <div className='text-white  lg:my-60 pb-10 '>
    <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">Company Events and Activites
    </p>
    <div>
      <div className=' flex justify-center ' data-aos="fade-up">
        <img className='rounded-3xl h-[250px] w-[370px]  md:h-[600px] md:w-[600px] m-5' src={CompanyEventsandActivites1}></img>
      </div>
      <p className=' flex justify-center text-xl md:text-2xl md:px-20 my-5' data-aos="fade-up">Engaging Team Building Activities</p>
      <p className=' flex justify-center text-xl  md:text-2xl ' data-aos="fade-up">Fun-filled Social Gatherings</p>
    </div>
  </div>
  )
}

export default CompanyEventsandActivites