import React, { useState } from 'react';

const AccordionSales = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div className="w-8/12 mx-auto text-black">
      {items.map((item, index) => (
        <div key={index} className="border border-gray-200 rounded mb-2">
          <div
            className={`flex justify-between items-center px-4 py-2 cursor-pointer ${index === activeIndex ? 'bg-gray-200' : 'bg-white'}`}
            onClick={() => handleClick(index)}
          >
            <span className="text-xl font-bold">{item.title}</span>
            <svg
              className={`w-6 h-6 ${index === activeIndex ? 'transform rotate-180' : ''}`}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19 9l-7 7-7-7"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {index === activeIndex && (
            <div className="px-4 py-2 bg-gray-100">
              <p>{item.content}</p>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default AccordionSales;
