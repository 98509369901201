import React from 'react'
import InteractiveFeatures1 from "../../Fashion History/Interactive Features01.jpg"
import InteractiveFeatures2 from "../../Fashion History/Interactive Features02.jpg"

const InteractiveFeatures = () => {
  return (
    <div>
      <div className='my-20 md:my-32 lg:my-20'>
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in">Interactive Features
      </p>
        <div className='' data-aos="zoom-in-up">
        <div className=''>
          <div className='lg:flex lg:justify-between'>
          <div className='p-5 md:p-16 lg:p-0'>
          <img src={InteractiveFeatures1} className='lg:w-1/2 lg:m-5 lg:ml-10 rounded-3xl'></img>
          </div>
          </div>
        
       <div className='border border-white rounded-3xl m-5 md:m-14 lg:m-3 lg:ml-10 lg:mr-[45%] xl:mr-[60%]'>
       <p className=' ml-5 md:ml-10 my-5 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold text-2xl">Fashion Trivia : </span>Fun fashion quizzes</p>
        <p className=' mb-3 md:m-5 ml-5  md:ml-10 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold text-2xl">Timeless Timelines :</span>  Fashion history journeys</p>
       </div>
       </div>
       
        </div>
        <div className='my-10' data-aos="zoom-in-up" >
        <div className='border border-white rounded-3xl m-5 md:m-14 lg:m-3   lg:ml-[45%] my-5
        lg:mb-10  lg:mr-16  xl:mr-40'>
        <p className=' ml-5 md:ml-[5%] text-xl lg:text-[18px] xl:text-xl mt-5'><span className="font-bold text-2xl">Multimedia Marvels : </span>Interactive fashion presentations</p>
        <p className=' ml-5 md:ml-[64%] text-xl my-1'></p>
        <p className=' ml-5 md:ml-[5%] text-xl lg:text-[18px] xl:text-xl mt-3 mb-5 '><span className="font-bold text-2xl">Designer Showcases : </span>Iconic designer galleries</p>
        </div>
        <div className='p-5 md:p-16 lg:p-0'>
        <img src={InteractiveFeatures2} className='lg:w-1/2 lg:ml-[45%] rounded-3xl md:mr-10'></img>
        </div>
        </div>        
      </div>
    </div>
  )
}

export default InteractiveFeatures
// Interactive Features
// Fashion Trivia: Fun fashion quizzes.
// Timeless Timelines: Fashion history journeys.
// Multimedia Marvels: Interactive fashion presentations.
// Designer Showcases: Iconic designer galleries

