import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import OurStory1 from "../../About Us Images/Our Story.jpg"

const OurStory = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div id="story" className="text-white  lg:my-60">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">
        Our Story
      </p>
      <div className="lg:flex p-3  m-3 lg:mt-28 ">
        <div className="lg:w-1/2 m-5" data-aos="fade-right">
          <img
            className="rounded-3xl"
            src={OurStory1}
            alt="teamimage"
          ></img>
        </div>
        <div className="lg:w-1/2 lg:ml-10 text-xl lg:text-2xl mt-10 lg:mt-0 " data-aos="fade-left">
          <p>
            Poshavesh, an Indian-origin brand, is more than just fashion it's a
            celebration of individuality and global style. Our journey is about
            empowering our consumers to embrace their uniqueness and stand out
            on the world stage. With every design, we curate experiences that
            inspire confidence and redefine fashion lifestyles, propelling us
            towards a future of growth and innovation.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
