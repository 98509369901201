import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CustomerFavorites1 from "../../New Arrivals Images/Customer favourites.jpg" 


const CustomerFavorites = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className='lg:my-40'>
        <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Customer Favorites
      </p>
        <div className='xl:flex  xl:justify-between'>
            <div className='xl:w-1/2 p-5 md:p-16 xl:p-5' data-aos="zoom-in">
                <img src={CustomerFavorites1} className="rounded-3xl lg:ml-5"></img>
            </div>
            <div className='xl:w-1/2 md:ml-5 lg:ml-20 xl:ml-0'>
                <div className='border border-white md:h-[118px] m-2 rounded-r-full md:w-[330px] ' data-aos="fade-right">
                   <p className=" text-[18px] m-1 mt-4"><div className="font-bold my-2 text-2xl">Top-Rated Treasures </div>Explore our top picks loved by all</p>
                </div>
                <div className='border border-white rounded-r-full  md:h-[118px] md:w-[400px] lg:w-[500px] xl:w-[400px]  m-2'data-aos="fade-right">
                <p className=" text-[18px] m-1 mt-6"><div className="font-bold my-2 text-2xl">Crowd Pleasers</div>Discover the favorites winning hearts</p>               
                 </div>
                <div className='border border-white rounded-r-full md:h-[118px] md:w-[600px] lg:w-[600px] xl:w-[600px] m-2'data-aos="fade-right">
                <p className=" text-[18px] m-1 mt-6"><div className="font-bold my-2 text-2xl">Stellar Reviews</div>Dive into products praised by our customers</p>    
                </div>
                <div className='border border-white rounded-r-full  md:h-[118px] md:w-[700px] lg:w-[700px] xl:w-[700px]  m-2'data-aos="fade-right">
                <p className=" text-[18px]  m-1 mt-6"><div className="font-bold my-2 text-2xl">Best in Show</div>Reveal our champions, chosen by you</p>    
                </div>
            </div>
        </div>
    </div>
  )
}

export default CustomerFavorites
// Customer Favorites
// Top-Rated Treasures: Explore our top picks loved by all.
// Crowd Pleasers: Discover the favorites winning hearts.
// Stellar Reviews: Dive into products praised by our customers.
// Best in Show: Reveal our champions, chosen by you.

