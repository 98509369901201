import React from 'react'
import IntroductiontoFashionforAllAges from './IntroductiontoFashionforAllAges'
import AgeSpecificFashionGuides from './AgeSpecificFashionGuides'
import TrendSpotlights from './TrendSpotlights'
import BodyPositivityandInclusivity from './BodyPositivityandInclusivity'
import MultiGenerationalStyling from './MultiGenerationalStyling'
import AdaptiveFashion from './AdaptiveFashion'
import FashionIconsandInfluencers from './FashionIconsandInfluencers'
import AgeAppropriateStylingTips from './AgeAppropriateStylingTips'
import CustomerStoriesandTestimonials from './CustomerStoriesandTestimonials'
import ContactInformation from './ContactInformation'
import EducationalResources from './EducationalResources'
import CommunityEngagement from './CommunityEngagement'
import Navbar from "../Navbar"
import Footer from "../Footer"
import Footer2 from "../Footer2"
import ShopNowButton from '../ShopNowButton'
const Fashionforallages = () => {
  return (
    <div className='overflow-x-hidden overflow-y-hidden'>
      <Navbar />
      <IntroductiontoFashionforAllAges/>
      <ShopNowButton/>
      <AgeSpecificFashionGuides/>
      <ShopNowButton/>
      <TrendSpotlights/>
      <ShopNowButton/>
      <BodyPositivityandInclusivity/>
      <ShopNowButton/>
      <MultiGenerationalStyling/>
      <ShopNowButton/>
      <AdaptiveFashion/>
      <ShopNowButton/>
      <FashionIconsandInfluencers/>
      <ShopNowButton/>
      <AgeAppropriateStylingTips/>
      <ShopNowButton/>
      <CustomerStoriesandTestimonials/>
      <ShopNowButton/>
      <CommunityEngagement/>
      <ShopNowButton/>
      <EducationalResources/>
      <ShopNowButton/>
      <ContactInformation/>
      <ShopNowButton/>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default Fashionforallages