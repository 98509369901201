import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from "./Navbar"
import Footer from "./Footer"
import Footer2 from './Footer2'

const Options = () => {
  return (
    <div className='overflow-x-hidden'>
        <Navbar/>
        <div className='flex flex-wrap mt-40 xl:ml-2' >
        <Link to="/fashioninsights">
        <div className='options border border-white rounded-2xl  h-[250px] w-[280px] md:w-[350px] p-5 md:m-5 m-5  hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16 lg:ml-[90px]  xl:ml-[60px]'>
          <div className='flex justify-center'>
          <img src='https://us.123rf.com/450wm/deimosz/deimosz1311/deimosz131100427/33747754-fashion-trends-or-lifestyle-business-concept-present-by-computer-laptop-with-group-of-colorful-lady.jpg' className='hover:scale-105 duration-500  rounded-full w-[130px]'></img>
          </div>
        <p className='flex justify-center my-7 text-2xl md:text-3xl font-bold'>FASHION INSIGHTS</p>
        </div>
        </Link>
        
        <Link to="/newarrivals">
        <div className=' options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16'>
        <div className='flex justify-center'>
          <img src='https://cdn-icons-png.flaticon.com/512/2037/2037558.png' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center my-7 text-2xl md:text-3xl font-bold'>NEW ARRIVALS</p>
        </div>
        </Link>

        <Link to="/sustainabilityefforts">
        <div className='options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16 lg:ml-[90px]  xl:ml-[60px] '>
        <div className='flex justify-center'>
          <img src='https://img.freepik.com/premium-vector/clothes-recycle-icon-sustainable-fashion-logo-eco-friendly-concept-vector-illustration_168129-2980.jpg' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center mt-5 text-2xl  md:text-3xl font-bold'>SUSTAINABILITY </p>
        <p className='flex justify-center text-2xl  md:text-3xl font-bold'> EFFORTS</p>
        </div>
        </Link>
        
        <Link to="/contact">
        <div className=' options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16'>
        <div className='flex justify-center'>
          <img src='https://static.vecteezy.com/system/resources/previews/015/720/174/original/eps10-white-phone-call-or-telephone-abstract-icon-isolated-on-black-background-contact-us-or-hotline-symbol-in-a-simple-flat-trendy-modern-style-for-your-website-design-logo-and-mobile-app-vector.jpg' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center my-5 text-2xl md:text-3xl font-bold'>CONTACT US </p>
        </div></Link>
      

        <Link to='/companypartnerships'>
        <div className=' options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16 lg:ml-[90px]  xl:ml-[60px]'>
        <div className='flex justify-center'>
          <img src='https://cdn.iconscout.com/icon/premium/png-256-thumb/strategic-partnerships-5350641-4467190.png?f=webp' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center mt-5 text-2xl md:text-3xl font-bold'>COMPANY </p>
        <p className='flex justify-center text-2xl md:text-3xl font-bold'> PARTNERSHIPS </p>
        </div>
        </Link>
        
        <Link to="/seasonalcampaigns">
        <div className=' options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16'>
        <div className='flex justify-center'>
          <img src='https://images.squarespace-cdn.com/content/v1/524883b7e4b03fcb7c64e24c/1649983526642-AQ97SY6F8FQTSJJH8GUF/Holiday+SEO.jpg?format=1500w' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center mt-5 text-2xl md:text-3xl font-bold'>SEASONAL </p>
        <p className='flex justify-center text-2xl md:text-3xl font-bold'> CAMPAIGNS</p>
        </div>
        </Link>
        
        <Link to="/industryinsightsandtrends">
        <div className=' options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16 lg:ml-[90px]  xl:ml-[60px]'>
        <div className='flex justify-center '>
          <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQv3BXhejeWl0xIyEkghFfoHPXlh073gCBKTK51e7AZnLKUXo0nkCkCOobvjlB8PwqF5l8&usqp=CAU' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center mt-5 text-[23px] md:text-3xl font-bold'>INDUSTRY INSIGHTS</p>
        <p className='flex justify-center  text-[23px] md:text-3xl font-bold'> AND TRENDS</p>
        </div>
        </Link>
       
        <Link to="/fashionhistory">
        <div className=' options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16'>
        <div className='flex justify-center'>
          <img src='https://cdn-icons-png.flaticon.com/512/7417/7417801.png' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center my-5 text-2xl md:text-3xl font-bold'>FASHION HISTORY</p>
        </div>
        </Link>
        
        <Link to="/fashioninnovationlab"> 
        <div className=' options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16 lg:ml-[90px]  xl:ml-[60px]'>
        <div className='flex justify-center'>
          <img src='https://media.wnyc.org/i/800/0/l/85/1/shutterstock_215245009.jpg' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center mt-5 text-2xl md:text-3xl font-bold'>FASHION</p>
        <p className='flex justify-center text-2xl md:text-3xl font-bold'> INNOVATION LAB</p>
        </div>
        </Link>
       
      <Link to="/fashionforallages">
      <div className=' options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16'>
        <div className='flex justify-center'>
          <img src='https://png.pngtree.com/png-clipart/20230928/original/pngtree-vector-illustration-of-a-circle-with-diverse-styles-of-womens-clothing-png-image_12902689.png' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center mt-5 text-2xl md:text-3xl font-bold'>FASHION FOR</p>
        <p className='flex justify-center text-2xl  md:text-3xl font-bold'> ALL AGES</p>
        </div>
        </Link>
        

        <Link to="/customersatisfaction">
        <div className=' options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16 lg:ml-[90px]  xl:ml-[60px]'>
        <div className='flex justify-center'>
          <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTksOoT9iHYAj5mwvPEoJfeUlQqNCrbhG6wmLu0f81uMQ&s' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center mt-5 text-2xl md:text-3xl font-bold'>CUSTOMER </p>
        <p className='flex justify-center  text-2xl md:text-3xl font-bold'> SATISFACTION </p>
        </div>
        </Link>
        
        <Link to="/faq">
        <div className=' options border border-white rounded-2xl h-[250px] w-[280px] md:w-[350px] p-5 m-5 hover:bg-white hover:text-black duration-500 md:mx-52 lg:mx-16 '>
        <div className='flex justify-center'>
          <img src='https://static.vecteezy.com/system/resources/previews/010/042/805/original/faq-icon-help-symbol-clean-and-modern-illustration-for-a-website-or-mobile-applications-isolated-in-white-background-best-used-for-frequently-asked-question-icon-free-vector.jpg' className='hover:scale-105 duration-500  rounded-full w-[130px]' ></img>
        </div>
        <p className='flex justify-center my-5 text-2xl md:text-3xl font-bold'>FAQ</p>
        </div>
        </Link>
    </div>
    <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    
    </div>
  )
}

export default Options