import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import IndustryImpactandSuccessStories1 from "../../Fashion Innovation Lab Images/Industry Impact and Success Stories.jpg"

const IndustryImpactandSuccessStories = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className=" md:mb-40 lg:mb-16 lg:mt-60">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in"> Industry Impact and Success Stories</p>
      <div className='lg:flex lg:justify-evenly'>
        <div className='w-full lg:w-4/12 mx-1' >
          <div className='lg:w-[200px] xl:w-1/2 lg:h-[220px] lg:ml-[150px] xl:ml-[245px] m-5 md:m-14 lg:m-3 lg:my-8 border border-white rounded-xl' data-aos="fade-down-right">
            <p className="flex justify-center my-1 mt-5 text-2xl font-bold">Transformational</p>
            <p className="flex justify-center text-2xl font-bold" >Innovations</p>
            <p className="flex justify-center mt-3 lg:mt-7 xl:mt-10 text-xl lg:text-[18px] xl:text-xl">Discover our reshaped </p>
            <p className="flex justify-center text-xl"> </p>
            <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3">industry standards</p>
          </div>
          <div className='lg:w-[200px] xl:w-1/2 lg:h-[220px] border border-white m-5 md:m-14 lg:m-3 mt-3 rounded-xl  lg:ml-[15%] xl:ml-[10%]' data-aos="fade-up-right"> 
           <p className="flex justify-center my-1 md:mt-5 text-2xl font-bold">Case Studies  </p>
           <p className="flex justify-center my-1  text-2xl font-bold">in Sustainability</p>
           <p className="flex justify-center mt-3 lg:mt-7 text-xl lg:text-[18px] xl:text-xl">Showcasing our lab's  </p>
           <p className="flex justify-center mb-3 text-xl lg:text-[18px]">sustainable solutions</p>
          </div>
        </div>
<div className='w-screen my-2 xl:w-4/12  mx-1 p-5 md:p-16 lg:p-5 xl:p-5' data-aos="zoom-in">
  <img src={IndustryImpactandSuccessStories1} className=" rounded-3xl ">
  </img>
</div>
<div className='xl:w-4/12 mx-1'>
<div className='lg:w-[200px] xl:w-1/2 lg:mr-[110px] xl:mr-[235px] m-5 md:m-14 lg:m-3 lg:h-[220px] my-10 lg:my-8  border border-white rounded-xl' data-aos="fade-down-left">
           <p className="flex justify-center my-1 md:mt-5 text-2xl font-bold">Collaborative </p>
           <p className="flex justify-center my-1  text-2xl font-bold mb-3">Achievements</p>
           <p className="flex justify-center lg:mt-7 text-xl lg:text-[18px] xl:text-xl">Learn about our </p>
           <p className="flex justify-center text-xl mb-3 lg:text-[18px] xl:text-xl">collaborative triumphs </p>
       </div>
          <div className=' lg:w-[200px] xl:w-1/2 lg:ml-[25%] xl:ml-[40%] lg:h-[220px]  border border-white m-5 md:m-14 lg:m-3 mt-3 rounded-xl' data-aos="fade-up-left">
          <p className=" flex justify-center my-1 md:mt-5 text-2xl font-bold">Consumer-Centric </p>
          <p className=" flex justify-center my-1  text-2xl font-bold mb-3">Solutions</p>
          <p className="flex justify-center lg:mt-7 text-xl lg:text-[18px] xl:text-xl">Delve into our consumer</p>
          <p className="flex justify-center text-xl mb-3 lg:text-[18px] xl:text-xl">centric innovation</p>
          </div>
       </div>
     </div>



    </div>
  )
}

export default IndustryImpactandSuccessStories
// Industry Impact and Success Stories
// Transformational Innovations: Discover our reshaped industry standards
// Case Studies in Sustainability: Showcasing our lab's sustainable solutions 
// Collaborative Achievements: Learn about our  collaborative triumphs 
// Consumer-Centric Solutions: Delve into our consumer-centric innovation
