import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import FutureVision1 from "../../About Us Images/Future Vision.jpg"

const FutureVision = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white  lg:my-60 pb-10">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">
        Future Vision
      </p>
      <div className="lg:flex lg:justify-evenly lg:mt-28">
        <div className="lg:w-1/2" data-aos="fade-right">
          <p className="text-xl lg:text-2xl m-2 p-5">
            Poshavesh invites you to glimpse into the future of our brand, where
            we envision continual growth and innovation.From new collections to
            innovative technologies, we're excited to invite you to be part of
            our journey as we chart new territories and redefine the fashion
            landscape.
          </p>
        </div>
        <div className="lg:w-1/2">
          <div className="m-5" data-aos="fade-left">
            <img
              className="rounded-3xl"
              src={FutureVision1}
              alt="teamimage"
            ></img>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FutureVision;
