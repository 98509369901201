import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const FashionInterview = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='lg:my-40'>
         <p className=" text-4xl xl:text-6xl flex justify-center font-bold mb-20 "  data-aos="zoom-in">Fashion Interview</p>
      <div className='lg:flex lg:justify-evenly mt-28'>
        <div className="xl:w-3/12">
        <div className='border border-white rounded-3xl lg:w-[230px] xl:w-[290px] lg:h-[250px] m-4 md:m-14 lg:m-3  lg:mx-10'data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center '>Renowned Designers</p>
        <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl'> Insights from celebrated </p>
        <p  className='flex justify-center text-xl mb-3 lg:text-[18px] xl:text-xl'>designers with Poshavesh</p>
        </div>
        <div  className='border border-white rounded-3xl m-4 md:m-14 lg:m-3 lg:h-[250px] lg:mx-10 mt-[10%] lg:mt-[10%]'data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center'>Influential Trendsetters</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl'>
        Making waves
        </p>
        <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3'>with Poshavesh</p>
        </div>
        </div>
       
        <div data-aos="zoom-in" className="p-5 md:p-16 lg:p-5 xl:p-0">
          <img className="rounded-3xl " src="https://cdn.dribbble.com/users/2054054/screenshots/6257329/interview.gif"></img>
        </div>
        <div className="xl:w-3/12">
        <div  className='border border-white rounded-3xl m-4 lg:w-[230px]  xl:w-[290px]  lg:mx-10 lg:h-[250px]' data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center'>Unparalleled Access</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl'>Insider perspectives</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl mb-3'>with Poshavesh</p>
        </div>
        <div  className='border border-white rounded-3xl  m-4 lg:w-[230px] xl:w-[290px]  lg:mx-10  mt-[10%] lg:h-[250px]' data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center'>Creative Minds</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl'>Dive into industry talent</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl mb-3'>with Poshavesh</p>
        </div>
        </div>
        
      </div>
      <div>
      

        

      </div>
    </div>
  )
}

export default FashionInterview
// Fashion Interviews: 
// Renowned Designers: Insights from celebrated designers with PV.
// Influential Trendsetters: Making waves with PV.
// Creative Minds: Dive into industry talent with PV.
// Unparalleled Access: Insider perspectives with PV.
