import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const VisionMissionGoals = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white  my-20 lg:my-60 ">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">
        Company Overview
      </p>
      <div className="md:flex md:justify-evenly mt-28 ">
        <div
          className="border border-white md:w-[240px] lg:w-[290px] xl:w-3/12 h-[270px] rounded-2xl   cursor-pointer mx-3"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <p className="flex justify-center text-3xl font-bold p-2 my-5">Mission</p>
          <div
            className="mt-[20px] text-xl lg:text-2xl"
           
          >
            <p className="flex justify-center">To weave together</p>
            <p className="flex justify-center">luxury & sustainability</p>
            <p className="flex justify-center">at every step</p>
            <p className="flex justify-center">of our journey</p>
          </div>
        </div>
        <div
          className="border border-white md:w-[240px] lg:w-[290px] xl:w-3/12 h-[270px] rounded-xl md:mt-10  duration-700 cursor-pointer mx-3 my-5"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <p className="flex justify-center text-3xl font-bold p-2 my-10">Vision</p>
          <div className="mt-[20px] text-xl lg:text-2xl">
            <p className="flex justify-center">To create a legacy</p>
            <p className="flex justify-center">
              of style
            </p>
            <p className="flex justify-center">that transcends borders</p>
            <p className="flex justify-center"></p>
            <p className="flex justify-center"></p>
          </div>
        </div>
        <div
          className="border border-white md:w-[240px] lg:w-[290px] xl:w-3/12 h-[270px] rounded-xl  duration-700 cursor-pointer mx-3"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <p className="flex justify-center text-3xl font-bold p-2 my-5">Goal</p>
          <div className="mt-[20px] text-xl lg:text-2xl">
            <p className="flex justify-center">Poshavesh aims to be a</p>
            <p className="flex justify-center">sustainable,premium </p>
            <p className="flex justify-center">fashion brand promoting  </p>
            <p className="flex justify-center">
            body positivity,diversity
            </p>
            <p className="flex justify-center">and self-expression
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VisionMissionGoals;
//Poshavesh aims to be a sustainable, premium fashion brand promoting body positivity, diversity, and self-expression.