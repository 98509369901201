import React from 'react'
import Navbar from "../Navbar"
import Footer from '../Footer'
import Footer2 from '../Footer2'
import TrendReports from './TrendReports'
import StyleGuides from './StyleGuides'
import FashionNews from './FashionNews'
import FashionAnalysis from './FashionAnalysis'
import FashionHistory from './FashionHistory'
import CelebrityStyle from './CelebrityStyle'
import Ecofashion from './Ecofashion'
import StyleTech from './StyleTech'
import Industryinsights from './Industryinsights'
import FashionPic from './FashionPic'
import StreetStyle from './StreetStyle'
import FashionInterview from './FashionInterview'
import ShopNowButton from '../ShopNowButton'
const FashionInsights = () => {
  return (
    <div className='overflow-x-hidden'>
      <Navbar />
      <TrendReports/>
      <ShopNowButton/>
      <StyleGuides/>
      <ShopNowButton/>
      <FashionNews/>
      <ShopNowButton/>
      <FashionAnalysis/>
      <ShopNowButton/>
      <FashionHistory/>
      <ShopNowButton/>
      <CelebrityStyle/>
      <ShopNowButton/>
      <Ecofashion/>
      <ShopNowButton/>
      <StyleTech/>
      <ShopNowButton/>
      <Industryinsights/>
      <ShopNowButton/>
      <FashionPic/>
      <ShopNowButton/>
      <StreetStyle/>
      <ShopNowButton/>
      <FashionInterview/>
      <ShopNowButton/>
      <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default FashionInsights