import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

const AchievementRecognition = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="text-white  lg:my-60">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14" data-aos="zoom-in">
        Achievement and Recognition
      </p>
      <div className="md:flex md:justify-evenly md:mt-28">
        <div
          className="border border-white md:w-[210px] lg:w-3/12  h-[100px] md:h-[175px] rounded-2xl   cursor-pointer mx-3 mb-5"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000">
          <div
            className="mt-[15px] md:mt-[50px] lg:mt-[35px] text-xl lg:text-2xl">
            <p className="flex justify-center">Reassuring </p>
            <p className="flex justify-center">our visitors</p>
          </div>
        </div>
        <div
          className="border border-white md:w-[210px] lg:w-3/12 h-[100px] md:h-[175px] rounded-xl md:mt-10  duration-700 cursor-pointer mx-3 mb-5"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <p className="flex justify-center text-xl lg:text-2xl font-bold p-2"></p>
          <div className=" md:mt-[45px] lg:mt-[35px] text-xl lg:text-2xl ">
            <p className="flex justify-center">
              Dedication 
            </p>
            <p className="flex justify-center"> to pushing boundaries</p>
          </div>
        </div>
        <div
          className="border border-white md:w-[210px] lg:w-3/12 h-[100px] md:h-[175px] rounded-xl  duration-700 cursor-pointer mx-3 mb-5"
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
        >
          <p className="flex justify-center text-xl lg:text-2xl font-bold p-2"></p>
          <div className="md:mt-[40px] lg:mt-[45px] text-xl lg:text-2xl">
            <p className="flex justify-center"> Synonymous with</p>
            <p className="flex justify-center">integrity and reliability</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AchievementRecognition;
