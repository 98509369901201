import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import PartnerResources1  from "../../Company Partnerships Images/Partner Resources.jpg" 

const PartnerResources = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='lg:my-40'>
         <p className=" text-4xl xl:text-6xl flex justify-center font-bold mb-20 "  data-aos="zoom-in"> Partner Resources</p>
      <div className='lg:flex lg:justify-evenly mt-28'>
        <div className="xl:w-3/12">
        <div className='border border-white rounded-3xl lg:w-[230px] xl:w-[290px] lg:h-[250px] m-4 md:m-14 lg:m-3  lg:mx-10'data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center '>Marketing Collateral</p>
        <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl'> Access </p>
        <p  className='flex justify-center text-xl mb-3 lg:text-[18px] xl:text-xl'>marketing materials</p>
        </div>
        <div  className='border border-white rounded-3xl m-4 md:m-14 lg:m-3 lg:h-[250px] lg:mx-10 mt-[10%] lg:mt-[10%]'data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center'>Branding Guidelines</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl'>
        Stay aligned with 
        </p>
        <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3'>our brand identity</p>
        </div>
        </div>
       
        <div data-aos="zoom-in" className="p-5 md:p-16 lg:p-5 xl:p-0">
          <img className="rounded-3xl " src={PartnerResources1}></img>
        </div>
        <div className="xl:w-3/12">
        <div  className='border border-white rounded-3xl m-4 lg:w-[230px]  xl:w-[290px]  lg:mx-10 lg:h-[250px]' data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center'>Training Materials</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl'>Enhance team </p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl mb-3'>skills with resources</p>
        </div>
        <div  className='border border-white rounded-3xl  m-4 lg:w-[230px] xl:w-[290px]  lg:mx-10  mt-[10%] lg:h-[250px]' data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center'>Exclusive Partner Portal</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl'>Access resources </p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl mb-3'>and track metrics</p>
        </div>
        </div>
        
      </div>
      <div>
      

        

      </div>
    </div>
  )
}

export default PartnerResources
// Partner Resources
// Marketing Collateral: Access marketing materials.
// Branding Guidelines: Stay aligned with our brand identity.
// Training Materials: Enhance team skills with resources.
// Exclusive Partner Portal: Access resources and track metrics.

