import React from 'react'
import GuaranteeOverview1 from "../../Customer Satisfaction Images/Guarantee Overview 01.jpg"
import GuaranteeOverview2 from "../../Customer Satisfaction Images/Guarantee Overview02.jpg"

const GuaranteeOverview = () => {
  return (
    <div className=' mt-32'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold  mb-16"  data-aos="zoom-in">Guarantee Overview</p>
        <div className='lg:flex ml-5'>
            <div className='lg:w-1/2 lg:p-5 xl:p-0' data-aos="fade-right">
                <div  className='lg:p-5 xl:p-8'>
                <img className='md:m-5 rounded-3xl transform skew-y-6' src={GuaranteeOverview1}>
                </img>
                </div>
                
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl'>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Customer-Centric Values : </span> Customer commitment overview</p>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Mission Statement : </span>Our guarantee's mission</p>
                </div>
            </div>
            <div className='mt-16 lg:w-1/2 lg:p-5 xl:p-0 lg:mr-5 xl:mr-0' data-aos="fade-left">
              <div  className='lg:p-5 xl:p-8'>
              <img className='md:m-5 rounded-3xl transform skew-y-6' src={GuaranteeOverview2}>
                </img>
              </div>
               
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl '>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Brand Promise : </span> Customer-focused brand foundation</p>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Value Proposition :</span> Benefits of our guarantee</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default GuaranteeOverview
// Guarantee Overview
// Customer-Centric Values: Customer commitment overview.
// Mission Statement: Our guarantee's mission.
// Brand Promise: Customer-focused brand foundation.
// Value Proposition: Benefits of our guarantee.
