import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TechnologyAndInnovation1  from "../../Industry Insights and Trends Images/Technology and Innovation.jpg" 

const TechnologyAndInnovation = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="mt-20 md:mb-40 lg:mb-60">
      <p className="text-4xl lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in">Technology and Innovation</p>
      <div className='lg:flex lg:justify-evenly'>
        <div className='w-full lg:w-4/12 mx-1' >
          <div className='lg:w-[200px] xl:w-1/2 lg:h-[220px] lg:ml-[150px] xl:ml-[245px] m-5 md:m-14 lg:m-3 lg:my-8 border border-white rounded-xl' data-aos="fade-down-right">
            <p className="flex justify-center my-1 mt-5 text-2xl font-bold">AI Fashion </p>
            <p className="flex justify-center text-2xl font-bold" >Assistants</p>
            <p className="flex justify-center mt-3 lg:mt-7 xl:mt-10 text-xl lg:text-[18px] xl:text-xl">Revolutionizing </p>
            <p className="flex justify-center text-xl">shopping </p>
            <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3"></p>
          </div>
          <div className='lg:w-[200px] xl:w-1/2 lg:h-[220px] border border-white m-5 md:m-14 lg:m-3 mt-3 rounded-xl  lg:ml-[15%] xl:ml-[10%]' data-aos="fade-up-right"> 
           <p className="flex justify-center my-1 md:mt-5 text-2xl font-bold">IoT  </p>
           <p className="flex justify-center my-1  text-2xl font-bold">Wearables</p>
           <p className="flex justify-center mt-3 lg:mt-7 text-xl lg:text-[18px] xl:text-xl">Fashion meets  </p>
           <p className="flex justify-center mb-3 text-xl lg:text-[18px]">functionality</p>
          </div>
        </div>
<div className='w-screen my-2 xl:w-4/12  mx-1 p-5 md:p-16 lg:p-5 xl:p-5' data-aos="zoom-in">
  <img src={TechnologyAndInnovation1} className=" rounded-3xl ">
  </img>
</div>
<div className='xl:w-4/12 mx-1'>
<div className='lg:w-[200px] xl:w-1/2 lg:mr-[110px] xl:mr-[235px] m-5 md:m-14 lg:m-3 lg:h-[220px] my-10 lg:my-8  border border-white rounded-xl' data-aos="fade-down-left">
           <p className="flex justify-center my-1 md:mt-5 text-2xl font-bold">Blockchain  </p>
           <p className="flex justify-center my-1  text-2xl font-bold mb-3">Transparency</p>
           <p className="flex justify-center lg:mt-7 text-xl lg:text-[18px] xl:text-xl">Ethical supply  </p>
           <p className="flex justify-center text-xl mb-3 lg:text-[18px] xl:text-xl">chain assurance</p>
       </div>
          <div className=' lg:w-[200px] xl:w-1/2 lg:ml-[25%] xl:ml-[40%] lg:h-[220px]  border border-white m-5 md:m-14 lg:m-3 mt-3 rounded-xl' data-aos="fade-up-left">
          <p className=" flex justify-center my-1 md:mt-5 text-2xl font-bold">AR/VR Fashion </p>
          <p className=" flex justify-center my-1  text-2xl font-bold mb-3">Experiences</p>
          <p className="flex justify-center lg:mt-7 text-xl lg:text-[18px] xl:text-xl">Virtual trend  </p>
          <p className="flex justify-center text-xl mb-3 lg:text-[18px] xl:text-xl">exploration</p>
          </div>
       </div>
     </div>



    </div>
  )
}

export default TechnologyAndInnovation
// Technology and Innovation
// AI Fashion Assistants: Revolutionizing shopping.
// IoT Wearables: Fashion meets functionality.
// Blockchain Transparency: Ethical supply chain assurance.
// AR/VR Fashion Experiences: Virtual trend exploration.
