import React from 'react'
import Navbar from "./Navbar"
import Footer from './Footer'
import Footer2 from './Footer2'
const UserGuidlines = () => {
  return (
    <div className='overflow-x-hidden'>
      <Navbar/>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-40 mb-14"  data-aos="zoom-in"> User Guidlines</p>
      <div data-aos="fade-up">
      <p className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>Thank you for visiting www.poshavesh.com. This site is operated by POSHAVESH PRIVATE LIMITED located in C/O- JHINTU BAIDYA ADHIKARI, ARYA COLONY NEAR, BELONIA, 799155, TRIPURA.</p>
<p className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl   '>
<div className='font-bold my-3'>DEFINITION CLAUSE :-</div>
Agreement: The Indian Contract Act 1872, section 2(e), defines an agreement as "every promise and every set of promises, forming the consideration for each other is an agreement."
Trademark: According to the Trademark Act, 1999, Trademark means a mark capable of being represented graphically and which is capable of distinguishing the goods or services of one person from those of others and may include the shape of goods, their packaging and combination of colors.
Consent: Section 13 of the Indian Contract Act defines consent as “when two parties entered into the contract they should agree upon the same thing in the same manner”.
Hyperlink: Hyperlink means an electronic connection or reference to another place in the document, such that when the hyperlink is selected the user is taken to the portion of the document to which the link refers. It is not in itself a part of the document.
Liability: A legally enforceable claim on the assets of a business or property of an individual. Liability results from a breach of duty or obligation by act or failure to act.
Severability: Severability Clause means a provision that keeps the remaining provisions of a contract or statute in force if any portion of that contract or statute is judicially declared void, unenforceable, or unconstitutional.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>AGREEMENT :-</div>
Access and use of this Site and the information, materials, products and services available through this Site is subject to all applicable laws and regulations and to these Terms of Use.  At this site by accessing and browsing, you accept, without limitation or qualification, these Terms of Use which form a legally binding agreement.
Kindly exit this Site if you do not agree to the above mentioned condition.
You should always review these Terms of Use prior to using the Site as the latest Terms of Use will be posted on the Site. In order to ensure that you have a current understanding of the Terms of Use under which you are permitted to access this Site. These Terms of Use may be changed by us from time to time without specific notice to you. It will be your responsibility to stay updated with our user guideline policy.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>OWNERSHIP :-</div>
Unless otherwise indicated, this Site and its design, text, content, selection and arrangement of elements, organization, graphics, design, compilation, and other matters related to this Site ("Content") are protected under applicable copyrights, trademarks and other proprietary (including, but not limited to, intellectual property) laws, including without limitation those of India and all Content and intellectual property rights therein are the property of BISJHINTUS Private Limited or the material is included with the permission of the rights owner and is protected pursuant to applicable copyright and trademark laws.
The posting of any Content on this Site does not constitute a waiver of any right in such Content. You do not acquire ownership rights to any such Content viewed through this Site. Except as otherwise provided herein, none of this Content may be used, copied, reproduced, distributed, republished, downloaded, modified, displayed, posted or transmitted in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording, or otherwise, without our express prior written permission.
Permission is hereby granted to the extent necessary to lawfully access. Kindly, use this Site and/or information, materials, products and/or services available on it to display and print in hard copy, portions of this Site on a temporary basis and for your individual use only. It is completely restricted to modify the materials that you retain. All the materials under this course are under copyright.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'> 
<div className='font-bold my-3'>TRADEMARKS :-</div>
The POSHAVESH INDIA PRIVATE LIMITED logo, all product names, all page headers, all custom graphics, all button icons, all trademarks, service marks and logos appearing on this Site, unless otherwise noted, are service marks, trademarks (whether registered or not) and/or trade dress of BISJHINTUS Private Limited (the "Marks''). All other trademarks, product names, company names, logos, service marks and/or trade dress mentioned, displayed, cited or otherwise indicated on the Site are the property of their respective owners. You are not authorized to display or use the marks in any manner without our prior written permission. You are not authorized to display or use trademarks, product names, company names, logos, service marks and/or trade dress of other owners without the prior written permission of the owners. The use or misuse of the Marks or other trademarks, product names, company names, logos, service marks and/or trade dress or any other materials contained herein, except as permitted herein, is expressly prohibited.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>DATA PROTECTION CONSENT :-</div>
Agreeing to these Terms of Use, you agree to the terms of our Privacy Policy which is incorporated herein. Before using this Site please read through all these documents carefully. All personal data provided to us as a result of your use of this Site will be handled in accordance with our Privacy Policy. We reserve the right to monitor your communications with us whether by mail, voice, fax, e-mail or any other form of transmission for the purposes of quality control, security and other business needs.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>HYPERLINK DISCLAIMER :-</div>
This Site may contain links and/or advertisements to other websites maintained by unrelated companies. An advertisement of, or link to, a non POSHAVESH INDIA PRIVATE LIMITED website does not mean that we approve, endorse or accept any responsibility for that website. Its content or use, or the use of products and services made available through such websites. Such websites are not investigated, monitored or checked for accuracy, completeness or conformance with applicable laws and regulations by us. We do not make any representations whatsoever, or give any warranties of any kind, expressed, implied, or otherwise about other websites which you may access through this Site, the content thereof, or the products and/or services made available through such websites. If you decide to leave our Site and access these other websites, you do so at your own risk. All rules, policies (including privacy policies) and operating procedures of such websites will apply to you while on such websites.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>NO WARRANTIES :-</div>
We make no representations or warranties with respect to this site or its contents, which are provided for use "as is" and on an "as available" basis. We disclaim all warranties, express or implied, including without limitation the implied warranties of title, non-infringement, merchantability, quality and fitness for a particular purpose, with respect to this site and any site with which it is linked. We do not warrant that this site, its servers or e-mail sent from us will be free of any harmful components (including viruses). We also make no representations or warranties as to whether the information accessible via this site, or any site with which it is linked, is accurate, complete, or current. We do not provide any representations or warranties against the possibility of deletion, wrong delivery or failure to store communications, personalized settings, or other data. You accept that our shareholders, owners, officers, directors, employees and other representatives shall have the benefit of this clause.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>EXCLUSION OF LIABILITY :-</div>
To the fullest extent permitted by applicable laws we, on behalf of our employees, agents, suppliers, and contractors, exclude and disclaim liability for any losses and expenses of whatever nature and howsoever arising including, without limitation, any direct, indirect, special, punitive (means punishment), or consequential damages, loss of use, loss of data, loss caused by a virus, loss of income or profit, loss of or damage to property, claims of third parties, or other losses of any kind or character, even if we have been advised of the possibility of such damages or losses, arising out of or in connection with the use of the site or any website with which it is linked. You assume total responsibility for establishing such procedures for data backup and virus checking as you consider necessary. This limitation of liability applies whether the alleged liability (means responsibility that someone has for their actions) is based on contract, negligence, tort (Tort law governs the remedies for civil wrongs), strict liability or any other basis.
These Terms of Use give you specific legal rights and you may also have other rights which vary from country to country. Some jurisdictions do not allow the exclusion of implied (An implied contract is a legally-binding obligation that derives from actions, conduct, or circumstances of one or more parties in an agreement) warranties, or certain kinds of limitations or exclusions of liability, so the limitations & exclusions set out in these Terms of Use may not apply to you. Other jurisdictions allow limitations and exclusions subject to certain conditions. In such a case the limitations and exclusions set out in these Terms of Use shall apply to the fullest extent permitted by the laws of such applicable jurisdictions. Your statutory rights as a consumer, if any, are not affected by these provisions, and we do not seek to exclude or limit liability for fraudulent misrepresentation.
</p>

<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>YOUR OBLIGATIONS :-</div>
In consideration of your use of the Site, if you register on our Site in order to participate, or otherwise, you agree to provide true, accurate, current and complete information about yourself.
Your account, including your username and password, are personal to you and may not be used by anyone else. You are responsible for maintaining the confidentiality of your password and username and are fully responsible for all activities that occur under your password or username by you or by anyone else using your username and password, whether or not authorized by you. You agree to immediately inform us of any apparent breaches of security such as loss, theft or unauthorized disclosure or use of your username or password (info@poshavesh.com) and until we are so notified you will remain liable for any unauthorized use of your account.
You agree to use the Site in a manner consistent with any and all applicable rules and regulations. You agree not to upload or transmit through the Site any computer viruses, Trojan horses, worms or anything else designed to interfere with, interrupt or disrupt the normal operating procedures of a computer. Any unauthorized modification, tampering (means an act in which a person alters, conceals, falsifies, or destroys the content with the intent to interfere) or change of any information, or any interference with the availability of or access to this Site is strictly prohibited. POSHAVESH INDIA PRIVATE LIMITED reserves all rights and remedies available to it.
You agree to indemnify, release and hold us harmless as well as our officers, directors, agents, representatives and employees from any claim, liability, loss, expense or demand, including legal fees, related to your violation of these Terms of Use or your access or use of this Site (including any information, materials, products or services available through this Site).

</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>INTELLECTUAL PROPERTY POLICY :-</div>
POSHAVESH INDIA PRIVATE LIMITED respects the intellectual property of third parties, and takes matters of alleged intellectual property infringement seriously. If you believe that your own work, or the work of a third party for whom you are authorized to act, is featured on the Site or has been otherwise copied or made available on the Site in a manner that constitutes copyright infringement, please send a notice of the alleged infringement to NITA including all of the following information:
An electronic or physical signature of the intellectual property owner, or an agent authorized to act on behalf of the owner;
A description of the work that you claim has been infringed (including the URL, location on the Site, title and/or item number (if applicable) or other identifying characteristics);
Your name, company name, address, telephone number, fax number, and e-mail address (and, if you are not the owner of the intellectual property, the name of the owner);
A statement by you that you have a good-faith belief that the described use of the work is not authorized by the intellectual property owner, its agent, or the law;
A statement by you, made under penalty of perjury, that the information in your notice is accurate and that you are the intellectual property owner or an agent authorized to act on behalf of the intellectual property owner.
Please send the notice of alleged infringement to POSHAVESH INDIA PRIVATE LIMITED agent for notice of claims of intellectual property infringement by any of the following:
Name: -----------------
E-mail: -------------------
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
 <div className='font-bold my-3'>PRODUCT & SERVICES INFORMATION :-</div>
All references on this Site to information, materials, products and services apply to information, materials, products and services available in the jurisdictions specified with respect to such information only, unless otherwise stated.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>MODIFICATION AND DISCONTINUATION :-</div>
We reserve the right at any time and from time to time to modify, edit, delete, suspend or discontinue, temporarily or permanently this Site (or any portion thereof) and/or the information, materials, products and/or services available through this Site (or any part thereof) with or without notice. You (users) agree that we (POSHAVESH) shall not be liable to you or to any third party (here third party means any hacker) or any technical error for any such modification, editing, deletion, suspension or discontinuance of this site (https://bisjhintus.com/).
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>SEVERABILITY AND NON-WAIVER :-</div>
Our failure to enforce any provision of these Terms of Use will not be deemed a waiver of such provision nor of the right to enforce such provision, and a waiver by us of any right under these Terms of Use on any occasion will not in any way constitute a waiver of such right or any other right on any other occasion. In the event any provision of these Terms of Use is determined to be invalid, such invalidity will not affect the validity of the remaining portions of these Terms of Use, and the parties will substitute for the invalid provision a provision which most closely approximates the intent and economic effect of the invalid provision. These Terms of Use will apply in lieu of (instead of) and notwithstanding any specific legend or statement associated with any particular document or information exchanged.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>LAW & JURISDICTION :-</div>
These Terms of Use shall be subject to, governed by and construed under the laws of the land, i.e. India. Access to, or use of, this Site or information, materials, products and/or services on this Site may be prohibited by law in certain countries or jurisdictions. You are responsible for compliance with any applicable laws of the country from which you are accessing this Site.
You hereby consent and waive all objection to the exclusive jurisdiction of competent courts and venue therein to resolve any controversy or claim of whatever nature arising out of or relating to use of this Site. However, we retain the right to bring legal proceedings in any jurisdiction where we believe that infringement (refers to the violation of a law or a right) of this agreement is taking place or originating.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>PAYMENT SYSTEM :-</div>
We will be accepting payment through any of the payment platforms. You must also be aware of the ongoing scams with the growth in technology. We would like to state to you that we will never be asking for OTP. Kindly contact us immediately if any kind of personal information is asked from you by using the name of POSHAVESH INDIA PRIVATE LIMITED. If you face any kind of issue related to the payment, you can write us a mail on our mail id mentioned on our website. Moreover, you can also call us on our contact details provided on our website. We will be addressing your concern at the earliest.
</p>
<p  className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
<div className='font-bold my-3'>ENTIRE UNDERSTANDING :-</div>
These guidelines contain the entire understanding between you and us with respect to use of this Site and no representation, statement, inducement oral or written, not contained herein shall bind any party to this agreement.
</p>
</div>
<div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default UserGuidlines