import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import FutureOutlook1  from "../../Industry Insights and Trends Images/Future Outlook01.jpg" 
import FutureOutlook2  from "../../Industry Insights and Trends Images/Future Outlook02.jpg" 


const FutureOutlook = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className="lg:mt-60">
       <p className="text-[34px]  lg:text-6xl flex justify-center font-bold my-10 mb-14"  data-aos="zoom-in">Future Outlook
      </p>
      <div className='md:flex md:justify-between'>
      <div className='md:w-1/2 m-5 xl:ml-[-5%]'data-aos="fade-right" >
      <img src={FutureOutlook1} className='h-3/4 xl:ml-[16%] mt-5   rounded-3xl'></img>
      <div className="border border-white xl:ml-[20%] my-5 rounded-3xl mx-5">
      <p className=' mt-5'></p>
      <p className='ml-5  mt-5 text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Trend Forecasting :</span>  Fashion crystal ball insights</p>
      <p className='ml-5 my-5 text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Disruptive Innovations :</span> Emerging reshaping tech</p>
      
      </div>
      </div>
      <div className='md:w-1/2 mt-20 xl:mr-[5%]' data-aos="fade-left">
        <div className="mx-5  xl:ml-[25%]  md:mr-[10%] border border-white rounded-3xl my-5">
        <p className='ml-5 mt-5  text-xl lg:text-[18px]  xl:text-xl'><span className="font-bold  text-2xl">Market Projections : </span>Future trends insights</p>
        <p className="text-xl mb-5 ml-[57%]"></p>
        <p className='ml-5 mt-2 text-xl lg:text-[18px] xl:text-xl'><span className="font-bold  text-2xl">Strategic Insights : </span>Business future-proofing advice</p>
        <p  className='ml-[64%] mb-5 text-xl'></p>
        </div>
      <div className="p-6">
      <img src={FutureOutlook2} className='h-3/4 rounded-3xl   md:mt-5 md:mt-15 xl:ml-[8%]'></img>
      </div>
      </div>
    </div>
    </div>
  )
}

export default  FutureOutlook
// Future Outlook
// Trend Forecasting: Fashion crystal ball insights.
// Disruptive Innovations: Emerging reshaping tech.
// Market Projections: Future trends insights.
// Strategic Insights: Business future-proofing advice.

