import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ImpactofGlobalization1 from "../../Fashion History/Impact of Globalization.jpg"


const ImpactofGlobalization = () => {
  useEffect(() => {
    // Initialize AOS when component mounts
    AOS.init({
      duration: 3000, // Duration of animation in milliseconds
      once: false, // Whether animation should only happen once while scrolling down
    });
  }, []); // Empty dependency array to run effect only once
  return (
    <div className='lg:my-40'>
         <p className=" text-4xl xl:text-6xl flex justify-center font-bold mb-20 "  data-aos="zoom-in">Impact of Globalization</p>
      <div className='lg:flex lg:justify-evenly mt-28'>
        <div className="xl:w-3/12">
        <div className='border border-white rounded-3xl lg:w-[230px] xl:w-[290px] lg:h-[250px] m-4 md:m-14 lg:m-3  lg:mx-10'data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center '>Trend Transcendence</p>
        <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl'> Global fashion fusion </p>
        <p  className='flex justify-center text-xl mb-3 lg:text-[18px] xl:text-xl'></p>
        </div>
        <div  className='border border-white rounded-3xl m-4 md:m-14 lg:m-3 lg:h-[250px] lg:mx-10 mt-[10%] lg:mt-[10%]'data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center'>Fast Fashion Phenomenon</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl'>
        Speeding trends
        </p>
        <p className='flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3'></p>
        </div>
        </div>
       
        <div data-aos="zoom-in" className="p-5 md:p-16 lg:p-5 xl:p-0">
          <img className="rounded-3xl " src={ImpactofGlobalization1}></img>
        </div>
        <div className="xl:w-3/12">
        <div  className='border border-white rounded-3xl m-4 lg:w-[230px]  xl:w-[290px]  lg:mx-10 lg:h-[250px]' data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center'>Global Supply Chains</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl'>Fashion's worldwide journey</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl mb-3'></p>
        </div>
        <div  className='border border-white rounded-3xl  m-4 lg:w-[230px] xl:w-[290px]  lg:mx-10  mt-[10%] lg:h-[250px]' data-aos="fade-up">
        <p className='text-2xl lg:text-xl font-bold my-5 lg:my-10 flex justify-center'>Balancing Acts</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl'>Globalization's duality</p>
        <p className='flex justify-center text-xl  lg:text-[18px] xl:text-xl mb-3'></p>
        </div>
        </div>
        
      </div>
      <div>
      

        

      </div>
    </div>
  )
}

export default ImpactofGlobalization
// Impact of Globalization
// Trend Transcendence: Global fashion fusion.
// Fast Fashion Phenomenon: Speeding trends.
// Global Supply Chains: Fashion's worldwide journey.
// Balancing Acts: Globalization's duality.

