import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import TimelineofFashionEras1 from "../../Fashion History/Ancient Elegance.png"
import TimelineofFashionEras2 from "../../Fashion History/Renaissance Revival.png"
import TimelineofFashionEras3 from "../../Fashion History/Victorian Splendor.png"
import TimelineofFashionEras4 from "../../Fashion History/Modern Marvels.png"

const TimelineofFashionEras = () => {
    useEffect(() => {
        // Initialize AOS when component mounts
        AOS.init({
          duration: 3000, // Duration of animation in milliseconds
          once: false, // Whether animation should only happen once while scrolling down
        });
      }, []); // Empty dependency array to run effect only once
  return (
    <div className="md:my-40">
       <p className="text-4xl lg:text-6xl flex justify-center font-bold my-20"  data-aos="zoom-in">Timeline of Fashion Eras
      </p>
        <div>
          <div className="lg:ml-[5%] xl:ml-[10%]">
            <div className="flex justify-center lg:inline-block  lg:ml-[11%]  xl:ml-[7%]" data-aos="zoom-in">
            <img src={TimelineofFashionEras1} className="rounded-full w-[110px] mb-3"></img>
            </div>
          <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] md:m-14 lg:m-0 rounded-2xl'data-aos="fade-right">
           <p className="text-2xl font-bold flex justify-center mt-5">Ancient Elegance</p>
           <p className="text-2xl font-bold flex justify-center"></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mt-5"> Explore the opulent </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">garments of Ancient times</p>
        </div>
          </div>

          <div className=" mt-16 lg:ml-[20%] xl:ml-[30%]">
            <div className=" flex justify-center lg:inline-block  lg:ml-[14%] xl:ml-[10%]" data-aos="zoom-in">
            <img src={TimelineofFashionEras2} className="rounded-full w-[110px] mb-3"></img>
            </div>
            <div className=' border border-white m-3  lg:w-[300px]  lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Renaissance Revival </p>
        <p className="text-2xl font-bold flex justify-center"></p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Step into the Renaissance  </p>
        <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3">era with Poshavesh</p>
        </div>
          </div>
        </div>

        <div className="mt-16 lg:ml-[40%] xl:ml-[50%]">
        <div className="flex justify-center lg:inline-block lg:ml-[18%]  xl:ml-[13%]" data-aos="zoom-in">
            <img src={TimelineofFashionEras3} className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3 lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3' data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Victorian Splendor </p>
        <p className="text-2xl font-bold flex justify-center "></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Enter the Victorian era</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl"> characterized withby</p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl  mb-3"> elaborate tailoring</p>
        </div>
        </div>
        
        <div className="mt-16 lg:ml-[60%] xl:ml-[70%]">
        <div className="flex justify-center lg:inline-block  lg:ml-[28%] xl:ml-[22%]" data-aos="zoom-in">
            <img src={TimelineofFashionEras4}className="rounded-full w-[110px] mb-3"></img>
            </div>
        <div className='border border-white m-3  lg:w-[300px] lg:h-[200px] rounded-2xl md:m-14 lg:m-3 'data-aos="fade-right">
        <p className="text-2xl font-bold flex justify-center mt-5">Modern Marvels </p>
        <p className="text-2xl font-bold flex justify-center"></p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mt-5">Witnessing the evolution </p>
           <p className="flex justify-center text-xl lg:text-[18px] xl:text-xl mb-3 ">of contemporary fashion </p>
        </div>
        </div>
        
        
    </div>
  )
}

export default TimelineofFashionEras
// Timeline of Fashion Eras
// Ancient Elegance: Explore the opulent garments of Ancient times.
// Renaissance Revival: Step into the Renaissance era with Posha Vesh
// Victorian Splendor: Enter the Victorian era, characterized by elaborate tailoring
// Modern Marvels:, Witnessing the evolution of contemporary fashion 


