import React from 'react'
import ContactForm from './ContactForm'
import Address from './Address'
import DetailsSocialMedia from './DetailsSocialMedia'
import BusinessHours from './BusinessHours'
import LanguageOptions from './LanguageOptions'
import FAQButton from './FAQButton'
import Navbar from "../Navbar"
import Footer from '../Footer'
import Footer2 from '../Footer2'
const Contact = () => {
  return (
    <div className='overflow-x-hidden overflow-y-hidden'>
        <Navbar/>
        <Address/>
        <DetailsSocialMedia/>
        <FAQButton/>
        <BusinessHours/>
        <LanguageOptions/>
        <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default Contact