import React from 'react'
import CollaborativeProjects1  from "../../Company Partnerships Images/Collaborative Projects01.jpg" 
import CollaborativeProjects2  from "../../Company Partnerships Images/Collaborative Projects02.jpg" 

const CollaborativeProjects = () => {
  return (
    <div className='mt-40'>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold  mb-32"  data-aos="zoom-in">Collaborative Projects</p>
        <div className='lg:flex ml-5'>
            <div className='lg:w-1/2 lg:p-5 xl:p-0' data-aos="fade-right">
                <div className='lg:p-5 xl:p-8'>
                <img className='md:m-5 rounded-3xl transform skew-y-6' src={CollaborativeProjects1}>
                </img>
                </div>
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl'>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Sustainable Fashion Initiative : </span>  Leading sustainability collaborations</p>
                  <p className='text-xl lg:text-[18px] xl:text-xl  my-2 ml-5'><span className='font-bold text-2xl'>Fashion Education Initiative : </span>Projects fostering the next generation of fashion leaders</p>
                </div>
            </div>
            <div className='mt-16 lg:w-1/2 lg:p-5 xl:p-0 lg:mr-5 xl:mr-0' data-aos="fade-left">
              <div className='lg:p-5 xl:p-8'>
              <img className='md:m-5 rounded-3xl transform skew-y-6' src={CollaborativeProjects2}>
                </img>
              </div>
                <div className='m-5 transform skew-y-6 border border-white rounded-3xl '>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'>Inclusive Design Campaign :</span>  Partnerships promoting diversity</p>
                <p className='text-xl lg:text-[18px] xl:text-xl my-2 ml-5'><span className='font-bold text-2xl'> Community Empowerment Program : </span> Collaborative efforts with local communities and NGOs</p>
                </div>
            </div>

        </div>
    </div>
  )
}

export default CollaborativeProjects
// Collaborative Projects
// Sustainable Fashion Initiative: Leading sustainability collaborations.
// Inclusive Design Campaign: Partnerships promoting diversity.
// Community Empowerment Program: Collaborative efforts with local communities and NGOs.
// Fashion Education Initiative: Projects fostering the next generation of fashion leaders.
