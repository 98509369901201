import React from "react";
import Welcome from "./Welcome";
import JobOpening from "./JobOpening";
import Testimonials from "./Testimonials";
import Perks from "./Perks";
import CompanyValues from "./CompanyValues";
import PathGrowth from "./PathGrowth";
import DiversityInclusions from "./DiversityInclusions";
import ApplicationProcess from "./ApplicationProcess";
import CompanyEventsandActivites from "./CompanyEventsandActivites";
import FAQs from "./FAQs";
import ContactInformation from "./ContactInformation";
import Navbar from "../Navbar"
import Footer from "../Footer";
import Footer2 from "../Footer2";
const Career = () => {
  return <div className=" overflow-x-hidden">
    <Navbar/>
    <Welcome/>
    <JobOpening/>
    <Testimonials/>
    <Perks/>
    <CompanyValues/>
    <PathGrowth/>
    <DiversityInclusions/>
    <ApplicationProcess/>
    <CompanyEventsandActivites/>
    <FAQs/>
    <ContactInformation/>
    <div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
  </div>;
};

export default Career;
