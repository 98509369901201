import React from 'react'
import Navbar from "./Navbar"
import Footer from "./Footer"
import Footer2 from "./Footer2"


const RefundPolicyandCancellationPolicy = () => {
  return (
    <div className=' overflow-x-hidden'>
      <Navbar/>
      <p className=" text-4xl lg:text-6xl flex justify-center font-bold my-40 mb-14"  data-aos="zoom-in">Refund Policy and Cancellation Policy</p>
      <div data-aos="fade-up">
      <p className='mx-3 my-5 text-xl lg:mx-20 lg:text-2xl'>
      <div className='font-bold my-20'>WARRANTY AND REFUND POLICY FOR POSHAVESH INDIA PRIVATE LIMITED :-</div>
      
 <div className='font-bold my-3'>INTRODUCTION :-</div>
At Poshavesh India Private Limited, we are committed to ensuring the highest level of customer satisfaction. This Warranty and Refund Policy outlines our policies regarding exchanges, returns, and refunds.
<div className='font-bold my-3'>ITEMS:-</div>

All items must be returned in their original condition, unworn, and with all their accessories. This includes, but is not limited to, original Poshavesh packaging, tags, and protective covers.
We understand that our customers may change their mind after purchasing a product. However, we are unable to accept exchanges and returns for personalized items.
<div className='font-bold my-3'>EXCHANGES :-</div>

We offer free exchanges within 5 days of reception. If you are not completely satisfied with your purchase, you can exchange it for another item of equal or lesser value.
To initiate an exchange, please contact our Client Advisors. They will guide you through the process and answer any questions you may have.
Please note that all exchanges are subject to inspection by our Quality Services. This is to ensure that the returned items meet our return criteria.
Once your product has been accepted by our Quality Services, we will ship the exchanged items to you.
<div className='font-bold my-3'>RETURNS :-</div>


We also offer free returns within 5 days of reception. If you are not satisfied with your purchase and do not wish to exchange it, you can return it for a refund.
Items purchased online can be returned via MyPoshavesh Account or in store.
Please note that in-store returns must be initiated in the same country as the billing address linked to the order.
<div className='font-bold my-3'>REFUNDS :-</div>
Once your return has been accepted by our Quality Services, we will issue a refund within 5 working days.
Refunds will be issued in the original payment method to the original purchaser.
Please note that refunds are not issued in-store.
<div className='font-bold my-3'>DAMAGED OR DEFECTIVE ITEMS :-</div>
If you receive a damaged or defective item, please notify us immediately. We will arrange for the item to be returned and either repair or replace the item at no additional cost.
<div className='font-bold my-3'>CANCELLATIONS :-</div>
If you wish to cancel your order, please contact us as soon as possible. Please note that once an order has been shipped, it cannot be cancelled.
<div className='font-bold my-3'>FAQ SECTION :-</div>
We understand that our customers may have questions about our products, services, and policies. Therefore, we provide a comprehensive FAQ section on our website to address common queries and concerns.
<div className='font-bold my-3'>CONCLUSION :-</div>
We strive to provide our customers with high-quality products and excellent customer service. If you have any questions or concerns about our Warranty and Refund Policy, please do not hesitate to contact us.
Please note that this policy is subject to change and it’s always a good idea to check our website for the most up-to-date information.
</p>
</div>
<div className="hidden xl:inline-block">
      <Footer />
      </div>
      <div className="inline-block  xl:hidden">
      <Footer2 />
      </div>
    </div>
  )
}

export default RefundPolicyandCancellationPolicy