import React from 'react'
import { Link } from 'react-router-dom'

const FAQButton = () => {
  return (
    <div className='text-white   my-20 lg:my-60 pb-10 ' data-aos="fade-up">
    <div className='flex justify-evenly'>
    <Link to="/faq">
      <button className='p-5 md:p-10  w-[110px] md:w-[180px] m-5 bg-white rounded-xl text-black text-[18px] md:text-2xl font-bold  hover:bg-black hover:text-white hover:border hover:boder-white duration-500 '>FAQ</button>
    </Link>
    <Link to="https://poshavesh.com/">
    <button className=' p-5 md:p-10 m-5 w-[110px]  md:w-[180px] bg-white rounded-xl text-black text-[18px] md:text-2xl font-bold hover:bg-black hover:text-white hover:border hover:boder-white duration-500 '>Feedback</button>
    </Link>
    
    </div>
    </div>
  )
}

export default FAQButton